import { createSelector } from 'reselect'
import { getPageParams } from 'selectors/page'
import { parseSku } from 'lib/sku'
import {
  getArtwork,
  getArtworkCategory,
  getArtworkHasOpenEditions,
  getArtworkHasLimitedEditions,
} from './artwork'
import { getProductStatus, toTitleCase } from '../helpers'
import {
  LIMITED_EDITION,
  OPEN_EDITION,
  ORIGINAL,
  SOLD_OUT,
  RESERVED,
  NOT_FOR_SALE,
  FOR_SALE,
} from '../helpers/constants'
export const getProductCategory = createSelector([getArtworkCategory], (category): string => {
  switch (category) {
    case 'Drawings':
      return 'Drawing'

    case 'Paintings':
      return 'Painting'

    case 'Photography':
      return 'Photograph'

    case 'Printmaking':
      return 'Art Print'

    case 'Mixed Media':
    case 'Digital':
      return 'Artwork'

    default:
      return category
  }
})
export const getCurrentProductType = createSelector(
  [getPageParams],
  (params): string => params.productType || ''
)
export const getCurrentSku = createSelector([getPageParams], (params): string => params.sku || '')
export const getProducts = createSelector(
  [getArtwork],
  (artwork): Array<Record<string, any>> => artwork.products || []
)
export const getOriginalProduct = createSelector(
  [getProducts],
  (products): Record<string, any> => products.find((data) => data.isOriginal) || {}
)
// We also Want to Sort Products by Price
export const getPrintProducts = createSelector(
  [getProducts],
  (products): Record<string, any> =>
    products
      .filter((data) => !data.isOriginal)
      .sort((a, b) => {
        if (a.price > b.price) {
          return 1
        } else if (a.price < b.price) {
          return -1
        }

        return 0
      }) || {}
)
export const getArtworkOriginalStatus = createSelector([getOriginalProduct], (product): string =>
  getProductStatus(product)
)
export const getOriginalProductPrice = createSelector(
  [getOriginalProduct],
  (product): number => product.price || 0
)
export const getOriginalProductIsAple = createSelector(
  [getOriginalProduct],
  (product): boolean => product.isAple || false
)
export const getOriginalProductUnitsProduced = createSelector(
  [getOriginalProduct],
  (product): number => product.unitsProduced || 0
)
// Checks that the USER actually created a PRODUCT for Artwork
export const hasCreatedOriginalProduct = createSelector(
  [getOriginalProduct],
  (originalProduct): boolean => {
    const { isAvailableForSale, isSoldOut, isReserved } = originalProduct
    return isAvailableForSale || isSoldOut || isReserved
  }
)
export const getCurrentTab = createSelector(
  [getCurrentProductType, hasCreatedOriginalProduct, getArtworkHasOpenEditions],
  (productType, originalProductCreated, openEditions): string => {
    let currentTab = ORIGINAL

    if (productType.search(/print/) > -1) {
      currentTab = openEditions ? OPEN_EDITION : LIMITED_EDITION
    }

    return currentTab
  }
)
export const getTabs = createSelector(
  [hasCreatedOriginalProduct, getArtworkHasOpenEditions, getArtworkHasLimitedEditions],
  (originalProductCreated, openEditions, limitedEditions): Array<string> => {
    const tabs = [ORIGINAL]

    if (openEditions) {
      tabs.push(OPEN_EDITION)
    }

    if (limitedEditions) {
      tabs.push(LIMITED_EDITION)
    }

    return tabs
  }
)
export const getProductTabLinkPath = createSelector([getArtwork], (artwork): string => {
  const { legacyUserArtId: artworkId, userId: artistId, slug } = artwork
  return `${slug}/${artistId}/${artworkId}/view`
})
export const getProductTabLinkUrls = createSelector(
  [
    getArtwork,
    getProductTabLinkPath,
    hasCreatedOriginalProduct,
    getArtworkHasOpenEditions,
    getArtworkHasLimitedEditions,
  ],
  (artwork, path, hasOriginal, hasOpenEditions, hasLimitedEditions): Record<string, any> => ({
    artUrl: `/art/${path}`,
    openUrl: hasOpenEditions || hasLimitedEditions ? `/print/${path}` : '',
  })
)
export const getProductTabShowInfo = createSelector(
  [getTabs, getCurrentProductType],
  (tabs, productType): boolean => !(tabs.length > 1 && productType.search(/print/) > -1)
)

/** @see ./product.spec.js exported for testing only */
export function getSelectedProductSelector(
  currentTab: string,
  originalProduct: Record<string, any> | null | undefined,
  printProducts: Array<Record<string, any>>,
  {
    productSku,
    optionIds,
  }: {
    productSku: string
    optionIds: Array<string>
  }
): Record<string, any> {
  if (currentTab === ORIGINAL) {
    return originalProduct
  }

  if (!productSku) {
    return printProducts[0]
  }

  if (optionIds.length > 0) {
    const printProductBySkuAndOptions = printProducts.find(
      ({
        sku,
        options,
      }: {
        sku: string
        options: Array<{
          id: string
        }>
      }) => sku === productSku && options.find(({ id: optionId }) => optionIds.includes(optionId))
    )

    if (printProductBySkuAndOptions) {
      return printProductBySkuAndOptions
    }
  }

  const printProductBySku = printProducts.find(({ sku }) => sku === productSku)
  return printProductBySku || printProducts[0]
}
type SelectedProductSkuOptions = {
  productSku: string | null | undefined
  optionIds: Array<string>
}
const getProductSkuOptions = createSelector(
  [getCurrentSku],
  (currentSku): SelectedProductSkuOptions => {
    // Don't try to parse, other SELECTORS will handle the logic
    if (!currentSku) {
      return {
        productSku: null,
        optionIds: [],
      }
    }

    const { artworkId, canvasWrapId, frameId, productId, type, userId } = parseSku(currentSku)
    const productSku = `${productId}-${userId}-${artworkId}-${type}`
    // const [productSku, optionId] = currentSku.split(/-[F|W]/)
    return {
      productSku,
      optionIds: [frameId, canvasWrapId].filter(Boolean),
    }
  }
)
export const getSelectedProduct = createSelector(
  [getCurrentTab, getOriginalProduct, getPrintProducts, getProductSkuOptions],
  getSelectedProductSelector
)
export type SelectedCanvasWrapColorOption =
  | {
      id: string
      extendedDescription: string
      title: string
      description: string
      price: number
    }
  | null
  | undefined

/** @see ./product.spec.js Exported for testing only */
export function getSelectedCanvasWrapColorOptionSelector(
  currentTab: string,
  printProduct: Record<string, any>,
  {
    productSku,
    optionIds: chosenOptionIds,
  }: {
    productSku: string
    optionIds: Array<string>
  }
): SelectedCanvasWrapColorOption {
  if (![OPEN_EDITION, LIMITED_EDITION].includes(currentTab) || !printProduct?.print) {
    return null
  }

  const {
    print: { recommendedOptionId },
    options: availableOptions,
  } = printProduct
  const selectedOptionIds =
    chosenOptionIds.length > 0 ? chosenOptionIds : [recommendedOptionId].filter(Boolean)
  const selectedOptions = availableOptions.filter(({ id }) => selectedOptionIds.includes(id))
  return selectedOptions.find(({ id }) => id.charAt(0) === 'W') ?? null
}
export const getSelectedCanvasWrapColorOption = createSelector(
  [getCurrentTab, getSelectedProduct, getProductSkuOptions],
  getSelectedCanvasWrapColorOptionSelector
)
export type SelectedFrameOption =
  | {
      id: string
      extendedDescription: string
      title: string
      description: string
      price: number
      framingTypeId: number
      width: string
      height: string
    }
  | null
  | undefined

/** @see ./product.spec.js exported for testing only */
export function getSelectedFrameOptionSelector(
  currentTab: string,
  printProduct: Record<string, any>,
  {
    productSku,
    optionIds: chosenOptionIds,
  }: {
    productSku: string
    optionIds: Array<string>
  }
): SelectedFrameOption {
  if (![OPEN_EDITION, LIMITED_EDITION].includes(currentTab)) {
    return null
  }

  if (!printProduct || !printProduct.print) {
    return null
  }

  const {
    print: { recommendedOptionId },
    options: availableOptions,
    isLimitedEdition,
  } = printProduct

  if (!productSku) {
    // default to white frame if any available
    return (
      availableOptions.find(({ title, id }) => id.charAt(0) === 'F' && title === 'White') ?? null
    )
  }

  const selectedOptionIds =
    isLimitedEdition && chosenOptionIds.length === 0
      ? [recommendedOptionId].filter(Boolean)
      : chosenOptionIds
  return (
    availableOptions.find(({ id }) => selectedOptionIds.includes(id) && id.charAt(0) === 'F') ??
    null
  )
}
export const getSelectedFrameOption = createSelector(
  [getCurrentTab, getSelectedProduct, getProductSkuOptions],
  getSelectedFrameOptionSelector
)

/** @see ./product.spec.js exported for testing only */
export const getTotalPriceInCentsOfSelectedOptionsSelector = (
  selectedFrameOption: SelectedFrameOption,
  selectedCanvasWrapColorOption: SelectedCanvasWrapColorOption
): number =>
  [selectedFrameOption, selectedCanvasWrapColorOption]
    .filter(Boolean)
    .reduce(
      (carry, selectedOption: Record<string, any>): number => carry + selectedOption.price || 0,
      0
    )
export const getTotalPriceInCentsOfSelectedOptions = createSelector(
  [getSelectedFrameOption, getSelectedCanvasWrapColorOption],
  getTotalPriceInCentsOfSelectedOptionsSelector
)

/** @see ./product.spec.js exported for testing only */
export function getSelectedProductSkuSelector(
  selectedProduct: Record<string, any>,
  selectedCanvasWrapColorOption: SelectedCanvasWrapColorOption,
  selectedFrameOption: SelectedFrameOption
): string {
  if (Object.keys(selectedProduct).length === 0) {
    return ''
  }

  const selectedOptionIds = [selectedCanvasWrapColorOption, selectedFrameOption]
    .filter(Boolean)
    .map(({ id }) => id)
  return [selectedProduct.sku, ...selectedOptionIds].join('-')
}
export const getSelectedProductSku = createSelector(
  [getSelectedProduct, getSelectedCanvasWrapColorOption, getSelectedFrameOption],
  getSelectedProductSkuSelector
)
export const getSelectedProductType = createSelector(
  [getSelectedProduct],
  (selectedProduct): string => {
    if (selectedProduct.isOriginal) {
      return ORIGINAL
    }

    if (selectedProduct.isOpenEdition) {
      return OPEN_EDITION
    }

    if (selectedProduct.isLimitedEdition) {
      return LIMITED_EDITION
    }

    return ''
  }
)
export const getSelectedProductStatus = createSelector(
  [getSelectedProduct],
  (selectedProduct): string => getProductStatus(selectedProduct) || ''
)
// Used for `metadata`
export const getSelectedProductAvailability = createSelector(
  [getSelectedProductStatus],
  (status): string => {
    let availability

    switch (status) {
      case SOLD_OUT:
      case RESERVED:
        availability = 'SoldOut'
        break

      case NOT_FOR_SALE:
        availability = 'OutOfStock'
        break

      case FOR_SALE:
      default:
        availability = 'InStock'
        break
    }

    return availability
  }
)
export const getSelectedProductPrice = createSelector(
  [getCurrentTab, getSelectedProduct],
  (currentTab, selectedProduct): number => {
    if (Object.keys(selectedProduct).length === 0) {
      return 0
    }

    if (currentTab === ORIGINAL) {
      const { original } = selectedProduct
      return original.listPrice || 0
    }

    if ([OPEN_EDITION, LIMITED_EDITION].includes(currentTab)) {
      const { print } = selectedProduct
      return print.listPrice || 0
    }

    return 0
  }
)
export const getSelectedProductLegacySku = createSelector(
  [getSelectedProduct],
  (selectedProduct): number => selectedProduct.legacySku || 0
)
export const getOriginalProductOriginal = createSelector(
  [getOriginalProduct],
  (originalProduct): Record<string, any> => originalProduct.original || {}
)
export const getOriginalProductPackagingOption = createSelector(
  [getOriginalProductOriginal],
  (original): string => original.packagingOption || ''
)
export const getOriginalProductReadyToHangStatus = createSelector(
  [getOriginalProductOriginal],
  (original): string => {
    if (original.isReadyToHang === null) {
      return 'Not applicable'
    }

    return original.isReadyToHang ? 'Yes' : 'No'
  }
)
export const getOriginalProductFrameColor = createSelector(
  [getOriginalProductOriginal],
  (original): string => original.frameColor || ''
)
export const getOriginalProductFrameStatus = createSelector(
  [getOriginalProductOriginal],
  (original): string => {
    if (original.hasFrame === null) {
      return 'Not applicable'
    }

    return original.hasFrame ? toTitleCase(original.frameColor) : 'Not Framed'
  }
)
export const getOriginalProductListPrice = createSelector(
  [getOriginalProductOriginal],
  (original): number => original.listPrice || 0
)
export const getOriginalProductShippingDimensions = createSelector(
  [getOriginalProductOriginal],
  (original): Record<string, any> => original.shippingDimensions || {}
)
export const getOriginalProductShipsFromCountry = createSelector(
  [getOriginalProductOriginal],
  (original): Record<string, any> => original.shippingCountry || ''
)
export const getOriginalProductAisp = createSelector(
  [getOriginalProductOriginal],
  (original): Record<string, any> => original.aisp || {}
)
export const getOriginalProductFreightAmount = createSelector(
  [getOriginalProductOriginal],
  (original): number => original.freightAmount || 0
)
export const getOriginalProductShippingPrice = createSelector(
  [getOriginalProductAisp],
  (aisp): number => aisp.freightAmount || 0
)
export const getArtworkAdminData = createSelector(
  [
    getArtwork,
    getOriginalProduct,
    getOriginalProductListPrice,
    getOriginalProductFreightAmount,
    getOriginalProductShippingDimensions,
  ],
  (artwork, originalProduct, listPrice, freightAmount, shippingDimensions): Record<string, any> => {
    const { hasOriginal, legacyUserArtId: artId, uploadedAt, slug, artworkId } = artwork
    const { legacySku: originalSku, sku: paletteSku } = originalProduct
    return {
      artId,
      artworkId,
      originalProduct: hasOriginal,
      originalSku,
      paletteSku,
      listPrice,
      freightAmount,
      shippingDimensions,
      slug,
      uploadedAt,
    }
  }
)
export const getPrintMaterials = createSelector(
  [getCurrentTab, getPrintProducts],
  (currentTab, printProducts): Array<string> => {
    const materialList = []

    if (currentTab !== ORIGINAL) {
      printProducts.forEach((product) => {
        if (!materialList.includes(product.material)) {
          materialList.push(product.material)
        }
      })
    }

    return materialList
  }
)
