/* Pulled from https://usehooks.com/useScript/ */
import { useState, useEffect } from 'react'
export const STATUS = {
  LOADING: 'loading',
  IDLE: 'idle',
  ERROR: 'error',
  READY: 'ready',
}
type OPTIONS = {
  async?: boolean
  src?: string
  text?: string
  name: string // Required to check if SCRIPT exist

  [key: string]: string
}

// Hook
const useScript = (options: OPTIONS) => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(options.src ? STATUS.LOADING : STATUS.IDLE)
  useEffect(
    () => {
      if (window && document) {
        // Fetch existing script element by src
        // It may have been added by another intance of this hook
        let script: HTMLElement | null = document.querySelector(
          `script[data-name="${options.name}"]`
        )

        if (!script) {
          // Create script
          script = document.createElement('script')
          script.setAttribute('data-status', STATUS.LOADING)

          // Allow CUSTOM options to be added
          if (options) {
            Object.entries(options).forEach(([key, value]) => {
              if (key && value) {
                // $FlowBug - Ignore the ERRORS
                script[key] = value
              }
            })
          }

          // Add script to document body
          document.body && document.body.appendChild(script)

          // Store status in attribute on script
          // This can be read by other instances of this hook
          const setAttributeFromEvent = (event: Event) => {
            if (script) {
              script.setAttribute(
                'data-status',
                event.type === 'load' ? STATUS.READY : STATUS.ERROR
              )
            }
          }

          script.addEventListener('load', setAttributeFromEvent)
          script.addEventListener('error', setAttributeFromEvent)
        } else {
          // Grab existing script status from attribute and set to state.
          setStatus(script.getAttribute('data-status'))
        }

        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        const setStateFromEvent = (event: Event) => {
          setStatus(event.type === 'load' ? STATUS.READY : STATUS.ERROR)
        }

        // Add event listeners
        script.addEventListener('load', setStateFromEvent)
        script.addEventListener('error', setStateFromEvent)
        // Remove event listeners on cleanup
        return () => {
          if (script) {
            script.removeEventListener('load', setStateFromEvent)
            script.removeEventListener('error', setStateFromEvent)
            document.body && document.body.removeChild(script)
          }
        }
      } else {
        return () => {}
      }
    },
    [options.src, options.text] // Only re-run effect if script src or text changes
  )
  return status
}

export default useScript