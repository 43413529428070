//@ts-nocheck

import * as React from 'react'
import styled from 'styled-components'
export const Container = styled.div`
  height: 0;
  overflow: hidden;
  transition: 0.3s ease height;
  will-change: height;
  contain: paint;
`
export const Inner = styled.div``
type Props = {
  children?: React.ReactNode
  open: boolean
  overflowScroll?: boolean
}
type State = {
  innerContentHeight: (number | null | undefined) | (string | null | undefined)
}
export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      innerContentHeight: props.open ? 'auto' : null,
    }
  }

  componentDidMount() {
    this.computeInnerContentHeight()
    window.addEventListener('resize', this.computeInnerContentHeight)
    this.initializeSupportedDOMChecker()
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.open && nextProps.open) {
      this.computeInnerContentHeight()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.computeInnerContentHeight)
    this.disconnectSupportedDOMChecker()
  }

  initializeSupportedDOMChecker = () => {
    if (!this.innerContentEl) {
      return
    }

    if (window.MutationObserver || window.WebKitMutationObserver) {
      this.initializeMutationObserver()
    } else {
      this.initializeManualHeightChecker()
    }
  }

  /**
   * Poll DOM for height changes to inner content
   */
  initializeManualHeightChecker = () => {
    this.heightCheckerInterval = setInterval(() => {
      if (!this.innerContentEl) {
        return
      }

      if (this.innerContentEl.clientHeight !== this.state.innerContentHeight) {
        this.computeInnerContentHeight()
      }
    }, 1000)
  }

  /**
   * Use the MutationObserver API for monitoring DOM updates
   */
  initializeMutationObserver = () => {
    if (!this.innerContentEl || (!window.MutationObserver && !window.WebKitMutationObserver)) {
      return
    }

    const ObserverClass = window.MutationObserver || window.WebKitMutationObserver
    // Recompute inner content height on DOM change
    this.observer = new ObserverClass(() => {
      this.computeInnerContentHeight()
    })
    this.observer.observe(this.innerContentEl, {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
    })
  }
  disconnectSupportedDOMChecker = () => {
    if (this.observer) {
      this.observer.disconnect()
    }

    if (this.heightCheckerInterval) {
      clearInterval(this.heightCheckerInterval)
    }
  }
  computeInnerContentHeight = () => {
    if (!this.innerContentEl) {
      return
    }

    this.setState({
      innerContentHeight: this.innerContentEl.clientHeight,
    })
  }
  innerContentEl: HTMLDivElement | null | undefined = null
  observer: MutationObserver | null | undefined = null
  heightCheckerInterval: IntervalID | null | undefined = null
  props: Props

  render() {
    const { children, open, overflowScroll } = this.props
    const { innerContentHeight } = this.state
    return (
      <Container
        style={{
          height: open && innerContentHeight ? innerContentHeight : null,
          overflow: overflowScroll ? 'scroll' : 'hidden',
        }}
      >
        <Inner
          ref={(ref) => {
            this.innerContentEl = ref
          }}
        >
          {children}
        </Inner>
      </Container>
    )
  }
}
