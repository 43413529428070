import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { lighten } from 'polished'
export const Affirm = styled.button`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  color: #ffffff;
  border: none;
  ${(props) => props.styles};

    font-size: 11px;
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.2s ease 0s;
    &:hover {
      color: #ffffff;
    }
  }

  a {
    font-size: 12px;

    color: #48a2d1;
    &:hover {
      color: ${(props) => lighten(0.2, props.theme.colors.lightGray)};
    }
    .__affirm-logo {
      margin: 0 0 3px 5px;
    }
  }

  &.affirm-as-low-as {
    a.affirm-modal-trigger {
      color: #48a2d1;
    }
  }
`