import React from 'react'
import { PlainLink } from 'components/A'
import Tooltip from 'components/Tooltip'
import { getPackagingType, getPackagingTooltip } from 'lib/artwork'
import type { ConnectorProps as ProductOriginalProps } from '../../../connectors/ProductsOriginal'
import ProductOriginalConnector from '../../../connectors/ProductsOriginal'
import { Row, Text, TooltipContent } from '../../styles/products'
import { InfoCircleIcon } from '../../styles/icons'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ProductOriginalConnector((props: ProductOriginalProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { packagingOption } = props

  if (!packagingOption) {
    return null
  }

  return (
    <Row data-type='packaging'>
      <SATypography variant={SATypographyVariant.MEDIUM}>
        {getPackagingType[packagingOption]}
      </SATypography>
      <Tooltip
        position='bottom'
        color='#333'
        tip={
          <TooltipContent p={1} fontSize={1}>
            {getPackagingTooltip[packagingOption]}{' '}
            <PlainLink
              href='https://support.saatchiart.com/hc/en-us/articles/210986188'
              target='_blank'
              rel='noopener noreferrer'
            >
              Read More
            </PlainLink>
          </TooltipContent>
        }
      >
        <InfoCircleIcon />
      </Tooltip>
    </Row>
  )
})