import React from 'react'
import type { ConnectorProps as ARExperienceProps } from '../../connectors/ARExperience'
import ARExperienceConnector from '../../connectors/ARExperience'
import { ARIcon } from '../styles/arExperience'
export default ARExperienceConnector((props: ARExperienceProps): React$ElementRef<any> | null => {
  const { isARExperienceAvailable, openARExperience } = props

  if (!isARExperienceAvailable) {
    return null
  }

  return (
    <>
      <ARIcon
        data-type='desktop'
        onClick={() => {
          openARExperience()
        }}
      >
        Open VR Experience
      </ARIcon>
    </>
  )
})