import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getLocaleCountryCode } from 'selectors/shared/locale'
import { isSessionSet } from 'selectors/user/session'
import { Actions as DataLayerActions } from 'actions/shared/dataLayer'
import { Actions as ModalActions } from 'actions/shared/modal'
import { Actions } from '../actions'
import {
  getArtistCountry,
  getArtistId,
  getArtistName,
  getArtistProfileUrl,
} from '../selectors/artist'
import {
  getArtworkId,
  getArtworkCategory,
  getArtworkLegacyUserArtId,
  getArtworkTitle,
  getArtworkUserId,
  getArtworkDimensions,
  getIsFairchainArtwork,
} from '../selectors/artwork'
import {
  getOriginalProductIsAple,
  getOriginalProductPackagingOption,
  getOriginalProductPrice,
  getOriginalProductListPrice,
  getProductTabLinkUrls,
  getSelectedProductLegacySku,
  getSelectedProductSku,
  getSelectedProductStatus,
  getCurrentProductType,
} from '../selectors/product'
const mapStateToProps = createStructuredSelector({
  artistId: getArtistId,
  artistName: getArtistName,
  artworkId: getArtworkId,
  category: getArtworkCategory,
  country: getArtistCountry,
  dimensions: getArtworkDimensions,
  isSessionSet,
  isAple: getOriginalProductIsAple,
  isFairchain: getIsFairchainArtwork,
  legacySku: getSelectedProductLegacySku,
  legacyUserArtId: getArtworkLegacyUserArtId,
  localizationCountry: getLocaleCountryCode,
  packagingOption: getOriginalProductPackagingOption,
  price: getOriginalProductPrice,
  listPrice: getOriginalProductListPrice,
  productStatus: getSelectedProductStatus,
  productType: getCurrentProductType,
  profileUrl: getArtistProfileUrl,
  sku: getSelectedProductSku,
  tabUrls: getProductTabLinkUrls,
  title: getArtworkTitle,
  userId: getArtworkUserId,
})

const mapDispatchToProps = (dispatch) => ({
  clickAddToCart: (sku: string) => dispatch(Actions.CLICK_ADD_TO_CART(sku)),
  clickMakeAnOffer: () => dispatch(Actions.CLICK_MAKE_AN_OFFER()),
  clickViewMore: (artworkId: string) =>
    dispatch(
      Actions.CLICK_VIEW_MORE({
        artworkId,
      })
    ),
  updateDataLayer: (payload: Record<string, any>) =>
    dispatch(DataLayerActions.UPDATE_DATALAYER(payload)),
  closeModal: (payload: string) => {
    return dispatch(ModalActions.CLOSE_MODAL())
  },
  openModal: (payload: string) => {
    return dispatch(ModalActions.OPEN_MODAL(payload))
  },
  updateUrl: (payload: string) => dispatch(Actions.UPDATE_URL(payload)),
})

export type ConnectorProps = {
  artistId: number
  artistName: string
  artworkId: number
  category: number
  clickAddToCart: (...args: Array<any>) => any
  clickMakeAnOffer: (...args: Array<any>) => any
  clickViewMore: (...args: Array<any>) => any
  closeModal: (...args: Array<any>) => any
  country: string
  dimensions: Record<string, any>
  isAple: boolean
  isFairchain: boolean
  isSessionSet: boolean
  legacySku: number
  legacyUserArtId: number
  localizationCountry: string
  openModal: (...args: Array<any>) => any
  updateUrl: (...args: Array<any>) => any
  packagingOption: string
  price: number
  listPrice: number
  productStatus: string
  productType: string
  profileUrl: string
  sku: string
  tabUrls: Record<string, any>
  title: string
  updateDataLayer: (...args: Array<any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps)