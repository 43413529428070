import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getABTestValues } from 'selectors/user/abTest'
import { getArtistCountry, getArtistName, getArtistProfileUrl } from '../selectors/artist'
import {
  getArtworkCategory,
  getArtworkTitle,
  getArtworkDimensions,
  getArtworkMediums,
  getArtworkMaterials,
} from '../selectors/artwork'
import {
  getProductTabShowInfo,
  getCurrentTab,
  getOriginalProductUnitsProduced,
  getOriginalProductIsAple,
} from '../selectors/product'
const mapStateToProps = createStructuredSelector({
  category: getArtworkCategory,
  mediums: getArtworkMediums,
  materials: getArtworkMaterials,
  title: getArtworkTitle,
  dimensions: getArtworkDimensions,
  country: getArtistCountry,
  artistName: getArtistName,
  isAple: getOriginalProductIsAple,
  profileUrl: getArtistProfileUrl,
  showInfo: getProductTabShowInfo,
  currentTab: getCurrentTab,
  unitsProduced: getOriginalProductUnitsProduced,
  abTest: getABTestValues,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  artistName: string
  category: string
  country: string
  dimensions: Record<string, any>
  isAple: boolean
  profileUrl: string
  showInfo: boolean
  title: string
  currentTab: string
  unitsProduced: number
  mediums: Array<string>
  materials: Array<string>
  abTest: Record<string, any>
}
export default connect(mapStateToProps, mapDispatchToProps)