import fetch from 'isomorphic-fetch'
import { deleteOptions, parseJSON } from 'apis/helpers'
export const getArtworkDetails = async ({
  artistId,
  artworkId,
}: Record<string, any>): Promise<Record<string, any>> => {
  const response = await fetch(`/easel_api/artwork/${artistId}/${artworkId}`)
  const json = await parseJSON(response, 'getArtworkDetails')
  return json
}
export const deleteMyArtwork = async (
  artwork: Record<string, any>
): Promise<Record<string, any>> => {
  const { artworkId } = artwork
  const options = { ...deleteOptions, body: JSON.stringify(artwork) }
  const response = await fetch(`/easel_api/my-art/${artworkId}`, options)
  const json = await parseJSON(response, 'deleteMyArtwork')
  return json
}