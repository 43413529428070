import React from 'react'
import { StarIcon } from '../../styles/icons'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const TrustPilot = () => (
  <div data-type='trustpilot-container'>
    <div data-type='trustpilot-stars-container'>
      {[1, 2, 3, 4, 5].map((key) => (
        <StarIcon key={`star-${key}`} />
      ))}
    </div>
    <SATypography variant={SATypographyVariant.SMALL}>Trustpilot Score</SATypography>
  </div>
)

export default TrustPilot
