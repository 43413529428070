import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import FullScreen from 'svg/expand.svg'
export { ViewInARoomIcon, FullScreenIcon, ViewInRoomARIcon } from '../icons'
export const FullscreenIcon = styled(FullScreen)`
  fill: ${(props) => props.theme.colors.white};
`
export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: opacity;
  transition: 0.3s ease-in-out opacity;
  width: 180px;
  height: 40px;
  background: #333;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  ${(props) =>
    props.color &&
    `
    [data-type='favorite-icon'] {
      fill: ${props.color};
      }
      &:hover {
        [data-type='favorite-icon'] {
          fill: ${props.color === 'red' ? 'white' : 'red'};
          }
      }
    `};
  p {
    font-size: 13px;
    color: ${(props) => props.theme.colors.white};
    margin: ${(props) => props.theme.space[1]}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    opacity: 1;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    p {
      display: none;
    }
    ${(props) =>
      props.color &&
      `
      [data-type='favorite-icon'], &:hover [data-type='favorite-icon']{
        fill: ${props.color};
        }
      `};
  }
`
export const Actions = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  padding-top: 30px;
  align-items: flex-start;
  justify-content: center;
  background: rgba(255, 255, 255, 0);
  opacity: 0;
  will-change: opacity;
  transition: 0.3s ease-in-out opacity;
  ${Action} p {
    color: ${(props) => props.theme.colors.white};
  }
  &:hover {
    opacity: 1;
  }
  ${Action} + ${Action} {
    margin-left: 20px;
  }
  ${(props) =>
    props.grabImage &&
    `height: 100px; [data-type='view-in-room-AR'] {
      display: none;
    }`}
  @media (max-width: 1024px) {
    [data-type='view-in-room-AR'] {
      display: none;
    }
    }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
    padding-top: 5px;
    padding-right: 5px;
    height: auto;
    opacity: 1;
    justify-content: flex-end;
    [data-type='view-in-room'],
    [data-type='back-to-artwork'], [data-type='view-in-room-AR'] {
      display: none;
    }
    ${Action} + ${Action} {
      margin-left: 10px;
    }
  }
  [data-type='fullscreen']{}
  [data-type='favorite']{}
  [data-type='view-in-room-AR']{
    position: absolute;
    width: 224px;
    height: 44px;
    bottom: 1em;
    margin-bottom: 25px;
    background: #FFFFFFCC;
    border: solid 1px #33333380;
    p {
      margin: 13px 12px 12px 12px;
      color: #333333;
      font-size: 16px;
      width: 150px;
      height: 19px;
      font-weight: bold;
    }
  }
`
// Actions container for VIR Desktop Button that always appears below image abTest[12.04]
export const VIRActionStatic = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
  width: 100%;
  display: block;
  margin: 30px 0px 10px 0px;
  z-index: 3;
  [data-type='view-in-room-AR'] {
    border: solid 1px #333333;
    width: 100%;
    height: 44px;
    background: #ffffffcc;
    border-radius: 0;
    p {
      margin: 7px 0 6px 16px;
      color: #333333;
      font-size: 16px;
      width: 170px;
      height: 19px;
      font-weight: bold;
      display: block;
    }
  }
`