import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import Chevron from 'svg/expand-icon.svg'
export const ChevronIcon = styled(Chevron)`
  width: 11px;
  height: 11px;
  stroke-width: 2;
  padding-top: 4px;
  margin-left: 2px;
`
export const Wrapper = styled.div`
  padding: 20px 20px 50px;
  order: 2;
  background-color: #f9f9f9;
  @media (max-width: 992px) {
    margin-left: -15px;
    margin-right: -15px;
    padding: 40px 14px 24px;
  }
  a {
    text-decoration: none;
  }
  [data-type='rotate-chevron'] {
    transform: rotate(180deg);
    padding-top: 0px;
    padding-bottom: 4px;
  }
  [data-type='row'] {
    display: flex;
    flex-direction: row;
    align-items: center;
    [data-type='profile-button'] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: auto;
      max-width: 200px;
      border: solid 1px #333333;
      text-align: center;
      @media (min-width: 992px) {
        margin-left: auto;
      }
      @media (max-width: 999px) {
        height: 40px;
      }
    }
    [data-type='artist-profile-link'] {
      padding: 12px 48px;
      font-size: 16px;
      text-align: center;
      color: #333333;
    }
  }
  [data-type='info-row'] {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    @media (max-width: 992px) {
      align-items: flex-start;
    }
    [data-type='artist-image'] {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    [data-type='artist-info'] {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-left: 20px;
      @media (min-width: 992px) {
        flex-direction: row;
      }
    }
    [data-type='artist-info-col'] {
      flex-direction: column;
    }
    [data-type='artist-name'] {
      color: #333333;
    }
    [data-type='artist-location'] {
      margin: 0;
      color: #666666;
      margin-top: 2px;
      margin-bottom: 16px;
    }
  }
  [data-type='artist-avatar'] {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    @media (max-width: 992px) {
      width: 100px;
      height: 100px;
    }
  }
  [data-type='artist-about'] {
    margin: 40px 0 0 0;
    color: #333333;
    width: 100%;
    overflow-wrap: anywhere;
  }
  [data-type='artist-about-clamped'] {
    margin: 40px 0 0 0;
    color: #333333;
    overflow: hidden;
    position: relative;
    max-height: 8.6em;
    overflow-wrap: anywhere;
  }
  [data-type='artist-about-clamped']:before {
    content: '.';
    font-size: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 30px 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(249, 249, 249, 1));
  }
  [data-type='read-more'] {
    display: flex;
    justify-content: center;
  }
  [data-type='toggle-button'] {
    flex-direction: row;
    display: flex;
    justify-content: center;
    gap: 5px;
    font-size: 10px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: #333;
    margin-top: 10px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: none;
    outline: none;
    margin-left: 0px;
    padding-left: 0px;
  }
  [data-type='title-section'] {
    h2 {
      font-size: 20px;
      font-weight: bold;
    }
    margin-top: 40px;
    margin-bottom: 24px;

    @media (max-width: 992px) {
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }
  [data-type='badge-row'] {
    display: grid;
    @media (min-width: 992px) {
      grid-column-gap: 50px;
      grid-template-columns: repeat(2, 1fr);
    }

    a {
      color: #666;
      text-decoration: none;
      transition: 0.3s ease-in-out color;
      &:hover {
        color: #333;
      }
    }
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  [data-type='badge-col'] {
    display: flex;
    align-items: center;
  }
  [data-type='badge-text'] {
    color: #333333;
    margin: 12px 0px;
  }
`