import React, { useState, useEffect } from 'react'

import ArtworkDescriptionConnector, {
  type ConnectorProps as ArtworkDescriptionProps,
} from '../../../connectors/ArtworkDescription'
import {
  DELIVERY_TIME_COPY,
  COUNTRIES_CUSTOMS_APPLICABLE,
  RETURN_POLICY_LINK,
  PACKAGING_GUIDELINES_LINK,
  ORIGINAL,
  ROLLED,
  FLAT_CRATE,
  ROLLED_IN_TUBE_TEXT,
  BOX_TEXT,
  CRATE_PACKAGING_TEXT,
} from '../../../helpers/constants'
import AccordionComponent from '../AccordionComponent'
import ContactUs from './ContactUs'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ArtworkDescriptionConnector((props: ArtworkDescriptionProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const {
    category,
    currentTab,
    packingOption,
    isAple,
    screenWidth,
    artworkID,
    shipsFromCountry,
  } = props
  const isNotApleCategory = category !== 'Photography'
  const targetValue = screenWidth > 1064 ? '_blank' : '_self'
  const relValue = screenWidth > 1064 ? 'noopener noreferrer' : ''
  const [showContent, setShowContent] = useState(false)
  useEffect(() => {
    setShowContent(false)
  }, [artworkID])

  const getPackagingType = () => {
    if (packingOption === ROLLED) {
      return ROLLED_IN_TUBE_TEXT
    } else if (packingOption === FLAT_CRATE) {
      return CRATE_PACKAGING_TEXT
    } else return BOX_TEXT
  }

  if (currentTab !== ORIGINAL) {
    return null
  }

  const getReturnSection = () => {
    return (
      <div data-type='accordion-sub-section'>
        <div data-type='accordion-sub-header-title'>
          <SATypography
            truncateText
            data-type='accordion-sub-header'
            variant={SATypographyVariant.H6}
          >
            Returns:
          </SATypography>
        </div>
        <div data-type='accordion-sub-header-content'>
          {isNotApleCategory && !isAple ? (
            <SATypography variant={SATypographyVariant.MEDIUM}>
              {`14-day return policy. Visit our `}
              <a
                data-type='support-link'
                target={targetValue}
                rel={relValue}
                href={RETURN_POLICY_LINK}
                title='help section'
              >
                help section
              </a>
              {` for more information.`}
            </SATypography>
          ) : (
            <SATypography variant={SATypographyVariant.MEDIUM}>
              The purchase of photography and limited edition artworks as shipped by the artist is
              final sale.
            </SATypography>
          )}
        </div>
      </div>
    )
  }

  const getAccordionTitleSection = () => {
    if (!showContent && screenWidth < 500) return null
    if (!showContent && screenWidth > 500 && screenWidth < 1253 && category)
      return getReturnSection()
    return (
      <>
        {category && getReturnSection()}
        <div data-type='accordion-sub-section'>
          <div data-type='accordion-sub-header-title'>
            <SATypography variant={SATypographyVariant.H6}>Delivery Cost:</SATypography>
          </div>
          <div data-type='accordion-sub-header-content'>
            <SATypography variant={SATypographyVariant.MEDIUM}>Shipping is included.</SATypography>
          </div>
        </div>
      </>
    )
  }

  return (
    <AccordionComponent
      dataType='shipping-returns'
      title='SHIPPING AND RETURNS'
      showContent={showContent}
      setShowContent={setShowContent}
      screenWidth={screenWidth}
      headerChildren={
        <>
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Delivery Time:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>{DELIVERY_TIME_COPY}</SATypography>
            </div>
          </div>
          {getAccordionTitleSection()}
        </>
      }
    >
      <>
        {packingOption && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Handling:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {`${getPackagingType()}. Artists are responsible for packaging and adhering to Saatchi Art’s `}
                <a
                  data-type='support-link'
                  target={targetValue}
                  rel={relValue}
                  href={PACKAGING_GUIDELINES_LINK}
                  title='packaging guidelines'
                >
                  packaging guidelines.
                </a>
              </SATypography>
            </div>
          </div>
        )}
        {shipsFromCountry && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Ships From:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {`${shipsFromCountry}.`}
              </SATypography>
            </div>
          </div>
        )}

        {shipsFromCountry && COUNTRIES_CUSTOMS_APPLICABLE.includes(shipsFromCountry) && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Customs:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {`Shipments from ${shipsFromCountry} may experience delays due to country's regulations for exporting valuable artworks.`}
              </SATypography>
            </div>
          </div>
        )}
        <ContactUs />
      </>
    </AccordionComponent>
  )
})