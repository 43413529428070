import React from 'react'
import BadgesStarIcon from 'svg/badges-star.svg'
import BadgesSmileIcon from 'svg/badges-smile.svg'
import BadgesGlobeIcon from 'svg/badges-globe.svg'
import BadgesDollarIcon from 'svg/badges-dollar.svg'

const { ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API } = process.env
export const DEFAULT_APP = '/saatchi/artdetail2'
export const DEFAULT_URL = ''
export const applicationId: string = ALGOLIA_APPLICATION_ID || ''
export const searchApi: string = ALGOLIA_SEARCH_API || ''
// Product Status
export const SOLD = 'sold'
export const AVAILABLE = 'avail'
export const RESERVED = 'reserved'
export const SOLD_OUT = 'soldOut'
export const FOR_SALE = 'forSale'
export const NOT_FOR_SALE = 'notForSale'
// Carousel Api Types
export const ALGOLIA = 'algolia'
export const VISII = 'visii'
export const GOOGLE = 'google'
// Product View Types
export const ARTWORK = 'art' // Original || Info ( no products )

export const PRINT = 'print' // OpenEdition || LimitedEdition

// Product Tab Names/Labels
export const OPEN_EDITION_TAB = 'Prints'
export const LIMITED_EDITION_TAB = 'Limited Editions'
// Product Types
export const ORIGINAL = 'original'
export const OPEN_EDITION = 'open'
export const LIMITED_EDITION = 'limited'
// Print Materials
export const CANVAS = 'Canvas'
export const PHOTO_PAPER = 'Photo Paper'
export const FINE_ART_PAPER = 'Fine Art Paper'
export const ACRYLIC = 'Acrylic'
export const METAL = 'Metal'
export const METAL_LIGHT_PEWTER = 'Metal: Light Pewter'
export const METAL_DARK_PEWTER = 'Metal: Dark Pewter'
// Framing types
export const FRAMING_TYPE_ID_LIGHT_PEWTER = 12
export const FRAMING_TYPE_ID_DARK_PEWTER = 13
// Canvas wrap colors
export const CANVAS_WRAP_COLOR_WHITE = 'White Canvas'
export const CANVAS_WRAP_COLOR_BLACK = 'Black Canvas'
// Packaging Types
export const ROLLED = 'rolled'
export const FLAT_CARDBOARD = 'flat_cardboard'
export const FLAT_CRATE = 'flat_crate'
// Packaging Option Values
export const ROLLED_IN_TUBE = 'Ships Rolled in a Tube'
export const CRATE = 'Ships in a Crate'
export const BOX = 'Ships in a Box'
// Frame Options
export const NOT_FRAMED = 'Not Framed'
export const NOT_APPLICABLE = 'Not Applicable'
// Artwork Description Section
// Shipping & Returns constants
export const DELIVERY_TIME_COPY =
  'Typically 5-7 business days for domestic shipments, 10-14 business days for international shipments.'
export const ACRYLIC_DELIVERY_TIME_COPY =
  'Typically 12-17 business days for domestic shipments, 17-21 business days for international shipments.'
export const METAL_DELIVERY_TIME_COPY =
  'Typically 10-14 business days for domestic shipments, 15-21 business days for international shipments.'
export const COUNTRIES_CUSTOMS_APPLICABLE = [
  'Argentina',
  'Armenia',
  'Brazil',
  'Bolivia',
  'Bulgaria',
  'Chile',
  'China',
  'Colombia',
  'Croatia',
  'Germany',
  'Greece',
  'India',
  'Italy',
  'Mexico',
  'Montenegro',
  'Poland',
  'Romania',
  'Russia',
  'Serbia',
  'Spain',
  'Slovakia',
  'Ukraine',
  'United Kingdom',
  'Venezuela',
]
export const RETURN_POLICY_LINK = 'https://support.saatchiart.com/hc/en-us/articles/14673570463643 '
export const HELP_SECTION_LINK = 'https://support.saatchiart.com/hc/en-us/categories/200254317'
export const CONTACT_US_LINK = 'https://support.saatchiart.com/hc/en-us/requests/new'
export const ART_ADVISORY_LINK = 'https://www.saatchiart.com/artadvisory'
export const PACKAGING_GUIDELINES_LINK =
  'https://support.saatchiart.com/hc/en-us/articles/14709932602139'
export const ROLLED_IN_TUBE_TEXT = 'Ships rolled in a tube'
export const CRATE_TEXT = 'Ships in a crate'
export const BOX_TEXT = 'Ships in a box'
export const CRATE_PACKAGING_TEXT =
  'Ships in a wooden crate for additional protection of heavy or oversized artworks'

// EDITION DETAILS - LIMITED EDITION
export const FRAMING_OPTIONS =
  'Custom frame options are available in solid wood and each framed artwork ships ready to hang––right out of the box. Choose from black, white, or natural wood frames in a classic "box" style.'
export const LEGAL_DISCLOSURE = `The term "limited edition" has various meanings depending upon the artist, publisher, distributor and seller (or reseller). Saatchi Art uses the term "limited edition prints" to denote two dimensional art prints available through a pre-publication license agreement between the artist and Saatchi Art and sold pursuant to an exclusive prints publication arrangement.`
export const LEGAL_DISCLOSURE_TEXT = ` This exclusive arrangement with the artist establishes a total maximum number of prints
        based on a single original high resolution digital image of a specific work of art created
        by the artist for limited and exclusive reproduction based on different paper type and/or
        dimension of the print. Certain information about the limited edition print and sale will be
        provided to buyers in a Certificate of Authenticity which is included with the purchase of
        each limited edition print through Limited by Saatchi Art. Saatchi Art is selling "limited
        edition prints" in compliance with the standards of disclosure established by California law
        regulating the sale of limited edition art prints. The image for any limited edition print
        may, in certain circumstances, be licensed by the artist for reproduction on other consumer
        products, such as phone cases, apparel and other home décor accents (but excluding two
        dimensional art prints).`
export const LEGAL_DISCLOSURE_DATA_ARRAY = [
  {
    title: 'CALIFORNIA LAW',
    text:
      'California law provides for disclosure in writing of information concerning certain fine prints, photographs, and sculptures prior to effecting a sale of them. This law requires disclosure of such matters as the identity of the artist, the artist’s signature, the medium, whether the multiple is a reproduction, the time when the multiple was produced, use of the plate which produced the multiple, and the number of multiples in a "limited edition." If a prospective purchaser so requests, the information shall be transmitted to him or her prior to payment, or the placing of an order for a multiple. If payment is made by a purchaser prior to delivery of the multiple, this information will be supplied at the time of or prior to delivery, in which case the purchaser is entitled to a refund if, for reasons related to the matters contained in such information, he or she returns the multiple in the condition in which it was received, within 14 days of receiving it. In addition, if after payment and delivery, it is ascertained that the information provided is incorrect, the purchaser may be entitled to certain remedies, including a refund upon return of the multiple in the condition in which it was received.',
  },
  {
    title: 'CERTIFICATE OF AUTHENTICITY AND WARRANTIES',
    text: `Buyers of limited edition prints are entitled to a Certificate of Authenticity upon request prior to placing a limited edition print order online, or at the time of or prior to delivery of the limited edition print. Every limited edition print sold through Limited by Saatchi Art comes with a Certificate of Authenticity which includes a certification that the "information and statements contained therein are true and correct."`,
  },
  {
    title: 'AVAILABILITY OF OTHER PRODUCTS THAT INCLUDE THE SUBJECT ART WORK',
    text:
      'Saatchi Art is the exclusive distributor of limited edition art prints available for sale on Limited by Saatchi Art. In certain circumstances, the artist of a work available as a limited edition art print on Limited by Saatchi Art may license the image of the subject work for reproduction related to the sale of other consumer products, such as phone cases, apparel and other home décor accents (but excluding two dimensional art prints). The manner and consumer goods on which the image is reproduced are at the discretion of the artist, but the artist is precluded from permitting the subject work to be made available for reproduction on two dimensional art prints other than limited edition prints available for purchase on Limited by Saatchi Art.',
  },
  {
    title: 'WHAT LIMITED BY SAATCHI ART EDITION NUMBERING MEANS',
    text: `A limited edition print that recites the number 2/25 means that the buyer is purchasing the second published print in a maximum print edition of 25 total prints (plus any proofs) of a specified size and dimension based upon a high resolution digital image of the artist’s original work of art. The Certificate of Authenticity tells the buyer if more than one edition has been published from the original image. For example, a print numbered 2/25 in a limited edition of 25 could be sold measuring 12" x 24" and another print numbered 2/15 in a limited edition of 15 could be sold measuring 24" x 36". The Certificate of Authenticity in that example would show that the total number is 40 prints (plus any proofs).`,
  },
]
export const PRINT_DEPTH = 1.2 // for framed print (in inches)

export const CANVAS_PRINT_DEPTH = 1.25 // in inches

export const CANVAS_WRAP_FRAME_SIZE_IN_INCHES = 0.625
export const CANVAS_WRAP_GAP_SIZE_IN_INCHES = 0.25 // gap between canvas and frame

export const CANVAS_WRAP_COLOR_OPTION_ID_BLACK = 'W39'
// constants for USP section
export const USPList = [
  {
    image: <BadgesStarIcon width={40} height={40} />,
    title: 'Thousands of 5-Star Reviews',
    description: 'We deliver world-class customer service to all of our art buyers.',
  },
  {
    image: <BadgesSmileIcon width={40} height={40} />,
    title: 'Satisfaction Guaranteed',
    description: 'Our 14-day satisfaction guarantee allows you to buy with confidence.',
  },
  {
    image: <BadgesGlobeIcon width={40} height={40} />,
    title: 'Global Selection of Emerging Art',
    description: 'Explore an unparalleled artwork selection by artists from around the world.',
  },
  {
    image: <BadgesDollarIcon width={40} height={40} />,
    title: 'Support An Artist With Every Purchase',
    description: 'We pay our artists more on every sale than other galleries.',
  },
]
// AB Test values for Sticky Cart
export const STICKY_CART_CONTROL_VERSION = '01'
export const STICKY_CART_VARIATION_VERSION = '02'
// AB Test - carousel
export const MORE_FROM_ARTIST_VARIATION = 'moreFromArtistVariation'
export const VISUALLY_SIMILAR_VARIATION = 'visuallySimilarVariation'
export const GOOGLE_REC_VARIATION = 'googleRecVariation'
// gallery spec images
export const PAPER_PRINT_FRAME_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Product_Detail_Frames.jpg',
  width: 1662,
  height: 1106,
  thumbnailUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Product_Detail_Thumbnail.jpg',
}
export const CANVAS_WRAP_FRAME_IMAGE_WHITE = {
  imageUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/art-detail/canvas-wrap-frame-white-detail-2x.jpg',
  width: 1662,
  height: 1814,
  thumbnailUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/art-detail/canvas-wrap-frame-white-detail-thumbnail.jpg',
}
export const CANVAS_WRAP_FRAME_IMAGE_BLACK = {
  imageUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/art-detail/canvas-wrap-frame-black-detail-2x.jpg',
  width: 1662,
  height: 1814,
  thumbnailUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/art-detail/canvas-wrap-frame-black-detail-thumbnail.jpg',
}
export const LIMITED_AUTHENTICITY_IMAGE = {
  imageUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/static/img/certificate-of-authenticity-numbered.jpg',
  width: 1662,
  height: 1106,
  thumbnailUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/static/img/certificate-of-authenticity-numbered-thumb.jpg',
}
export const PAPER_PRINT_FRAME_BACKING_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/static/img/white-frame-backing.jpg',
  width: 1662,
  height: 1109,
  thumbnailUrl: 'https://d3t95n9c6zzriw.cloudfront.net/static/img/white-frame-backing-thumb.jpg',
}
export const CANVAS_WRAP_FRAME_BACKING_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/canvas-wrap-frame-back-2x.jpg',
  width: 1662,
  height: 1109,
  thumbnailUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/art-detail/canvas-wrap-frame-back-thumbnail.jpg',
}
export const PAPER_PRINT_SPECS_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Specs2.jpg',
  width: 1658,
  height: 1109,
  thumbnailUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Specs_Thumbnail2.jpg',
}
export const CANVAS_WRAP_PRINT_SPECS_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/canvas-wrap-frame-spec-2-2x.jpg',
  width: 1658,
  height: 1146,
  thumbnailUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/art-detail/canvas-wrap-frame-spec-2-thumbnail.jpg',
}
export const ACRYLIC_FRAME_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Product_Detail_Acrylic2.jpg',
  width: 1662,
  height: 1106,
  thumbnailUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Product_Thumbnail_Acrylic2.jpg',
}
export const ACRYLIC_FRAME_BACKING_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Backing_Acrylic_print2.jpg',
  width: 1662,
  height: 1106,
  thumbnailUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Backing_Acrylic_Thumbnail.jpg',
}
export const ACRYLIC_SPECS_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Spec_Detail_Acrylic2.jpg',
  width: 1658,
  height: 1109,
  thumbnailUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Specs_Detail_Thumbnail_Acrylic2.jpg',
}
export const METAL_FRAME_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Product_Detail_Metal3.jpg',
  width: 1662,
  height: 1106,
  thumbnailUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Product_Detail_Thumbnail_Metal3.jpg',
}
export const METAL_FRAME_BACKING_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Backing_Metal2.jpg',
  width: 1662,
  height: 1109,
  thumbnailUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Backing_Metal_Thumbnail.jpg',
}
export const METAL_SPECS_IMAGE = {
  imageUrl: 'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Specs_Detail_Metal3.jpg',
  width: 1658,
  height: 1146,
  thumbnailUrl:
    'https://d3t95n9c6zzriw.cloudfront.net/art-detail/Specs_Detail_Thumbnail_Metal3.jpg',
}
