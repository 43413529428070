import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getPageData } from 'selectors/page'
import { getArtworkViews } from '../selectors/artwork'
const mapStateToProps = createStructuredSelector({
  artworkViews: getArtworkViews,
  sellOutRisk: (store): string => {
    const { sellOutRisk } = getPageData(store)
    return sellOutRisk || 'none'
  },
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  artworkViews: number
  sellOutRisk: string
}
export default connect(mapStateToProps, mapDispatchToProps)