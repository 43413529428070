import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import Chevron from 'svg/expand-icon.svg'
export const ChevronIcon = styled(Chevron)`
  right: 50%;
  width: 15px;
  height: 40px;
  stroke-width: 1.2;
`
export const Layout = styled.div`
  margin-bottom: 50px;
  margin-top: 10px;
  order: 1;
  [data-type='top-divider'],
  [data-type='bottom-divider'] {
    width: 100%;
    height: 2px;
    background-color: #eeeeee;
  }
  [data-type='top-divider'] {
    margin: 0 0 20px;
  }
  [data-type='bottom-divider'] {
    margin: 0;
  }
  [data-type='about-artwork'] {
    @media (max-width: 768px) {
      [data-type='accordion-year'] {
        margin-top: 30px;
      }
    }
  }
  [data-type='product-details'] {
    [data-type='accordion-mobile'] {
      margin-top: -8px;
    }
  }
  [data-type='limited-edition-details'] {
    [data-type='accordion-mobile'] {
      margin-top: -5px;
    }
  }
  [data-type='shipping-returns'] {
    [data-type='accordion-mobile'] {
      margin-top: -8px;
    }
  }
  [data-type='legal-disclosure'] {
    [data-type='accordion-mobile'] {
      margin-top: -18px;
    }
    [data-type='sub-section'] {
      margin-bottom: 20px;
    }
  }
  [data-type='legal-disclosure'] {
    [data-type='year-value'] {
      line-height: 1.45em;
    }
  }
  [data-type='product-details'],
  [data-type='shipping-returns'],
  [data-type='limited-edition-details'],
  [data-type='legal-disclosure'] {
    [data-type='title-col'] {
      [data-type='title-text'] {
        width: 228px;
      }
    }
    [data-type='data-row'] {
      width: 100%;
      margin: 0 20px 0px 55px;
      @media (max-width: 1024px) {
        margin-top: 20px;
        margin-left: 0px;
      }
    }
    [data-type='accordion-year'] {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`
export const Wrapper = styled.div`
  [data-type='row'],
  [data-type='accordion'],
  [data-type='accordion-mobile'],
  [data-type='title-col'],
  [data-type='data-row'] {
    display: flex;
    [data-type='title-col'] {
      [data-type='title-text'] {
        width: 228px;
      }
    }
  }
  [data-type='rotate-chevron'] {
    transform: rotate(180deg);
  }
  button:focus {
    outline: 0;
  }
  [data-type='accordion'] {
    flex-direction: row;
    text-align: left;
    background-color: transparent;
    border: none;
    width: 100%;
    position: relative;
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 0px;
    }
  }
  [data-type='accordion-mobile'] {
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-items: center;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  [data-type='accordion-sub-section'] {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 16px;
    &[data-type-variant='description'],
    &[data-type-variant='help'],
    &[data-type-variant='legal'] {
      margin-bottom: 0;
    }
    &[data-type-variant='legal-content'] {
      [data-type='accordion-sub-header'] {
        font-size: 10px;
        margin-top: 1em;
        margin-bottom: 0;
      }
    }
    p,
    p {
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #333333;
      margin: 0;
      [data-type='accordion-sub-header'] {
        font-weight: bold;
        font-size: 12px;
      }
    }
    [data-type='description'] {
      margin: 0px 0px 25px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    [data-type='legal'] {
      margin-bottom: 20px;
    }
  }
  [data-type='accordion-year'] {
    margin-top: 45px;
    margin-bottom: 8px;
  }
  [data-type='subject-section'] {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  [data-type='accordion-content'] {
    margin-left: 283px;
    margin-top: 0px;
    margin-right: 20px;
    @media (max-width: 1024px) {
      margin-left: 0px;
      margin-top: 0px;
      margin-right: 0px;
    }
  }
  [data-type='data-row'] {
    flex-direction: column;
    word-break: break-word;
    margin: 0 20px 0px 55px;
    @media (max-width: 1024px) {
      margin: 0;
      margin-top: 20px;
    }
  }
  [data-type='subTitle-text'] {
    font-weight: bold;
    font-size: 12px;
    [data-type='year-value'],
    [data-type='year-value'] {
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }
  p {
    [data-type='support-link'] {
      display: inline !important;
      text-decoration: underline;
      &:not(:last-child):after {
        content: '';
        padding-right: 0px;
        color: #333333;
      }
    }
  }
  a {
    display: inline-block;
    color: #666;
    text-decoration: none;
    &:hover {
      color: #333333;
    }
  }
  [data-type='read-more-button'],
  [data-type='title'] {
    font-size: 10px;
    font-weight: bold;
  }
  [data-type='read-more-button'] {
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 0px;
    padding-left: 0px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: none;
    outline: none;
    color: #666;
    &:hover {
      color: #333333;
    }
  }
  [data-type='title'] {
    margin-bottom: 0px;
  }
  [data-type='accordion-sub-header-title'] {
    min-width: 100px;
    flex: 1;
  }
  [data-type='accordion-sub-header-content'] {
    display: flex;
    flex-direction: row;
    flex: 3;
    p {
      line-height: 1.5rem;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        line-height: 1.25rem;
      }
    }
    a {
      display: flex;
      p {
        color: #666;
        text-decoration: none;
        &:hover {
          color: #333333;
        }
        &:not(:last-child):after {
          content: ',';
          padding-right: 7px;
          color: #333333;
        }
      }
    }
    [data-type='break-text-container'] {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
    [data-type='break-text'] {
      flex: 1 1 auto;
      min-width: 0;
    }
    [data-type='multiple-links'] {
      display: inline-block !important;
    }
  }
`
