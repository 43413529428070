import { createSelector } from 'reselect'
import { getArtistId, getArtistName, getArtistCountry, getArtistBadgesList } from './artist'
import {
  getArtworkId,
  getArtworkCategory,
  getArtworkTitle,
  getArtworkMediums,
  getArtworkSubject,
  getArtworkStyles,
} from './artwork'
import {
  getArtworkOriginalStatus,
  getOriginalProductListPrice,
  getCurrentTab,
  getSelectedProductSku,
  getSelectedProductPrice,
} from './product'
import { convertFromCentsToDollars } from '../helpers'
const dataDefaults = {
  event: 'pagetype',
  pagetype: 'artDetail',
}
export const getDataLayerEcommerce = createSelector(
  [
    getArtworkCategory,
    getArtworkTitle,
    getCurrentTab,
    getSelectedProductSku,
    getSelectedProductPrice,
  ],
  (category, title, currentTab, productSku, price): Record<string, any> => ({
    currencyCode: 'USD',
    detail: {
      products: [
        {
          name: title,
          id: productSku,
          price: convertFromCentsToDollars(price),
          brand: 'saatchi',
          category,
          variant: currentTab,
          quantity: 1,
        },
      ],
    },
  })
)
export const getDataLayerGA4 = createSelector(
  [
    getArtworkCategory,
    getArtworkTitle,
    getCurrentTab,
    getSelectedProductSku,
    getSelectedProductPrice,
    getArtistName,
    getArtistCountry,
    getArtworkOriginalStatus,
    getArtworkId,
  ],
  (
    category,
    title,
    currentTab,
    productSku,
    price,
    artistName,
    artistCountry,
    originalStatus,
    artworkId,
  ): Record<string, any> => ({
    event: 'view_item',
    ecommerce: {
      currency: 'USD',
      value: convertFromCentsToDollars(price),
      items: [
        {
          item_name: title,
          item_id: productSku,
          item_art_id: artworkId,
          price: convertFromCentsToDollars(price),
          item_brand: artistName,
          item_category: category,
          variant: currentTab,
          quantity: 1,
          affiliation: 'Online Store',
          index: 0,
          original_availability: originalStatus,
          artist_country: artistCountry,
        },
      ],
    },
  })
)
export const getDataLayer = createSelector(
  [
    getDataLayerEcommerce,
    getArtworkId,
    getArtworkCategory,
    getArtworkTitle,
    getArtworkMediums,
    getArtworkSubject,
    getArtworkStyles,
    getArtistId,
    getArtistName,
    getArtistCountry,
    getArtistBadgesList,
    getArtworkOriginalStatus,
    getOriginalProductListPrice,
    getCurrentTab,
    getSelectedProductSku,
  ],
  (
    ecommerce,
    artworkId,
    category,
    title,
    mediums,
    subject,
    styles,
    artistId,
    artistName,
    artistCountry,
    artistBadgesList,
    originalStatus,
    originalPrice,
    currentTab,
    productSku
  ): Record<string, any> => ({
    ...dataDefaults,
    'artist country': artistCountry,
    'artist id': artistId,
    'artist name': artistName,
    'artwork id': artworkId,
    'artwork title': title,
    badges: artistBadgesList,
    category,
    ecommerce,
    medium: mediums,
    'original availability': originalStatus,
    price: originalPrice,
    'product type': currentTab,
    sacs: 0,
    // curationScore
    sku: productSku,
    style: styles,
    subject,
  })
)