import React from 'react'
import SALink from 'components/SALink'
import { Dimensions } from 'components/MeasurementUnit'
import type { ConnectorProps as ProductInfoProps } from '../../connectors/ProductsArtworkInfo'
import ProductInfoConnector from '../../connectors/ProductsArtworkInfo'
import { Text } from '../styles/products'
import { getTitleCategory } from '../../helpers'
import { OPEN_EDITION, LIMITED_EDITION } from '../../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const ArtworkInfo = (props: ProductInfoProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    artistName,
    category,
    country,
    dimensions,
    isAple,
    profileUrl,
    showInfo,
    title,
    unitsProduced,
    currentTab,
    mediums,
    materials,
  } = props
  const subtitled = !!title.match(/(limited|edition)/i)
  const limitedSubtitle =
    isAple && unitsProduced && unitsProduced > 0 && !subtitled
      ? `- Limited Edition of ${unitsProduced}`
      : ''
  return (
    <>
      <div data-type='main-artwork-info-container'>
        {currentTab === OPEN_EDITION || currentTab === LIMITED_EDITION ? (
          <SATypography
            data-type='title-text'
            variant={SATypographyVariant.H4}
            component='h1'
            style={{ margin: 0, fontWeight: 'bold' }}
          >
            {title} Print {limitedSubtitle}
          </SATypography>
        ) : (
          <SATypography
            data-type='title-text'
            variant={SATypographyVariant.H4}
            component='h1'
            style={{ margin: 0, fontWeight: 'bold' }}
          >
            {title} {getTitleCategory(category)} {limitedSubtitle}
          </SATypography>
        )}

        <SALink data-type='profile' {...{ href: profileUrl, title: artistName }}>
          <SATypography variant={SATypographyVariant.MEDIUM} data-type='profile-artist-name'>
            {artistName}
          </SATypography>
        </SALink>

        <SATypography variant={SATypographyVariant.MEDIUM} data-type='country'>
          {country}
        </SATypography>
      </div>

      {showInfo && (
        <>
          <SATypography variant={SATypographyVariant.MEDIUM}>
            {`${category}, ${mediums.length && mediums[0]} on ${materials.length && materials[0]}`}
          </SATypography>

          <SATypography variant={SATypographyVariant.MEDIUM} style={{ margin: '8px 0px' }}>
            Size: <Dimensions dimensions={dimensions} />
          </SATypography>
        </>
      )}
    </>
  )
}

export default ProductInfoConnector(ArtworkInfo)