import React from 'react'
import type { ConnectorProps as GalleryProps } from '../../../connectors/Gallery/Image'
import GalleryImageConnector from '../../../connectors/Gallery/Image'
import Image from './Image'
import PrintImage from './PrintImage'
import ViewInARoom from './ViewInARoom'
import {
  PRINT,
  CANVAS,
  CANVAS_WRAP_COLOR_WHITE,
  CANVAS_WRAP_COLOR_BLACK,
} from '../../../helpers/constants'
type Props = GalleryProps & {
  hasHighFetchPriority: boolean
} & Record<string, any>

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    imageUrl,
    selectedFrameOption,
    selectedCanvasWrapColorOption,
    selectedProduct,
    title,
    type,
    viewInARoomImage,
    hasHighFetchPriority,
    category,
    subject,
    styles,
    artistName,
  } = props

  if (viewInARoomImage) {
    return (
      <div data-type='vir'>
        <ViewInARoom {...props} />
      </div>
    )
  }

  const getCanvasWrapColor = (): CANVAS_WRAP_COLOR_WHITE | CANVAS_WRAP_COLOR_BLACK | null => {
    if (selectedProduct.material !== CANVAS) {
      return null
    }

    return selectedCanvasWrapColorOption?.title || CANVAS_WRAP_COLOR_BLACK
  }

  if (type === PRINT) {
    const { height, width, material } = selectedProduct
    return (
      <div data-type='print'>
        <PrintImage
          {...{
            alt: title,
            frameColor: selectedFrameOption?.title,
            canvasWrapColor: getCanvasWrapColor(),
            height,
            imageUrl,
            type: material,
            width,
            hasHighFetchPriority,
            category,
            subject,
            styles,
            artistName,
          }}
        />
      </div>
    )
  }

  return <Image {...props} />
}

export default GalleryImageConnector(Component)