import React from 'react'
import { MaoButton } from '../../styles/products'
import { CommentIcon } from '../../styles/icons'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  isSticky?: boolean
  onClick: (...args: Array<any>) => any
  sku: string
  updateDataLayer: (...args: Array<any>) => any
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { isSticky, onClick, sku, updateDataLayer } = props

  const goToMao = () => {
    window.location.href = `/offer/${sku}`
  }

  return (
    <MaoButton
      data-button='mao'
      onClick={() => {
        onClick()
        updateDataLayer({
          event: 'PDP MAO',
          prompt: isSticky ? 'sticky bar' : 'inline',
        })
        goToMao()
      }}
      title='Make an offer to artist'
    >
      <CommentIcon />
      <SATypography
        style={{ color: isSticky ? '#333' : '#ffffff', margin: 0 }}
        variant={isSticky ? SATypographyVariant.SMALL : SATypographyVariant.MEDIUM}
      >
        Make an Offer
      </SATypography>
    </MaoButton>
  )
}

export default Component
