import React, { useState, useEffect } from 'react'
import type { ConnectorProps as ArtworkDescriptionProps } from '../../../connectors/ArtworkDescription'
import ArtworkDescriptionConnector from '../../../connectors/ArtworkDescription'
import AccordionComponent from '../AccordionComponent'
import ContactUs from './ContactUs'
import {
  DELIVERY_TIME_COPY,
  RETURN_POLICY_LINK,
  PHOTO_PAPER,
  FINE_ART_PAPER,
  BOX_TEXT,
  ROLLED_IN_TUBE_TEXT,
  LIMITED_EDITION,
} from '../../../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ArtworkDescriptionConnector((props: ArtworkDescriptionProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { currentTab, selectedPrintProduct, selectedFrameOption, screenWidth, artworkID } = props
  const [showContent, setShowContent] = useState(false)
  useEffect(() => {
    setShowContent(false)
  }, [artworkID])
  const { material } = selectedPrintProduct

  if (currentTab !== LIMITED_EDITION) {
    return null
  }

  const hasFrame = selectedFrameOption !== null
  const targetValue = screenWidth > 1064 ? '_blank' : '_self'
  const relValue = screenWidth > 1064 ? 'noopener noreferrer' : ''

  const getPackagingType = () => {
    if (!hasFrame && (material === PHOTO_PAPER || material === FINE_ART_PAPER)) {
      return ROLLED_IN_TUBE_TEXT
    } else return BOX_TEXT
  }

  const getReturnCopy = () => {
    return (
      <>
        Visit our{' '}
        <a
          data-type='support-link'
          target={targetValue}
          rel={relValue}
          href={RETURN_POLICY_LINK}
          title='help section'
        >
          help section
        </a>{' '}
        for more information.
      </>
    )
  }

  const getReturnSection = () => {
    return (
      <div data-type='accordion-sub-section'>
        <div data-type='accordion-sub-header-title'>
          <SATypography
            truncateText
            data-type='accordion-sub-header'
            variant={SATypographyVariant.H6}
          >
            Returns:
          </SATypography>
        </div>
        <div data-type='accordion-sub-header-content'>
          All Limited Edition prints are final sale items and ineligible for returns.
          {!showContent && screenWidth > 500 && screenWidth < 1253 ? null : getReturnCopy()}
        </div>
      </div>
    )
  }

  const getAccordionTitleSection = () => {
    if (!showContent && screenWidth < 500) {
      return null
    } else {
      return material && getReturnSection()
    }
  }

  return (
    <AccordionComponent
      dataType='shipping-returns'
      title='SHIPPING AND RETURNS'
      showContent={showContent}
      setShowContent={setShowContent}
      screenWidth={screenWidth}
      headerChildren={
        <>
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Delivery Time:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>{DELIVERY_TIME_COPY}</SATypography>
            </div>
          </div>
          {getAccordionTitleSection()}
        </>
      }
    >
      <>
        <div data-type='accordion-sub-section'>
          <div data-type='accordion-sub-header-title'>
            <SATypography variant={SATypographyVariant.H6}>Delivery Cost:</SATypography>
          </div>
          <div data-type='accordion-sub-header-content'>
            <SATypography variant={SATypographyVariant.MEDIUM}>Calculated at checkout.</SATypography>
          </div>
        </div>
        {material && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Handling:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {`${getPackagingType()}.  Art prints are packaged and shipped by our printing partner.`}
              </SATypography>
            </div>
          </div>
        )}

        <div data-type='accordion-sub-section'>
          <div data-type='accordion-sub-header-title'>
            <SATypography variant={SATypographyVariant.H6}>Handling: Ships From:</SATypography>
          </div>
          <div data-type='accordion-sub-header-content'>
            <SATypography variant={SATypographyVariant.MEDIUM}>
              Printing facility in California.
            </SATypography>
          </div>
        </div>
        <ContactUs />
      </>
    </AccordionComponent>
  )
})