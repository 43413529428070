import React, { useEffect, useState } from 'react'
import { formatDimensionInCm } from 'components/MeasurementUnit/helper'
import PrintImage from './PrintImage'
import {
  ViewInARoomContainer,
  ViewInARoomPrintContainer,
  ViewInARoomArtwork,
  ViewInARoomBackground,
} from '../../styles/gallery'
type Props = Record<string, any>
const maxWidth = 700
const [small, medium, large, xlarge] = [
  'https://d3t95n9c6zzriw.cloudfront.net/static/img/view_in_a_room_2019_1b.jpg',
  'https://d3t95n9c6zzriw.cloudfront.net/static/img/view_in_a_room_2019_2b.jpg',
  'https://d3t95n9c6zzriw.cloudfront.net/static/img/view_in_a_room_2019_3b.jpg',
  'https://d3t95n9c6zzriw.cloudfront.net/static/img/view_in_a_room_2019_4b.jpg',
]

const ViewInARoom = (props: Props) => {
  const [isClientReady, handleClient] = useState(false)
  useEffect(() => {
    handleClient(true)
  }, [])
  const {
    galleryDimensions,
    imageUrl,
    selectedFrameOption,
    selectedCanvasWrapColorOption,
    selectedProduct: { height, isOriginal, material, width },
    title,
  } = props
  const { width: dimensionWidth, height: dimensionHeight } = galleryDimensions
  const containerWidth = dimensionWidth >= dimensionHeight ? dimensionHeight : dimensionWidth

  if (!containerWidth || !isClientReady) {
    return null
  }

  // print dimensions - include matBorder + frameBorder to calculate width/height for prints
  const smallBorder = 2 // inches for Prints width < 25

  const largeBorder = 3 // inches for Prints width >= 25

  const frameBorder = 0.625 // inches

  const matBorder = width < 18 ? smallBorder : largeBorder
  const printWidthWithMatFrame = parseInt(width, 10) + matBorder * 2 + frameBorder * 2
  const printHeightWithMatFrame = parseInt(height, 10) + matBorder * 2 + frameBorder * 2
  let backgroundImage = small
  let scale = isOriginal ? 1.77 : 1.81
  let type = 'small'

  // convert width/height from centimeters to inches for originals to determine background image
  // don't do any conversion for prints as its already in inches
  if (isOriginal) {
    if (formatDimensionInCm(width) > 72 || formatDimensionInCm(height) > 72) {
      // 72 => 6ft
      backgroundImage = xlarge
      scale = 0.6
      type = 'xlarge'
    } else if (formatDimensionInCm(width) > 42 || formatDimensionInCm(height) > 42) {
      // 42 => 3.5ft
      backgroundImage = large
      scale = 0.81
      type = 'large'
    } else if (formatDimensionInCm(width) > 36 || formatDimensionInCm(height) > 36) {
      // 36 => 3ft
      backgroundImage = medium
      scale = 1.27
      type = 'medium'
    }
  } // determine background image for prints based on total print size
  // ie., print image width/height + matBorder + frameBorder
  // ex: 24 * 36 = (24 + (3 * 2) + 1.25) * (36 + (3 * 2) + 1.25) = 31.25 * 43.25(final width * height in inches)
  else if (printWidthWithMatFrame > 72 || printHeightWithMatFrame > 72) {
    // 72 => 6ft
    backgroundImage = xlarge
    scale = 0.6
    type = 'xlarge'
  } else if (printWidthWithMatFrame > 41 || printHeightWithMatFrame > 41) {
    // 72 => 6ft
    backgroundImage = large
    scale = 0.84
    type = 'large'
  } else if (printWidthWithMatFrame > 36 || printHeightWithMatFrame > 36) {
    // 48 => 4ft
    backgroundImage = medium
    scale = 1.27
    type = 'medium'
  }

  //     room size width                    artwork pixel width
  // -----------------------------   =  -----------------------------
  //  actual space width (inches)        actual artwork width (inches)
  // const spaceWidth = 68 // inches
  // const actualFrameWidth = 0.75 // inches
  const roomWidth = 8 * 12 // Feet X Inches

  const artworkWidth =
    (((containerWidth < maxWidth ? containerWidth : maxWidth) *
      Number(isOriginal ? formatDimensionInCm(width) : width)) /
      roomWidth) *
    scale
  // Account Frame for Prints
  const canvasWrapColor = selectedCanvasWrapColorOption?.title
  const frameColor = selectedFrameOption?.title
  const frameSize = selectedFrameOption ? 1.25 : 0
  // include matBorder + frameBorder to width/height for scaling prints
  const printDimensions = {
    width: `${Number(
      containerWidth * Number((Number(width) + matBorder * 2 + frameSize) / roomWidth) * scale
    ).toFixed(0)}px`,
    height: `${Number(
      containerWidth * Number((Number(height) + matBorder * 2 + frameSize) / roomWidth) * scale
    ).toFixed(0)}px`,
  }
  return (
    <ViewInARoomContainer>
      {!isOriginal ? (
        <ViewInARoomPrintContainer style={printDimensions} type={type}>
          <PrintImage
            {...{
              imageUrl,
              width: Number(width),
              height: Number(height),
              canvasWrapColor,
              frameColor,
              type: material,
              alt: title,
              isVirImage: true,
              overrides: {
                width: Number(printDimensions.width.replace(/px/, '')),
                height: Number(printDimensions.height.replace(/px/, '')),
              },
            }}
          />
        </ViewInARoomPrintContainer>
      ) : (
        <ViewInARoomArtwork
          src={imageUrl}
          widthValue={`${parseInt(artworkWidth, 10)}px`}
          type={type}
          alt='View in a Room Artwork'
        />
      )}

      <ViewInARoomBackground src={backgroundImage} alt='View in a Room Background' />
    </ViewInARoomContainer>
  )
}

export default ViewInARoom