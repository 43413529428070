import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import ViewInRoomAR from 'svg/vir-2-0-button-icon-dark.svg'
import ViewInRoomARTextOnly from 'svg/vir-2-0-button-icon.svg'
export const ARIcon = styled(ViewInRoomAR)`
  width: 60px;
  height: 60px;
  padding: 16px;
  background: #333;
  cursor: pointer;
  &[data-type='desktop'] {
    margin-top: 8px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &[data-type='mobile'] {
    position: absolute;
    z-index: 3;
    bottom: 60px;
    right: 5px;
    width: 50px;
    height: 50px;
    @media (min-width: 1024px) {
      display: none;
    }
  }
`
export const TextOnlyARIcon = styled(ViewInRoomARTextOnly)`
  width: 50px;
  height: 50px;
  cursor: pointer;
  &[data-type='desktop'] {
    margin-top: 8px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &[data-type='mobile'] {
    position: absolute;
    z-index: 3;
    bottom: 60px;
    right: 5px;
    width: 50px;
    height: 50px;
    @media (min-width: 1024px) {
      display: none;
    }
  }
`
export const ARIconABTest = styled(ViewInRoomAR)`
  width: 50px;
  height: 50px;
  cursor: pointer;
  &[data-type='desktop'] {
    margin-top: 8px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &[data-type='mobile'] {
    position: absolute;
    z-index: 3;
    bottom: -375px;
    right: 5px;
    width: auto;
    @media (min-width: 1024px) {
      display: none;
    }
  }
`