import React, { useState, useEffect } from 'react'
import type { ConnectorProps as ArtworkDescriptionProps } from '../../../connectors/ArtworkDescription'
import ArtworkDescriptionConnector from '../../../connectors/ArtworkDescription'
import AccordionComponent from '../AccordionComponent'
import {
  PHOTO_PAPER,
  FINE_ART_PAPER,
  CANVAS,
  BOX,
  ROLLED_IN_TUBE,
  NOT_FRAMED,
  NOT_APPLICABLE,
  LIMITED_EDITION,
  PRINT_DEPTH,
} from '../../../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ArtworkDescriptionConnector((props: ArtworkDescriptionProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const {
    currentTab,
    selectedPrintProduct,
    selectedFrameOption,
    selectedCanvasWrapColorOption,
    screenWidth,
    artworkID,
  } = props
  const [showContent, setShowContent] = useState(false)
  useEffect(() => {
    setShowContent(false)
  }, [artworkID])
  const { material, height, width, depth, isLimitedEdition, unitsProduced } = selectedPrintProduct

  if (currentTab !== LIMITED_EDITION) {
    return null
  }

  const hasFrame = selectedFrameOption !== null
  const smallBorder = 4 // 2" mat border on both sides

  const largeBorder = 6 // 3" mat border on both sides

  const frameBorder = 1.25 // 0.625" frame including both sides

  const getFramedPrintDimensions = () => {
    if (width < 18) {
      return `${parseInt(width, 10) + smallBorder + frameBorder} W x ${
        parseInt(height, 10) + smallBorder + frameBorder
      } H x ${PRINT_DEPTH} D in`
    } else {
      return `${parseInt(width, 10) + largeBorder + frameBorder} W x ${
        parseInt(height, 10) + largeBorder + frameBorder
      } H x ${PRINT_DEPTH} D in`
    }
  }

  const getPackagingType = () => {
    if (!hasFrame && (material === PHOTO_PAPER || material === FINE_ART_PAPER)) {
      return ROLLED_IN_TUBE
    } else return BOX
  }

  const getFrameValue = () => {
    if (material === CANVAS) {
      return NOT_APPLICABLE
    } else if (!hasFrame) {
      return NOT_FRAMED
    } else {
      return selectedFrameOption.title
    }
  }

  return (
    <AccordionComponent
      dataType='product-details'
      title='DETAILS AND DIMENSIONS'
      showContent={showContent}
      setShowContent={setShowContent}
      screenWidth={screenWidth}
      headerChildren={
        <>
          {material && (
            <div data-type='accordion-sub-section'>
              <div data-type='accordion-sub-header-title'>
                <SATypography variant={SATypographyVariant.H6}>Print:</SATypography>
              </div>
              <div data-type='accordion-sub-header-content'>
                <SATypography variant={SATypographyVariant.MEDIUM}>
                  {`Giclee on ${material}`}
                </SATypography>
              </div>
            </div>
          )}
          {isLimitedEdition && unitsProduced && (
            <div data-type='accordion-sub-section'>
              <div data-type='accordion-sub-header-title'>
                <SATypography variant={SATypographyVariant.H6}>
                  Saatchi Art Limited Edition of:
                </SATypography>
              </div>
              <div data-type='accordion-sub-header-content'>
                <SATypography variant={SATypographyVariant.MEDIUM}>{unitsProduced}</SATypography>
              </div>
            </div>
          )}
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Size:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography
                variant={SATypographyVariant.MEDIUM}
              >{`${width} W x ${height} H x ${depth} D in`}</SATypography>
            </div>
          </div>
        </>
      }
    >
      <>
        {isLimitedEdition && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>
                Certificate of Authenticity:
              </SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                Yes, Signed and Numbered
              </SATypography>
            </div>
          </div>
        )}
        <div data-type='accordion-sub-section'>
          <div data-type='accordion-sub-header-title'>
            <SATypography variant={SATypographyVariant.H6}>Frame:</SATypography>
          </div>
          <div data-type='accordion-sub-header-content'>
            <SATypography variant={SATypographyVariant.MEDIUM}>{getFrameValue()}</SATypography>
          </div>
        </div>
        {material && material !== CANVAS && hasFrame && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Size with Frame:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {getFramedPrintDimensions()}
              </SATypography>
            </div>
          </div>
        )}
        {material === CANVAS && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Canvas Wrap:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {selectedCanvasWrapColorOption.title}
              </SATypography>
            </div>
          </div>
        )}
        {hasFrame && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Ready to Hang:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>Yes</SATypography>
            </div>
          </div>
        )}
        <div data-type='accordion-sub-section'>
          <div data-type='accordion-sub-header-title'>
            <SATypography variant={SATypographyVariant.H6}>Packaging:</SATypography>
          </div>
          <div data-type='accordion-sub-header-content'>
            <SATypography variant={SATypographyVariant.MEDIUM}>{getPackagingType()}</SATypography>
          </div>
        </div>
      </>
    </AccordionComponent>
  )
})