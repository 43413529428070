import React, { useEffect, useRef, useState } from 'react'
// import Script from 'next/script'
import useScript from 'hooks/loadScript'
import type { ConnectorProps as SessionProps } from 'containers/Session'
import SessionConnect from 'containers/Session'
import { affirmConfig, MAX_PRICE, MIN_PRICE, script } from './helpers'
import { Affirm } from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = SessionProps & {
  price: number
}

/*
  Idea - We want to load Affirm and open the POPUP only after a USER clicks
  1) On click check that Affirm is available
  2) load script
  3) UseRef to fire click event
*/
const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { isSessionReady, localizationCountry, price } = props
  const [affirmScriptTagRequired, handleAffirmScriptTagRequired] = useState(false) // Inform the component to load Affirm Script

  const affirmElement: Record<string, any> = useRef()
  // Affirm Scripts
  useScript({
    name: 'affirm-config',
    text: !affirmScriptTagRequired ? null : affirmConfig,
  })
  const scriptStatus = useScript({
    async: true,
    name: 'affirm-script',
    src: !affirmScriptTagRequired ? null : script,
  })
  // Handle Affirm loading for the first time
  // After first load Affirm will handle the rest
  useEffect(() => {
    if (scriptStatus === 'ready') {
      if (affirmElement.current) {
        affirmElement.current.click()
        // Affirm doesn't always show up on initial click
        // Lets set a TIMER 1.5 seconds and check if its open
        setTimeout(() => {
          if (window.affirm && window.affirm.ui.modal.hidden) {
            affirmElement.current.click()
          }
        }, 1500)
      } else {
        console.log('affirm not ready')
      }

      handleAffirmScriptTagRequired(false)
    }
  }, [scriptStatus])
  const isMinMaxValid = Number(price) >= MIN_PRICE && Number(price) <= MAX_PRICE

  if (!isSessionReady || localizationCountry !== 'US' || !isMinMaxValid) {
    return null
  }

  const handleAffirmClick = () => {
    if (window.affirm) {
      // We have this LOGIC as backup for switching/clientside transitions of PDP's
      // Refresh price and manually fire click
      // If we remove `click`, user has to click twice
      window.affirm.ui.refresh()
      affirmElement.current && affirmElement.current.click()
    } else {
      handleAffirmScriptTagRequired(true)
    }
  }

  return (
    <Affirm
      className='affirm-product-modal'
      data-amount={price * 100} // Affirm want cents
      onClick={handleAffirmClick}
      ref={affirmElement}
      styles={{
        background: '#333333',
        padding: '20px',
      }}
    >
      <SATypography style={{ color: '#ffffff' }} variant={SATypographyVariant.X_SMALL}>
        Buy Now, Pay Later
      </SATypography>
      <div data-type='affirm-logo'>
        <SATypography
          style={{ color: '#ffffff', marginTop: '4px' }}
          variant={SATypographyVariant.X_SMALL}
        >
          with
        </SATypography>
        <img
          width='40'
          height='16'
          src='https://d3t95n9c6zzriw.cloudfront.net/art-detail/affirm_white_logo-solid_crop.svg'
          alt='Affirm Logo'
        />
      </div>
    </Affirm>
  )
}

export default SessionConnect(Component)