import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getScreenWidth } from 'selectors/screen'
import { getMeasurementUnit } from 'selectors/user/session'
import {
  getArtworkCategory,
  getArtworkDescription,
  getArtworkKeywords,
  getArtworkMaterials,
  getArtworkMediums,
  getArtworkStyles,
  getArtworkSubject,
  getArtworkYearProduced,
  getArtworkDimensions,
  getArtworkMultiPanel,
  getArtworkId,
  getArtworkHasLimitedEditions,
  getArtworkTitle,
  getArtworkPanels,
} from '../selectors/artwork'
import type { SelectedCanvasWrapColorOption, SelectedFrameOption } from '../selectors/product'
import {
  getCurrentTab,
  getOriginalProductPackagingOption,
  getOriginalProductReadyToHangStatus,
  getOriginalProductFrameStatus,
  getOriginalProductFrameColor,
  getSelectedCanvasWrapColorOption,
  getSelectedFrameOption,
  getSelectedProduct,
  getCurrentProductType,
  getOriginalProductIsAple,
  getOriginalProductUnitsProduced,
  getOriginalProductListPrice,
  getOriginalProductShipsFromCountry,
} from '../selectors/product'
import { getArtistCountry, getArtistName } from '../selectors/artist'
const mapStateToProps = createStructuredSelector({
  artistCountry: getArtistCountry,
  artistName: getArtistName,
  artworkTitle: getArtworkTitle,
  artworkID: getArtworkId,
  category: getArtworkCategory,
  currentTab: getCurrentTab,
  description: getArtworkDescription,
  dimensions: getArtworkDimensions,
  frameColor: getOriginalProductFrameColor,
  frameStatus: getOriginalProductFrameStatus,
  isAple: getOriginalProductIsAple,
  isLimitedEdition: getArtworkHasLimitedEditions,
  isMultiPanel: getArtworkMultiPanel,
  readyToHangStatus: getOriginalProductReadyToHangStatus,
  keywords: getArtworkKeywords,
  materials: getArtworkMaterials,
  mediums: getArtworkMediums,
  originalProductPrice: getOriginalProductListPrice,
  packingOption: getOriginalProductPackagingOption,
  panels: getArtworkPanels,
  screenWidth: getScreenWidth,
  selectedPrintProduct: getSelectedProduct,
  selectedFrameOption: getSelectedFrameOption,
  selectedCanvasWrapColorOption: getSelectedCanvasWrapColorOption,
  shipsFromCountry: getOriginalProductShipsFromCountry,
  styles: getArtworkStyles,
  subject: getArtworkSubject,
  unitsProduced: getOriginalProductUnitsProduced,
  userSizePreference: getMeasurementUnit,
  yearProduced: getArtworkYearProduced,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  artistCountry: string
  artistName: string
  artworkID: number
  artworkTitle: string
  category: string
  currentTab: string
  description: string
  dimensions: Record<string, any>
  frameColor: string
  frameStatus: string
  isAple: boolean
  isLimitedEdition: boolean
  isMultiPanel: boolean
  readyToHangStatus: string
  keywords: Array<string>
  materials: Array<string>
  mediums: Array<string>
  originalProductPrice: number
  packingOption: string
  panels: number
  productType: getCurrentProductType
  screenWidth: number
  selectedPrintProduct: Record<string, any>
  selectedFrameOption: SelectedFrameOption
  selectedCanvasWrapColorOption: SelectedCanvasWrapColorOption
  shipsFromCountry: string
  styles: Array<string>
  subject: string
  unitsProduced: number
  userSizePreference: 'cm' | 'in'
  yearProduced: number
}
export default connect(mapStateToProps, mapDispatchToProps)