import fetch from 'isomorphic-fetch'
import type { PromisePayload } from 'apis/helpers'
import { deleteOptions, getOptions, postOptions, putOptions, parseJSON } from 'apis/helpers'
export const updateCurationArtworkStatus = async ({
  artworkId,
  direction,
}: Record<string, any>): Promise<PromisePayload> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      artworkId,
      direction,
    }),
  }
  const response = await fetch(`/easel_api/curation/artwork/status`, options)
  const json = await parseJSON(response, 'updateCurationArtworkStatus')
  return json
}
export const deleteCurationArtworkStatus = async ({
  artworkId,
  direction,
}: Record<string, any>): Promise<PromisePayload> => {
  const options = {
    ...deleteOptions,
    body: JSON.stringify({
      artworkId,
      direction,
    }),
  }
  const response = await fetch(`/easel_api/curation/artwork/status`, options)
  const json = await parseJSON(response, 'deleteCurationArtworkStatus')
  return json
}
export const updateCurationArtworkIsSafe = async ({
  artworkId,
  isSafe,
}: Record<string, any>): Promise<PromisePayload> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      artworkId,
      isSafe,
    }),
  }
  const response = await fetch(`/easel_api/curation/artwork/isSafe`, options)
  const json = await parseJSON(response, 'updateCurationArtworkIsSafe')
  return json
}
export const fetchCurators = async (): Promise<PromisePayload> => {
  const options = getOptions
  const response = await fetch(`/easel_api/curators`, options)
  const json = await parseJSON(response, 'fetchCurators')
  return json
}
export const fetchCurationArtistEmailForms = async ({
  artworkId,
  curatorId,
}: Record<string, any>): Promise<PromisePayload> => {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      artworkId,
      curatorId,
    }),
  }
  const response = await fetch(`/easel_api/curation/artist/emailForms`, options)
  const json = await parseJSON(response, 'fetchCurationArtistEmailForms')
  return json
}
export const postCurationArtistEmailSubmit = async ({
  email,
  message,
  subject,
}: Record<string, any>): Promise<PromisePayload> => {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      email,
      message,
      subject,
    }),
  }
  const response = await fetch(`/easel_api/curation/artist/emailSubmit`, options)
  const json = await parseJSON(response, 'postCurationArtistEmailSubmit')
  return json
}
export const fetchCurationBookmark = async (): Promise<PromisePayload> => {
  const options = getOptions
  const response = await fetch(`/easel_api/curation/artwork/bookmark`, options)
  const json = await parseJSON(response, 'fetchCurationBookmark')
  return json
}
export const postCurationBookmark = async ({
  artworkId,
}: Record<string, any>): Promise<PromisePayload> => {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      artworkId,
    }),
  }
  const response = await fetch(`/easel_api/curation/artwork/bookmark`, options)
  const json = await parseJSON(response, 'postCurationBookmark')
  return json
}
export const fetchArtworkVotes = async (artworkId: string): Promise<Record<string, any>> => {
  const response = await fetch(`/easel_api/ops/curation/artwork/${artworkId}/votes`, getOptions)
  const json = await parseJSON(response, 'fetchArtworkVotes')
  return json
}