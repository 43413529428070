import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getABTestValues } from 'selectors/user/abTest'
import { getIsClientReady } from 'selectors/shared/client'
import { Actions } from '../actions'
import { getARExperienceAvailable } from '../selectors/arExperience'
const mapStateToProps = createStructuredSelector({
  isARExperienceAvailable: getARExperienceAvailable,
  abTest: getABTestValues,
  isClientReady: getIsClientReady,
})

const mapDispatchToProps = (dispatch) => ({
  openARExperience: () => dispatch(Actions.OPEN_AR_EXPERIENCE()),
})

export type ConnectorProps = {
  isARExperienceAvailable: boolean
  openARExperience: (...args: Array<any>) => any
  abTest: Record<string, any>
  isClientReady: boolean
}
export default connect(mapStateToProps, mapDispatchToProps)