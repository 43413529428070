import React from 'react'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'
import type { ConnectorProps as LayoutProps } from '../connectors/Layout'
import LayoutConnector from '../connectors/Layout'

const AdminOptions = ({
  edit,
  admin,
}: Record<string, any>): React.ReactElement<React.ComponentProps<any>, any> => {
  const ManageArtwork = dynamic(import('../components/ManageArtwork'), noSSR)
  const AdminPanel = dynamic(import('../components/AdminPanel'), noSSR)
  return (
    <>
      {edit && (
        <>
          <ManageArtwork />
        </>
      )}
      {admin && <AdminPanel />}
    </>
  )
}

const Admin = (props: LayoutProps): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { canEditArtwork, isAdmin } = props

  if (!canEditArtwork) {
    return null
  }

  return <AdminOptions edit={canEditArtwork} admin={isAdmin} />
}

export default LayoutConnector(Admin)