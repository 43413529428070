import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as DataLayerActions } from 'actions/shared/dataLayer'
import { getIsAdmin } from 'selectors/user/session'
import { getArtworkId } from '../../selectors/artwork'
import type { SelectedFrameOption } from '../../selectors/product'
import { getCurrentTab, getSelectedProduct, getSelectedFrameOption } from '../../selectors/product'
import {
  getGalleryDimensions,
  getGalleryImages,
  getGalleryModalDimensions,
  isValidScreenOrientation,
} from '../../selectors/gallery'
const mapStateToProps = createStructuredSelector({
  artworkId: getArtworkId,
  currentTab: getCurrentTab,
  galleryDimensions: getGalleryDimensions,
  galleryImages: getGalleryImages,
  galleryModalDimensions: getGalleryModalDimensions,
  isAdmin: getIsAdmin,
  isValidScreenOrientation,
  selectedProduct: getSelectedProduct,
  selectedFrameOption: getSelectedFrameOption,
})

const mapDispatchToProps = (dispatch) => ({
  updateDataLayer: (payload: Record<string, any>) =>
    dispatch(DataLayerActions.UPDATE_DATALAYER(payload)),
})

export type ConnectorProps = {
  artworkId: number
  currentTab: string
  galleryDimensions: Record<string, any>
  galleryImages: Array<Record<string, any>>
  galleryModalDimensions: Record<string, any>
  isAdmin: boolean
  isValidScreenOrientation: string
  updateDataLayer: (...args: Array<any>) => any
  selectedProduct: Record<string, any>
  selectedFrameOption: SelectedFrameOption
}
export default connect(mapStateToProps, mapDispatchToProps)