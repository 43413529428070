import React, { useState, useEffect } from 'react'
import { Dimensions } from 'components/MeasurementUnit'
import type { ConnectorProps as ArtworkDescriptionProps } from '../../../connectors/ArtworkDescription'
import ArtworkDescriptionConnector from '../../../connectors/ArtworkDescription'
import {
  ORIGINAL,
  ROLLED,
  FLAT_CRATE,
  ROLLED_IN_TUBE,
  BOX,
  CRATE,
} from '../../../helpers/constants'
import { getPanelTitle } from '../../../helpers/product'
import AccordionComponent from '../AccordionComponent'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ArtworkDescriptionConnector((props: ArtworkDescriptionProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const {
    category,
    mediums,
    materials,
    currentTab,
    dimensions,
    packingOption,
    readyToHangStatus,
    frameStatus,
    isAple,
    unitsProduced,
    screenWidth,
    isMultiPanel,
    artworkID,
    panels,
  } = props
  const [showContent, setShowContent] = useState(false)
  const isNotApleCategory =
    category !== 'Photography' || category !== 'Printmaking' || category !== 'Mixed Media'
  const panelTitle = getPanelTitle(category)
  const shouldShowPanels = category !== 'Video' && panels > 1
  useEffect(() => {
    setShowContent(false)
  }, [artworkID])

  const getPackagingType = () => {
    if (packingOption === ROLLED) {
      return ROLLED_IN_TUBE
    } else if (packingOption === FLAT_CRATE) {
      return CRATE
    } else return BOX
  }

  if (currentTab !== ORIGINAL) {
    return null
  }

  return (
    <AccordionComponent
      dataType='product-details'
      title='DETAILS AND DIMENSIONS'
      showContent={showContent}
      setShowContent={setShowContent}
      screenWidth={screenWidth}
      headerChildren={
        <>
          {category && (
            <div data-type='accordion-sub-section'>
              <div data-type='accordion-sub-header-title'>
                <SATypography variant={SATypographyVariant.H6}>
                  {isMultiPanel ? `Multi-paneled ${category}:` : `${category}:`}
                </SATypography>
              </div>
              <div data-type='accordion-sub-header-content'>
                <SATypography variant={SATypographyVariant.MEDIUM}>
                  {`${mediums.length && mediums[0]} on ${materials.length && materials[0]}`}
                </SATypography>
              </div>
            </div>
          )}
          <div data-type='accordion-sub-section'>
            {isNotApleCategory && !isAple ? (
              <>
                <div data-type='accordion-sub-header-title'>
                  <SATypography variant={SATypographyVariant.H6}>Original:</SATypography>
                </div>
                <div data-type='accordion-sub-header-content'>
                  <SATypography variant={SATypographyVariant.MEDIUM}>
                    One-of-a-kind Artwork
                  </SATypography>
                </div>
              </>
            ) : (
              <>
                <div data-type='accordion-sub-header-title'>
                  <SATypography variant={SATypographyVariant.H6}>
                    Artist Produced Limited Edition of:
                  </SATypography>
                </div>
                <div data-type='accordion-sub-header-content'>
                  <SATypography variant={SATypographyVariant.MEDIUM}>{unitsProduced}</SATypography>
                </div>
              </>
            )}
          </div>
          {dimensions && (
            <div data-type='accordion-sub-section'>
              <>
                <div data-type='accordion-sub-header-title'>
                  <SATypography variant={SATypographyVariant.H6}>Size:</SATypography>
                </div>
                <div data-type='accordion-sub-header-content'>
                  <SATypography variant={SATypographyVariant.MEDIUM}>
                    <Dimensions dimensions={dimensions} />
                  </SATypography>
                </div>
              </>
            </div>
          )}
          {shouldShowPanels && (
            <div data-type='accordion-sub-section'>
              <>
                <div data-type='accordion-sub-header-title'>
                  <SATypography variant={SATypographyVariant.H6}>{`${panelTitle}:`}</SATypography>
                </div>
                <div data-type='accordion-sub-header-content'>
                  <SATypography variant={SATypographyVariant.MEDIUM}>{panels}</SATypography>
                </div>
              </>
            </div>
          )}
        </>
      }
    >
      <>
        <div data-type='accordion-sub-section'>
          <>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Frame:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>{frameStatus}</SATypography>
            </div>
          </>
        </div>
        <div data-type='accordion-sub-section'>
          <>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Ready to Hang:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>{readyToHangStatus}</SATypography>
            </div>
          </>
        </div>
        {packingOption && (
          <div data-type='accordion-sub-section'>
            <>
              <div data-type='accordion-sub-header-title'>
                <SATypography variant={SATypographyVariant.H6}>Packaging:</SATypography>
              </div>
              <div data-type='accordion-sub-header-content'>
                <SATypography variant={SATypographyVariant.MEDIUM}>
                  {getPackagingType()}
                </SATypography>
              </div>
            </>
          </div>
        )}
      </>
    </AccordionComponent>
  )
})