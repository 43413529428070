import { createSelector } from 'reselect'
import { formatDimensionInCm } from 'components/MeasurementUnit/helper'
import { getPageParams } from 'selectors/page'
import { getStoreLocale } from 'selectors/geoLocation'
import { formatPrice, joinArray, getLinkCategory, getProductSchemaCategory } from '../helpers'
import {
  getArtistBadges,
  getArtistCountry,
  getArtistName,
  getArtistProfileUrl,
  getArtistUserId,
} from './artist'
import {
  getArtwork,
  getArtworkCategory,
  getArtworkImageUrl,
  getArtworkMaterials,
  getArtworkMediums,
  getArtworkTitle,
  getArtworkTitleSafe,
} from './artwork'
import { getCurationStatus } from './general'
import {
  getCurrentTab,
  getPrintMaterials,
  getProductCategory,
  getSelectedProductPrice,
  getSelectedProduct,
  getSelectedProductAvailability,
  getSelectedProductStatus,
  getCurrentProductType,
} from './product'
const { SITE_URL } = process.env
export const getPath = createSelector(
  [getStoreLocale, getPageParams],
  (storeLocale, { artistId, artworkId, productType, title }) => {
    return `/${storeLocale}/${productType}/${title}/${artistId}/${artworkId}/view`
  }
)
export const getLocation = createSelector(
  [getPath],
  (path) => `${SITE_URL || 'saatchiart.com/'}${path}`
)
export const getMetaOriginalDetails = createSelector(
  [getArtworkCategory, getSelectedProduct, getArtworkMaterials, getArtworkMediums],
  (category, selectedProduct, materials, mediums): string => {
    const { width, depth, height } = selectedProduct
    return `Original ${category}: ${joinArray(mediums) || 'N/A'} on ${
      joinArray(materials) || 'N/A'
    }. Size is ${formatDimensionInCm(height)} H x ${formatDimensionInCm(
      width
    )} W x ${formatDimensionInCm(depth)} in.`
  }
)
export const getMetaPrintDetails = createSelector(
  [getSelectedProduct, getPrintMaterials],
  (selectedProduct, printMaterials): string => {
    const { width, height } = selectedProduct
    return `Archival inks on ${
      joinArray(printMaterials) || 'N/A'
    }. Size is ${height} H x ${width} W in.`
  }
)
export const getMetaCurrentTabDetails = createSelector(
  [getCurrentTab, getMetaOriginalDetails, getMetaPrintDetails],
  (currentTab, originalDetails, printDetails): string =>
    currentTab === 'original' || currentTab === 'info' ? originalDetails : printDetails
)
export const getMetaPriceInfo = createSelector(
  [getSelectedProductStatus, getSelectedProductPrice, getCurrentTab],
  (status, price, currentTab): string => {
    let metaPriceInfo

    switch (status) {
      case 'editions':
        metaPriceInfo = `.${
          currentTab === 'limited' ? ' Limited Edition' : ''
        } Art prints are available from $${Number((price || 0) / 100).toLocaleString()} USD.`
        break

      case 'notForSale':
        metaPriceInfo = '.'
        break

      case 'soldOut':
      case 'reserved':
        metaPriceInfo = `, sold and originally listed for $${formatPrice(price, true, true)} USD.`
        break

      case 'forSale':
      default:
        metaPriceInfo = `, available for purchase at $${formatPrice(price, true, true)} USD.`
        break
    }

    return metaPriceInfo
  }
)
export const getMetaTitle = createSelector(
  [getArtworkTitleSafe, getArtworkCategory, getArtistName],
  (artworkTitleSafe, category, artistName) =>
    `${artworkTitleSafe} ${category} by ${artistName} | Saatchi Art`
)
export const getMetaDescription = createSelector(
  [
    getCurrentTab,
    getArtworkTitleSafe,
    getArtworkCategory,
    getArtistName,
    getMetaPriceInfo,
    getMetaCurrentTabDetails,
    getProductCategory,
  ],
  (
    currentTab,
    artworkTitleSafe,
    category,
    artistName,
    priceInfo,
    tabDetails,
    productCategory
  ): string => {
    let value = ''

    if (currentTab === 'original' || currentTab === 'info') {
      value = productCategory.toLowerCase()
    } else if (currentTab === 'limited') {
      value = 'Limited Edition Art Print'
    } else {
      value = 'Art Print'
    }

    return `Saatchi Art is pleased to offer the ${value}, "${artworkTitleSafe}," by ${artistName}${priceInfo} ${tabDetails}`
  }
)
export const getMetaKeywords = createSelector(
  [getArtwork, getArtistName, getArtistCountry],
  (artwork, artistName, country) => {
    const { category, keywords, materials, mediums, styles, subject } = artwork
    return `${joinArray(
      keywords,
      ', '
    )} ${category}, ${country}, ${artistName}, ${subject}, ${joinArray(styles, ', ')}${joinArray(
      mediums,
      ', '
    )}${joinArray(
      materials,
      ', '
    )}Prints, Fine Art, Sale, Original Art, Artwork, Saatchi Art, Art, Gallery`.replace(/["]/g, '')
  }
)
export const getOgTitle = createSelector(
  [getArtworkTitle, getCurrentTab, getProductCategory],
  (title, currentTab, productCategory): string => {
    let value = ''

    if (currentTab === 'original' || currentTab === 'info') {
      value = productCategory
    } else if (currentTab === 'limited') {
      value = 'Limited Edition Art Print'
    } else {
      value = 'Art Print'
    }

    return `${title} ${value}`
  }
)
export const getOgStatus = createSelector([getSelectedProductStatus], (status): string =>
  status.search(/forSale|editions/) > -1 ? 'instock' : 'out of stock'
)
export const getInvalidSeo = createSelector(
  [getCurationStatus, getArtistBadges, getCurrentTab],
  (curationStatus, badges, currentTab): boolean =>
    curationStatus < 1 && (!badges || !badges.length) && currentTab === 'info'
)
export const getMetadata = createSelector(
  [
    getArtworkImageUrl,
    getArtworkCategory,
    getArtworkTitle,
    getCurrentTab,
    getSelectedProductPrice,
    getSelectedProductStatus,
  ],
  (artworkImage, category, title, currentTab, price, status): Record<string, any> => ({
    artworkImage,
    category,
    title,
    currentTab,
    price,
    status,
  })
)
export const getBreadcrumbSchema = createSelector(
  [getArtistName, getArtistProfileUrl, getArtistUserId, getArtworkCategory, getCurrentTab],
  (artistName, artistProfileUrl, artistUserId, category, currentTab) => {
    const productCategory =
      currentTab !== 'original' && currentTab !== 'info' ? 'Prints' : getLinkCategory(category)
    return {
      '@context': 'http://schema.org',
      type: 'BreadcrumbList',
      itemListElement: [
        {
          type: 'ListItem',
          position: 1,
          item: {
            '@id': 'https://www.saatchiart.com/all',
            name: 'All Artworks',
          },
        },
        {
          type: 'ListItem',
          position: 2,
          item: {
            '@id': `https://www.saatchiart.com/${productCategory.toLowerCase()}`,
            name: productCategory,
          },
        },
        {
          type: 'ListItem',
          position: 3,
          item: {
            '@id': `https://www.saatchiart.com/account/artworks/${artistUserId}`,
            name: `${artistName} Works`,
          },
        },
      ],
    }
  }
)
export const getProductSchema = createSelector(
  [
    getArtistName,
    getArtistProfileUrl,
    getArtistUserId,
    getArtworkCategory,
    getArtworkImageUrl,
    getArtworkTitleSafe,
    getCurrentTab,
    getLocation,
    getMetaDescription,
    getProductCategory,
    getSelectedProduct,
    getSelectedProductAvailability,
    getSelectedProductPrice,
    getCurrentProductType,
    getStoreLocale,
  ],
  (
    artistName,
    artistProfileUrl,
    artistUserId,
    category,
    artworkImage,
    artworkTitleSafe,
    currentTab,
    location,
    metaDescription,
    productCategory,
    selectedProduct,
    availability,
    price,
    productType,
    storeLocale
  ) => {
    const { width, height, sku } = selectedProduct

    let shippingDetails

    if (currentTab === 'original') {
      const [, countryCode = 'us'] = storeLocale.split('-')
      shippingDetails = {
        '@type': 'OfferShippingDetails',
        shippingRate: {
          '@type': 'MonetaryAmount',
          value: 0,
          currency: 'USD',
        },
        shippingDestination: {
          '@type': 'DefinedRegion',
          addressCountry: countryCode.toUpperCase(),
        },
        deliveryTime: {
          '@type': 'ShippingDeliveryTime',
          handlingTime: {
            '@type': 'QuantitativeValue',
            minValue: 1,
            maxValue: 2,
            unitCode: 'DAY',
          },
          transitTime: {
            '@type': 'QuantitativeValue',
            minValue: 2,
            maxValue: 5,
            unitCode: 'DAY',
          },
        },
      }
    }

    return {
      '@context': 'http://schema.org/',
      type: 'Product',
      name: artworkTitleSafe,
      sku,
      mpn: sku,
      image: artworkImage,
      description: metaDescription,
      category: getProductSchemaCategory(category, productType),
      width: [
        {
          type: 'Distance',
          name: `${currentTab === 'print' ? width : formatDimensionInCm(width)} inches`,
        },
      ],
      height: [
        {
          type: 'Distance',
          name: `${currentTab === 'print' ? height : formatDimensionInCm(height)} inches`,
        },
      ],
      brand: {
        type: 'Brand',
        name: 'Saatchi Art',
      },
      offers: {
        type: 'Offer',
        priceCurrency: 'USD',
        price: formatPrice(price, false, availability === 'InStock'),
        availability: `http://schema.org/${availability}`,
        acceptedPaymentMethod: [
          'http://purl.org/goodrelations/v1#PayPal',
          'http://purl.org/goodrelations/v1#PaymentMethodCreditCard',
        ],
        itemoffered: currentTab === 'original' ? category : 'Product',
        name: artworkTitleSafe,
        sameas: `http://www.productontology.org/id/${
          currentTab === 'original' && productCategory !== 'VisualArtwork'
            ? productCategory
            : 'Work_of_art'
        }`,
        url: location,
        seller: {
          type: 'Organization',
          name: 'Saatchi Art',
        },
        shippingDetails,
      },
    }
  }
)
export const getMetaData = createSelector(
  [
    getBreadcrumbSchema,
    getProductSchema,
    getMetaTitle,
    getMetaDescription,
    getOgStatus,
    getArtworkImageUrl,
    getSelectedProductPrice,
    getInvalidSeo,
  ],
  (
    breadcrumb,
    product,
    title,
    description,
    status,
    artworkImageUrl,
    price,
    invalidSeo
  ): Record<string, any> => {
    return {
      description,
      title,
      og: {
        availability: status,
        description,
        image: artworkImageUrl,
        title,
        ttl: '2419200',
        type: 'product',
      },
      product: {
        price: {
          currency: 'USD',
          amount: status === 'out of stock' ? '0.00' : Number(price / 100),
        },
      },
      preloadImages: [artworkImageUrl],
      robots: !invalidSeo,
      schema: {
        breadcrumb,
        product,
        webpage: {
          headline: title,
          description,
        },
      },
    }
  }
)