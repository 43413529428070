import React, { useState, useEffect } from 'react'
import type { ConnectorProps as ArtworkDescriptionProps } from '../../../connectors/ArtworkDescription'
import ArtworkDescriptionConnector from '../../../connectors/ArtworkDescription'
import AccordionComponent from '../AccordionComponent'
import ContactUs from './ContactUs'
import {
  DELIVERY_TIME_COPY,
  RETURN_POLICY_LINK,
  OPEN_EDITION,
  PHOTO_PAPER,
  FINE_ART_PAPER,
  BOX_TEXT,
  ROLLED_IN_TUBE_TEXT,
  ACRYLIC,
  ACRYLIC_DELIVERY_TIME_COPY,
  METAL,
  METAL_DELIVERY_TIME_COPY,
} from '../../../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ArtworkDescriptionConnector((props: ArtworkDescriptionProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { currentTab, selectedPrintProduct, selectedFrameOption, screenWidth, artworkID } = props
  const [showContent, setShowContent] = useState(false)
  useEffect(() => {
    setShowContent(false)
  }, [artworkID])
  const { material } = selectedPrintProduct

  if (currentTab !== OPEN_EDITION) {
    return null
  }

  const targetValue = screenWidth > 1064 ? '_blank' : '_self'
  const relValue = screenWidth > 1064 ? 'noopener noreferrer' : ''

  const getPackagingType = () => {
    if (!selectedFrameOption && (material === PHOTO_PAPER || material === FINE_ART_PAPER)) {
      return ROLLED_IN_TUBE_TEXT
    } else return BOX_TEXT
  }

  const getReturnCopy = () => {
    return (
      <>
        Visit our{' '}
        <a
          data-type='support-link'
          target={targetValue}
          rel={relValue}
          href={RETURN_POLICY_LINK}
          title='help section'
        >
          help section
        </a>{' '}
        for more information.
      </>
    )
  }

  const getReturnSection = () => {
    return (
      <div data-type='accordion-sub-section'>
        <div data-type='accordion-sub-header-title'>
          <SATypography
            truncateText
            data-type='accordion-sub-header'
            variant={SATypographyVariant.H6}
          >
            Returns:
          </SATypography>
        </div>
        <div data-type='accordion-sub-header-content'>
          <SATypography variant={SATypographyVariant.MEDIUM}>
            All Open Edition prints are final sale items and ineligible for returns.
            {!showContent && screenWidth < 1070 ? null : getReturnCopy()}
          </SATypography>
        </div>
      </div>
    )
  }

  const getAccordionTitleSection = () => {
    if (!showContent && screenWidth < 500) {
      return null
    } else if (!showContent && screenWidth > 500 && screenWidth < 1253) {
      return material && getReturnSection()
    } else {
      return (
        <>
          {material && getReturnSection()}
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Delivery Cost:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                Calculated at checkout.
              </SATypography>
            </div>
          </div>
        </>
      )
    }
  }

  const getDeliveryTime = (): string => {
    switch (material) {
      case ACRYLIC:
        return ACRYLIC_DELIVERY_TIME_COPY

      case METAL:
        return METAL_DELIVERY_TIME_COPY

      default:
        return DELIVERY_TIME_COPY
    }
  }

  return (
    <AccordionComponent
      dataType='shipping-returns'
      title='SHIPPING AND RETURNS'
      showContent={showContent}
      setShowContent={setShowContent}
      screenWidth={screenWidth}
      headerChildren={
        <>
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Delivery Time:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>{getDeliveryTime()}</SATypography>
            </div>
          </div>
          {getAccordionTitleSection()}
        </>
      }
    >
      <>
        {material && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Handling:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {`${getPackagingType()}. Art prints are packaged and shipped by our printing partner.`}
              </SATypography>
            </div>
          </div>
        )}

        <div data-type='accordion-sub-section'>
          <div data-type='accordion-sub-header-title'>
            <SATypography variant={SATypographyVariant.H6}>Ships From:</SATypography>
          </div>
          <div data-type='accordion-sub-header-content'>
            <SATypography variant={SATypographyVariant.MEDIUM}>
              Printing facility in California.
            </SATypography>
          </div>
        </div>
        <ContactUs />
      </>
    </AccordionComponent>
  )
})