import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const MAX_WIDTH = `1024`
export const MAX_WIDTH_DESKTOP = `700`
export const MAX_HEIGHT_DESKTOP = `800`
export const MAX_WIDTH_MOBILE = `740`
export const MAX_HEIGHT_MOBILE = `740`
export const GalleryLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAX_WIDTH_DESKTOP}px;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    max-width: ${MAX_WIDTH_MOBILE}px;
  }

  [data-section='mobile-actions'] {
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    [data-type='row'] {
      display: flex;
      align-content: center;
      justify-content: space-between;
    }
  }
`
export const Gallery = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: ${(props) => props.width}px;
  max-height: ${(props) => props.height}px;
  //height: ${(props) => props.height}px;
  transition: height 0.5s ease-out;
  overflow: hidden;
  /* Remove CSS transition when switching between tabs */
  ${(props) => (props.disableAnimations ? `transition: height 0s ease-out;` : null)}

  button {
    border: none;
    background: none;
    outline: none;
  }

  [data-section='items'] {
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
    white-space: nowrap;
    transform: translateX(0px);
    transition: transform 0.5s ease 0s;
  }

  [data-type='item'] {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // align-items: center;
    /*padding: 5px 10px 0 10px;*/
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.8s ease-out;
    &[data-style='active'] {
      opacity: 1;
      /* We need to handle image size until react is ready, hide first layout image until we have dimensions */
      ${(props) => props.width <= 0 && `opacity: 0;`}
    }
    [data-type='image'] {
      img {
        align-self: flex-start;
        width: auto;
        height: auto;
        max-width: ${(props) => props.width}px;
        max-height: ${(props) => props.height}px;
      }
    }
    [data-type='print'] {
      img {
        width: auto;
        height: auto;
        max-width: ${(props) => props.width - 5}px;
        max-height: ${(props) => props.height - 5}px;
        align-self: flex-start;
        &[data-material='Acrylic'] {
          max-width: ${(props) => props.width - 25}px;
          max-height: ${(props) => props.height - 25}px;
        }
        &[data-material='Metal'] {
          max-width: ${(props) => props.width - 25}px;
          max-height: ${(props) => props.height - 25}px;
        }
      }
    }
    @media (min-width: ${MAX_WIDTH}px) {
      align-items: flex-start;
    }
  }

  [data-type='image'] {
    user-select: ${(props) => (props.isAdmin ? 'initial' : 'none')};
    pointer-events: ${(props) => (props.isAdmin ? 'initial' : 'none')};
    justify-content: center;
    align-items: center;
    display: flex;
  }

  [data-type='print'] {
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 5px 10px 0 10px;*/
    user-select: none;
    pointer-events: none;
    padding-top: 2px;
    width: ${({ width }) => width}px;
    height: ${({ height }) =>
      height > 650 ? 650 : height}px; /* Fix for keeping print aligned to top */
  }

  @media (max-width: ${MAX_WIDTH}px) {
    [data-type='image'] {
      img {
        align-self: flex-start;
      }
    }
  }

  [data-section='mobile-actions'] {
    display: flex;
    flex-direction: column;
    width: 100%;
    [data-type='row'] {
      display: flex;
      align-content: center;
      justify-content: space-between;
    }
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }
`
export const Controls = styled.div`
  @media (min-width: ${MAX_WIDTH}px) {
    display: none;
  }
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  svg {
    width: 36px;
    height: 36px;
    cursor: pointer;
    position: absolute;
    min-height:
    top: 250px;
    z-index: 10;
    &[data-type='back'] {
      left: 0;
    }
    &[data-type='next'] {
      right: 0;
    }
  }

  [data-section='dots'] {
    display: flex;
    flex: 1 auto;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    button {
      background: none;
      border: none;
      cursor: pointer;
      &[data-type='close'] {
        align-self: flex-end;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      &[data-type='dot'] {
        border-radius: 100%;
        width: 10px;
        height: 10px;
        margin: 0 10px 0 0;
        padding: 0;
        cursor: pointer;
        background-color: #dcdcdc;

        &:last-child {
          margin-right: 0;
        }

        &[data-style='active'] {
          background: #333333;
        }
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    svg {
      position: initial;
      width: 20px;
      height: 20px;
    }
  }
`