import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getArtworkCategory, getArtworkKeywords } from '../selectors/artwork'
import { getCurrentProductType } from '../selectors/product'
const mapStateToProps = createStructuredSelector({
  category: getArtworkCategory,
  keywords: getArtworkKeywords,
  productType: getCurrentProductType,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  category: string
  keywords: Array<string>
  productType: string
}
export default connect(mapStateToProps, mapDispatchToProps)