import { createSelector } from 'reselect'
import { getPageData } from 'selectors/page'
import { getUserID, getIsAdmin } from 'selectors/user/session'
import { getIsUpdating } from 'selectors/ops/curate'
import { getArtistId } from './artist'
import { getCurrentTab, getArtworkAdminData } from './product'
export { getIsUpdating, getIsAdmin }
export const getCanEditArtwork = createSelector(
  [getArtistId, getUserID, getIsAdmin],
  (artistId, userId, isAdmin): boolean => artistId === userId || isAdmin || false
)
export const getUploaderAvailable = createSelector(
  [getCurrentTab],
  (currentTab): boolean => currentTab === 'original' || false
)
export const getAdmin = createSelector(
  [getPageData],
  (page): Record<string, any> => page.admin || {}
)
export const hasAdminData = createSelector(
  [getAdmin],
  (admin): boolean => !!Object.keys(admin).length || false
)
export const getIsSafe = createSelector(
  [getAdmin],
  (admin): boolean => admin.isArtworkSafe || false
)
export const getCurationData = createSelector(
  [getArtworkAdminData, getArtistId, getAdmin, getUserID],
  (artworkAdminData, userId, admin, curatorId): Record<string, any> => {
    const { artistEmail, isArtworkSafe: isSafe } = admin
    return { ...artworkAdminData, artistEmail, isSafe, userId }
  }
)