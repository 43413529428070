import { convertDefaultToLocaleCurrency, formatPrice } from 'components/FormatPrice/helpers'
import { convertPrintInchesToCentimeters } from 'components/MeasurementUnit/helper'
import { convertFromCentsToDollars } from './index'
export const getPrintSizeTitle = (
  product: Record<string, any>,
  selectedCurrency: Record<string, any>,
  userSizePreference: string
): string => {
  const { width, height, print } = product
  const convertedWidth = convertPrintInchesToCentimeters(width, userSizePreference)
  const convertedHeight = convertPrintInchesToCentimeters(height, userSizePreference)
  return `${convertedWidth} x ${convertedHeight} ${userSizePreference} (${formatPrice(
    convertDefaultToLocaleCurrency(convertFromCentsToDollars(print.listPrice), selectedCurrency),
    selectedCurrency
  )})`
}
export const getOptionTitle = (
  option: Record<string, any>,
  selectedCurrency: Record<string, any>
): string => `
    ${option.title} ${
  option.price
    ? `(${formatPrice(
        convertDefaultToLocaleCurrency(convertFromCentsToDollars(option.price), selectedCurrency),
        selectedCurrency
      )})`
    : ''
}
`
type Option = {
  id: string
  price: number
  title: string
}
type Product = {
  material: string
  isLimitedEdition: boolean
  unitsProduced?: number | null | undefined
  sku: string
  width: number
  height: number
  price: number
  options: Array<Option>
  print: {
    recommendedOptionId: string | null | undefined
  }
}
type MaterialDropdownData = {
  data: Product
  title: string
  value: string
}
type SizeDropdownData = {
  data: Product
  title: string
  value: string
  note: string
}
type FrameOptionDropdownData = {
  data?: string
  value: string
  title: string
  note?: string
}
type CanvasWrapDropdownData = {
  data: string
  value: string
  title: string
  note: string
}

function getMaterialsDropdownData(products: Array<Product>): Array<MaterialDropdownData> {
  return products.reduce((carry: Array<MaterialDropdownData>, product: Product) => {
    // only add materials to the drop-down that have not already been added
    if (
      carry.filter((material: { title: string }) => material.title === product.material).length ===
      0
    ) {
      carry.push({
        data: product,
        title: product.material,
        value: product.material,
      })
    }

    return carry
  }, [])
}

function getSizesDropdownData(
  products: Array<Product>,
  selectedProduct: Product,
  selectedCurrency: Record<string, any>,
  userSizePreference: string
): Array<SizeDropdownData> {
  return products.reduce((carry: Array<SizeDropdownData>, product: Product) => {
    if (product.material === selectedProduct.material) {
      carry.push({
        data: product,
        title: getPrintSizeTitle(product, selectedCurrency, userSizePreference),
        value: product.sku,
        note:
          product.isLimitedEdition && product.unitsProduced
            ? `Limited Edition of ${product.unitsProduced}`
            : '',
      })
    }

    return carry
  }, [])
}

function getFrameOptionsList(
  selectedProduct: Product,
  selectedCurrency: Record<string, any>
): Array<FrameOptionDropdownData> {
  const { print, isLimitedEdition } = selectedProduct
  const frameOptionsList = selectedProduct.options.reduce(
    (carry: Array<Record<string, any>>, option: Option) => {
      if (option.id.charAt(0) === 'F') {
        carry.push({
          data: option.id,
          value: option.id,
          title: getOptionTitle(option, selectedCurrency),
          note:
            isLimitedEdition && print.recommendedOptionId === option.id
              ? 'Curator Recommended'
              : '',
        })
      }

      return carry
    },
    []
  )
  frameOptionsList.push({
    title: 'No Frame',
    value: '',
  })
  return frameOptionsList
}

function getCanvasWrapColorOptionsList(
  selectedProduct: Product,
  selectedCurrency: Record<string, any>
): Array<CanvasWrapDropdownData> {
  return selectedProduct.options.reduce((carry: Array<CanvasWrapDropdownData>, option: Option) => {
    if (option.id.charAt(0) === 'W') {
      carry.push({
        data: option.id,
        value: option.id,
        title: getOptionTitle(option, selectedCurrency),
        note: '',
      })
    }

    return carry
  }, [])
}

type DropdownData = {
  materials: Array<MaterialDropdownData>
  sizes: Array<SizeDropdownData>
  frameOptions: Array<FrameOptionDropdownData>
  canvasWrapColorOptions: Array<CanvasWrapDropdownData>
}
export function createDropdownData(
  products: Array<Product>,
  selectedProduct: Product,
  selectedCurrency: Record<string, any>,
  userSizePreference: string
): DropdownData {
  const materials = getMaterialsDropdownData(products)
  const sizes = getSizesDropdownData(
    products,
    selectedProduct,
    selectedCurrency,
    userSizePreference
  )
  const frameOptions = getFrameOptionsList(selectedProduct, selectedCurrency)
  const canvasWrapColorOptions = getCanvasWrapColorOptionsList(selectedProduct)
  return {
    materials,
    sizes,
    frameOptions,
    canvasWrapColorOptions,
  }
}
export const getPanelTitle = (category: string | null | undefined): string => {
  const title =
    category === ('Sculpture' || 'Installation') ? 'Number of Pieces' : 'Number of Panels'
  return category ? title : ''
}