import React from 'react'
import type { ConnectorProps as ProductOriginalProps } from '../../../connectors/ProductsOriginal'
import ProductOriginalConnector from '../../../connectors/ProductsOriginal'
import { NOT_FOR_SALE } from '../../../helpers/constants'
export default ProductOriginalConnector((props: ProductOriginalProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { productStatus } = props

  if (productStatus !== NOT_FOR_SALE) {
    return null
  }

  return <span>This artwork is not for sale.</span>
})