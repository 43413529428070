import React, { useEffect } from 'react'
import SessionConnector from 'containers/Session'
import type { Session } from 'types/User/Session'
import 'types/User/Session'
type Props = {
  children: React.ReactElement<React.ComponentProps<any>, any>
  session: Session
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { children, session } = props
  const { isAdmin } = session

  const disableContextMenu = (ev: React.SyntheticEvent<HTMLElement>) => {
    if (isAdmin) {
      return true
    }

    // Only ADMINS have access to ContextMenu
    ev.preventDefault()
    return false
  }

  useEffect(() => {
    window.addEventListener('contextmenu', disableContextMenu)
    return () => {
      window.removeEventListener('contextmenu', disableContextMenu)
    }
  }, [isAdmin])
  return <>{children}</>
}

export default SessionConnector(Component)