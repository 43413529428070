import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { Gallery } from './gallery'
import { Products } from './products'
export const Grid = styled.div``
export const MainContent = styled.div`
  display: grid;
  grid-template-columns: auto 340px;
  grid-gap: 20px;
  grid-template-areas: 'gallery sidepanel';
  align-items: flex-start;
  margin: 20px 0 0 0;

  @media (max-width: 1024px) {
    grid-template-columns: 100%;
    grid-template-areas:
      'gallery'
      'sidepanel';
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
  }

  ${Gallery} {
    position: relative;
    grid-area: gallery;
  }
  ${Products} {
    grid-area: sidepanel;
  }
`