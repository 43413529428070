import React from 'react'
import AboutArtwork from './AboutArtwork'
import OriginalProductDetails from './ProductDetails/Original'
import PrintProductDetails from './ProductDetails/Print'
import LimitedProductDetails from './ProductDetails/Limited'
import OriginalShippingAndReturns from './ShippingAndReturns/Original'
import PrintShippingAndReturns from './ShippingAndReturns/Print'
import LimitedShippingAndReturns from './ShippingAndReturns/Limited'
import LimitedEditionDetails from './LimitedEditionDetails'
import LegalDisclosureSection from './LegalDisclosureSection'
import { Layout } from '../styles/artworkDescriptionSection/aboutArtwork'
export default (): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <Layout>
      <div data-type='top-divider' />
      <AboutArtwork />
      <div data-type='top-divider' />
      <OriginalProductDetails />
      <PrintProductDetails />
      <LimitedProductDetails />
      <LimitedEditionDetails />
      <div data-type='top-divider' />
      <OriginalShippingAndReturns />
      <PrintShippingAndReturns />
      <LimitedShippingAndReturns />
      <LegalDisclosureSection />
      <div data-type='bottom-divider' />
    </Layout>
  )
}