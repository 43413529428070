import fetch from 'isomorphic-fetch'
import { parseJSON } from 'apis/helpers'

const getArtDetails = async ({
  artistId,
  artworkId,
}: Record<string, any>): Promise<Record<string, any>> => {
  const response = await fetch(`/easel_api/artwork/${artistId}/${artworkId}`)
  const json = await parseJSON(response, 'getArtDetails')
  return json
}

const incrementViewCount = async (artworkId: number): Promise<Record<string, any>> => {
  const response = await fetch(`/easel_api/artwork/${artworkId}/ivc`)
  const json = await parseJSON(response, 'getArtDetails')
  return json
}

export { getArtDetails, incrementViewCount }