import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getPageIsReady } from 'selectors/page'
import { getCanEditArtwork, getIsAdmin } from '../selectors/admin'
const mapStateToProps = createStructuredSelector({
  canEditArtwork: getCanEditArtwork,
  isAdmin: getIsAdmin,
  pageIsReady: getPageIsReady,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  canEditArtwork: boolean
  isAdmin: boolean
  pageIsReady: boolean
}
export default connect(mapStateToProps, mapDispatchToProps)