import React, { useRef, useState, useEffect } from 'react'
import type { ConnectorProps as ArtworkDescriptionProps } from '../../connectors/ArtworkDescription'
import ArtworkDescriptionConnector from '../../connectors/ArtworkDescription'
import { displayEllipticalString } from '../../helpers'
import {
  LEGAL_DISCLOSURE,
  LIMITED_EDITION,
  LEGAL_DISCLOSURE_DATA_ARRAY,
  LEGAL_DISCLOSURE_TEXT,
} from '../../helpers/constants'
import AccordionComponent from './AccordionComponent'
export default ArtworkDescriptionConnector((props: ArtworkDescriptionProps) => {
  const { currentTab, screenWidth, artworkID } = props
  const descriptionRef: Record<string, any> = useRef(null)
  const [showContent, setShowContent] = useState(false)
  // find the width of description <p> tag
  const descriptionWidth = descriptionRef.current ? descriptionRef.current.offsetWidth : 0
  // specs to display description text when accordion is in collapsed state
  // 1. display max of 3 lines of text
  // 2. if description has more than 3 lines, display 2/3rd of the third line and add [...]
  // 3. if description has 2 lines or less, display 'original created: year' section as the third line
  // maxWidth of <p> tag = 757
  // max no of characters that makes 3 lines of text (for <p> width 757) = 341
  // below logic determines what the string length should be before adding [...]
  // max no of characters to make 2/3 of 3rd line (for <p> width 757) = 300
  const descriptionLengthValue = (descriptionWidth * 341) / 757

  const getDescriptionText = () => {
    if (!showContent) {
      if (descriptionRef.current && LEGAL_DISCLOSURE.length > descriptionLengthValue) {
        return displayEllipticalString(LEGAL_DISCLOSURE, (descriptionWidth * 300) / 757)
      } else {
        return LEGAL_DISCLOSURE
      }
    } else {
      return LEGAL_DISCLOSURE
    }
  }

  useEffect(() => {
    setShowContent(false)
  }, [artworkID])

  if (currentTab !== LIMITED_EDITION) {
    return null
  }

  return (
    <>
      <div data-type='top-divider' />
      <AccordionComponent
        dataType='legal-disclosure'
        title='Legal Disclosure'
        screenWidth={screenWidth}
        showContent={showContent}
        setShowContent={setShowContent}
        headerChildren={
          <div data-type='accordion-sub-section' data-type-variant='legal'>
            <p data-type='legal' ref={descriptionRef}>
              {getDescriptionText()}
            </p>
          </div>
        }
      >
        <>
          <div data-type='accordion-sub-section' data-type-variant='legal-content'>
            <p data-type='accordion-sub-value'>{LEGAL_DISCLOSURE_TEXT}</p>
            {LEGAL_DISCLOSURE_DATA_ARRAY.map(({ title, text }, index) => (
              <div key={index}>
                <h3 data-type='accordion-sub-header'>{title}</h3>
                <p data-type='accordion-sub-value'>{text}</p>
              </div>
            ))}
          </div>
        </>
      </AccordionComponent>
    </>
  )
})