import React, { useState } from 'react'
import { LocaleCurrency, USD } from 'components/FormatPrice'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import type { ConnectorProps as ProductOriginalProps } from '../../../connectors/ProductsOriginal'
import ProductOriginalConnector from '../../../connectors/ProductsOriginal'
import Affirm from './Affirm'
import MAO from './MAO'
import { LinkButton, Row, Price } from '../../styles/products'
import { FOR_SALE } from '../../../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ProductOriginalConnector((props: ProductOriginalProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const [addToCart, updateAddToCart] = useState(false)
  const { clickAddToCart, clickMakeAnOffer, productStatus, sku, updateDataLayer, listPrice } = props

  if (productStatus !== FOR_SALE) {
    return null
  }

  return (
    <>
      <Row>
        <Price>
          <SATypography variant={SATypographyVariant.H4}>
            <LocaleCurrency price={convertFromCentsToDollars(listPrice)} />
            <USD />
          </SATypography>
        </Price>

        <LinkButton
          addingToCart={addToCart}
          data-button='add-to-cart'
          disabled={addToCart}
          onClick={(ev: React.SyntheticEvent<HTMLLinkElement>) => {
            ev.preventDefault()
            updateAddToCart(true)
            clickAddToCart(sku)
          }}
          title='Add artwork to cart'
        >
          <SATypography
            style={{ color: '#ffffff', margin: 0 }}
            variant={SATypographyVariant.CTA_LARGE}
            bold
          >
            {addToCart ? 'Adding...' : 'Add to Cart'}
          </SATypography>
        </LinkButton>
      </Row>

      <Row>
        <Affirm />

        <span />

        <MAO onClick={clickMakeAnOffer} sku={sku} updateDataLayer={updateDataLayer} />
      </Row>
    </>
  )
})