import { animateScroll } from 'components/ScrollTo'
import { Constants } from 'actions/utility/scroll'
import { lockScroll, unlockScroll } from 'lib/lockScroll'
import { observerEpicHelper } from 'epics/helpers'
export const lockScrollEpic = observerEpicHelper(
  [Constants.LOCK_SCROLL],
  async ({ action, observer, store }) => {
    // Used for Modals/Overlays
    action.payload ? lockScroll() : unlockScroll()
    observer.complete()
  }
)
export const scrollToEpic = observerEpicHelper(
  [Constants.SCROLL_TO_ELEMENT, Constants.SCROLL_TO_TOP],
  async ({ action, observer, store }) => {
    // Sets uses the default offset/duration unless a payload overrides it.
    animateScroll({
      offset: 0,
      duration: 200,
      ...(action.payload ? action.payload : {}),
    })
    observer.complete()
  }
)
export default [lockScrollEpic, scrollToEpic]