import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import {
  getArtworkTitle,
  getArtworkCategory,
  getArtworkSubject,
  getArtworkStyles,
} from '../../selectors/artwork'
import { getArtistName } from '../../selectors/artist'
import type { SelectedFrameOption, SelectedCanvasWrapColorOption } from '../../selectors/product'
import {
  getSelectedFrameOption,
  getSelectedCanvasWrapColorOption,
  getSelectedProduct,
} from '../../selectors/product'
import { getGalleryDimensions } from '../../selectors/gallery'
const mapStateToProps = createStructuredSelector({
  galleryDimensions: getGalleryDimensions,
  selectedFrameOption: getSelectedFrameOption,
  selectedCanvasWrapColorOption: getSelectedCanvasWrapColorOption,
  selectedProduct: getSelectedProduct,
  title: getArtworkTitle,
  category: getArtworkCategory,
  subject: getArtworkSubject,
  styles: getArtworkStyles,
  artistName: getArtistName,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  galleryDimensions: Record<string, any>
  selectedProduct: Record<string, any>
  selectedFrameOption: SelectedFrameOption
  selectedCanvasWrapColorOption: SelectedCanvasWrapColorOption
  title: string
  category: string
  subject: string
  styles: ReadonlyArray<string>
  artistName: string
}
export default connect(mapStateToProps, mapDispatchToProps)