import React from 'react'
import { LocaleCurrency as LocaleCurrencyComponent, USD } from 'components/FormatPrice'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import type { ConnectorProps as ProductPrintProps } from '../../../connectors/ProductsPrint'
import ProductPrintConnector from '../../../connectors/ProductsPrint'
import { BlackBox, LinkButton, Column, Price } from '../../styles/products'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const PrintForSaleComponent = (
  props: ProductPrintProps
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    clickAddToCart,
    totalPriceInCentsOfSelectedOptions,
    selectedProduct: { print },
    selectedProductSku,
  } = props
  const { listPrice } = print

  return (
    <BlackBox data-type='print'>
      <Column align='start'>
        <SATypography variant={SATypographyVariant.H4}>
          <Price>
            <LocaleCurrencyComponent
              price={convertFromCentsToDollars(listPrice + totalPriceInCentsOfSelectedOptions)}
            />
            <USD />
          </Price>
        </SATypography>
      </Column>

      <Column mr={2}>
        <LinkButton
          data-button='add-to-cart'
          onClick={(ev: React.SyntheticEvent<HTMLLinkElement>) => {
            ev.preventDefault()
            clickAddToCart(selectedProductSku)
          }}
          title='Add print to cart'
        >
          <SATypography
            style={{ color: '#ffffff', margin: 0 }}
            variant={SATypographyVariant.CTA_LARGE}
            bold
          >
            Add to Cart
          </SATypography>
        </LinkButton>
      </Column>
    </BlackBox>
  )
}

export default ProductPrintConnector(PrintForSaleComponent)