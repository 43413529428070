import React from 'react'
import { ViewIcon, CartIcon } from '../../styles/icons'
export const viewPercentage = [
  {
    min: 0,
    max: 9,
    percent: 0,
    cap: 0,
  },
  {
    min: 10,
    max: 999,
    percent: 0.23,
    cap: 4,
  }, // 23%
  {
    min: 1000,
    max: 9999,
    percent: 0.14,
    cap: 7,
  }, // 14%
  {
    min: 10000,
    max: 10000000,
    percent: 0.07,
    cap: 15,
  }, // 7%
]
export const cartPercentage = [
  {
    min: 0,
    max: 9,
    percent: 0,
    cap: 0,
  },
  {
    min: 10,
    max: 99,
    percent: 0.05,
    cap: 0,
  }, // 5%
  {
    min: 10,
    max: 999,
    percent: 0.02,
    cap: 0,
  }, // 2%
  {
    min: 1000,
    max: 9999,
    percent: 0.002,
    cap: 0,
  }, // .2%
  {
    min: 10000,
    max: 10000000,
    percent: 0.0002,
    cap: 0,
  }, // .02%
]
export const getViewCount = (view: number, percentage: Array<Record<string, any>>): number => {
  const { percent, cap } = percentage.find(
    ({ min, max }): Record<string, any> => min < view && max > view
  ) || {
    percent: 0,
    cap: 0,
  }

  if (cap) {
    return Math.round(Math.random() * cap)
  }

  return Math.round(view * percent)
}
export const getText = ({
  type,
  views,
}: {
  type: string
  views: number
}): React.ReactElement<React.ComponentProps<any>, any> => {
  if (type === 'cart') {
    const viewCount = getViewCount(views, cartPercentage) || 1
    return (
      <>
        <CartIcon />
        <p>
          <strong>
            {viewCount} {viewCount === 1 ? 'person' : 'people'}
          </strong>{' '}
          {viewCount === 1 ? 'has' : 'have'} added this to their cart
        </p>
      </>
    )
  }

  // type === 'view'
  const viewCount = getViewCount(views, viewPercentage) || 1
  return (
    <>
      <ViewIcon />
      <p>
        <strong>
          {viewCount} {viewCount === 1 ? 'person' : 'people'}
        </strong>{' '}
        {viewCount === 1 ? 'is' : 'are'} now viewing this artwork
      </p>
    </>
  )
}
