import React from 'react'
import { LocaleCurrency } from 'components/FormatPrice'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import type { ConnectorProps as ProductOriginalProps } from '../../../connectors/ProductsOriginal'
import ProductOriginalConnector from '../../../connectors/ProductsOriginal'
import { LinkButton } from '../../styles/products'
import { RESERVED } from '../../../helpers/constants'
export default ProductOriginalConnector((props: ProductOriginalProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { listPrice, productStatus } = props

  if (productStatus !== RESERVED) {
    return null
  }

  return (
    <>
      <LinkButton data-button='reserved' title='Artwork reserved'>
        Reserved
      </LinkButton>

      <span>
        This artwork is reserved ( <LocaleCurrency price={convertFromCentsToDollars(listPrice)} /> )
      </span>
    </>
  )
})