import React, { useRef, useState, useEffect } from 'react'
import SALink from 'components/SALink'
import { slugify } from 'lib/slugify'
import type { ConnectorProps as ArtworkDescriptionProps } from '../../connectors/ArtworkDescription'
import ArtworkDescriptionConnector from '../../connectors/ArtworkDescription'
import {
  getLinkCategory,
  displayEllipticalString,
  displayStringWithoutLineBreak,
} from '../../helpers'
import AccordionComponent from './AccordionComponent'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ArtworkDescriptionConnector((props: ArtworkDescriptionProps) => {
  const {
    category,
    description,
    subject,
    styles,
    mediums,
    materials,
    currentTab,
    yearProduced,
    screenWidth,
    artworkID,
  } = props
  const linkCategory = getLinkCategory(category)
  const linkCategoryType =
    currentTab === 'original' || currentTab === 'info' ? linkCategory : 'Prints'
  const descriptionRef: Record<string, any> = useRef(null)
  const [showContent, setShowContent] = useState(false)
  // if description includes line breaks, trim it
  const cleanDescription = description.includes('\n')
    ? displayStringWithoutLineBreak(description)
    : description
  // find the width of description <p> tag
  const descriptionWidth = descriptionRef.current ? descriptionRef.current.offsetWidth : 0
  // specs to display description text when accordion is in collapsed state
  // 1. display max of 3 lines of text
  // 2. if description has more than 3 lines, display 2/3rd of the third line and add [...]
  // 3. if description has 2 lines or less, display 'original created: year' section as the third line
  // maxWidth of <p> tag = 757
  // max no of characters that makes 3 lines of text (for <p> width 757) = 341
  // below logic determines what the string length should be before adding [...]
  // max no of characters to make 2/3 of 3rd line (for <p> width 757) = 300
  const descriptionLengthValue = (descriptionWidth * 341) / 757
  // maxWidth of <p> tag = 757
  // max no of characters that makes 2 lines of text (for <p> width 757) = 240
  // below logic determines what the string length should be
  // to determine if to display 'original created: year' section
  const stringLengthValueToDisplayYear = (descriptionWidth * 240) / 757

  const getDescriptionText = () => {
    if (!showContent) {
      if (descriptionRef.current && cleanDescription.length > descriptionLengthValue) {
        return displayEllipticalString(cleanDescription, (descriptionWidth * 300) / 757)
      } else {
        return cleanDescription
      }
    } else {
      return description
    }
  }

  const yearVisibility = cleanDescription.length < stringLengthValueToDisplayYear
  const uniqueMaterial= Array.from(new Set([...(materials || [])]))
  const uniqueStyle = Array.from(new Set([...(styles || [])]))
  const uniqueMedium = Array.from(new Set([...(mediums || [])]))

  useEffect(() => {
    setShowContent(false)
  }, [artworkID])


  return (
    <AccordionComponent
      dataType='about-artwork'
      title='ABOUT THE ARTWORK'
      screenWidth={screenWidth}
      showContent={showContent}
      setShowContent={setShowContent}
      headerChildren={
        <>
          <div data-type='accordion-sub-section' data-type-variant='description'>
            <SATypography
              variant={SATypographyVariant.MEDIUM}
              ref={descriptionRef}
              data-type='description'
              dangerouslySetInnerHTML={{
                __html: `${(getDescriptionText() || '').replace(/\n/g, '<br />')}`,
              }}
            ></SATypography>
          </div>
          {yearProduced && yearVisibility && (
            <div data-type='accordion-sub-section'>
              <div data-type='accordion-sub-header-title'>
                <SATypography variant={SATypographyVariant.H6}>Original Created:</SATypography>
              </div>
              <div data-type='accordion-sub-header-content'>
                <SATypography variant={SATypographyVariant.MEDIUM}>{yearProduced}</SATypography>
              </div>
            </div>
          )}
        </>
      }
    >
      <>
        {yearProduced && !yearVisibility && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Original Created:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>{yearProduced}</SATypography>
            </div>
          </div>
        )}
        {subject.length && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Subjects:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SALink
                href={`/${slugify(linkCategoryType)}/${slugify(subject)}`}
                title={`${subject} ${linkCategoryType}`}
              >
                <SATypography variant={SATypographyVariant.MEDIUM}>{subject}</SATypography>
              </SALink>
            </div>
          </div>
        )}
        {materials.length && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Materials:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <div>
                {uniqueMaterial.map((material, materialIndex) => (
                  <SALink
                    key={material}
                    href={`/${slugify(linkCategoryType)}/${slugify(material)}`}
                    title={`${material} ${linkCategoryType}`}
                    data-type='multiple-links'
                  >
                    <SATypography variant={SATypographyVariant.MEDIUM}>{`${material}${materialIndex !== uniqueMaterial.length - 1 ? ',' : ''}`}&nbsp;</SATypography>
                  </SALink>
                ))}
              </div>
            </div>
          </div>
        )}
        {styles.length && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Styles:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <div>
                {uniqueStyle.map((style, styleIndex) => (
                  <SALink
                    key={style}
                    href={`/${slugify(linkCategoryType)}/${slugify(style)}`}
                    title={`${style} ${linkCategoryType}`}
                    data-type='multiple-links'
                  >
                    <SATypography variant={SATypographyVariant.MEDIUM}>{`${style}${styleIndex !== uniqueStyle.length - 1 ? ',' : ''}`}&nbsp;</SATypography>
                  </SALink>
                ))}
              </div>
            </div>
          </div>
        )}
        {mediums.length && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Mediums:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <div>
                {uniqueMedium.map((medium, mediumIndex) => (
                  <SALink
                    key={medium}
                    href={`/${slugify(linkCategoryType)}/${slugify(medium)}`}
                    title={`${medium} ${linkCategoryType}`}
                    data-type='multiple-links'
                  >
                    <SATypography variant={SATypographyVariant.MEDIUM}>{`${medium}${mediumIndex !== uniqueMedium.length - 1 ? ',' : ''}`}&nbsp;</SATypography>
                  </SALink>
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    </AccordionComponent>
  )
})