import { observerEpicHelper, dispatchFlashMessage } from 'epics/helpers'
import * as CurateApis from 'apis/ops/curate'
import * as ArtworkApis from 'apis/artwork'
import {
  findCuratorIdByName,
  getArtworkId,
  getBookmarkInfo,
  getCurators,
} from 'selectors/ops/curate'
import { getUserID } from 'selectors/user/session'
import { Actions as CurateActions, Constants } from 'actions/ops/curate'
import { getFormattedDateTime } from 'lib/date'
export const deleteArtworkByCuratorEpic = observerEpicHelper(
  [Constants.DELETE_ARTWORK_BY_CURATOR],
  async ({ action, observer, store }) => {
    const { artworkId } = action.payload
    const actionType = 'curationDeleteArtwork'
    const confirmDeletion = confirm('Are you sure you want to delete this artwork?')

    if (confirmDeletion) {
      observer.next(
        CurateActions.UPDATING_CURATION_ARTWORK_ACTION({
          artworkId,
          actionType,
        })
      )
      const { success } = await ArtworkApis.deleteMyArtwork(action.payload)

      if (!success) {
        dispatchFlashMessage({
          observer,
          message: 'Problem deleting the artwork',
          type: 'error',
        })
      } else {
        observer.next(
          CurateActions.DELETE_ARTWORK_BY_CURATOR_COMPLETE({ ...action.payload, deleted: true })
        )
        dispatchFlashMessage({
          observer,
          message: 'Successfully deleted the artwork',
          type: 'success',
        })
      }

      observer.next(
        CurateActions.UPDATING_CURATION_ARTWORK_ACTION({
          artworkId,
          actionType,
          complete: true,
        })
      )
    }

    observer.complete()
  }
)
export const deleteArtworkCurationStatusEpic = observerEpicHelper(
  [Constants.DELETE_ARTWORK_CURATION_STATUS],
  async ({ action, observer, store }) => {
    observer.next(CurateActions.UPDATING_CURATION_ACTION(true))
    const confirmDeletion = confirm('Are you sure you want to delete this curation status?')

    if (confirmDeletion) {
      const { success } = await CurateApis.deleteCurationArtworkStatus(action.payload)

      if (!success) {
        dispatchFlashMessage({
          observer,
          message: 'Problem deleting the curation status',
          type: 'error',
        })
      } else {
        dispatchFlashMessage({
          observer,
          message: 'Successfully deleted your curation status',
          type: 'success',
        })
      }
    }

    observer.next(CurateActions.UPDATING_CURATION_ACTION(false))
    observer.complete()
  }
)
export const updateArtworkCurationStatusEpic = observerEpicHelper(
  [Constants.UPDATE_ARTWORK_CURATION_STATUS],
  async ({ action, observer, store }) => {
    const { artworkId } = action.payload
    const actionType = 'curationStatusArtwork'
    observer.next(
      CurateActions.UPDATING_CURATION_ARTWORK_ACTION({
        artworkId,
        actionType,
      })
    )
    const { success } = await CurateApis.updateCurationArtworkStatus(action.payload)

    if (!success) {
      dispatchFlashMessage({
        observer,
        message: 'Problem updating the curation status',
        type: 'error',
      })
    } else {
      observer.next(CurateActions.UPDATE_ARTWORK_CURATION_STATUS_COMPLETE())
    }

    observer.next(
      CurateActions.UPDATING_CURATION_ARTWORK_ACTION({
        artworkId,
        actionType,
        complete: true,
      })
    )
    observer.complete()
  }
)
export const updateArtworkIsSafeEpic = observerEpicHelper(
  [Constants.UPDATE_ARTWORK_CURATION_ISSAFE],
  async ({ action, observer, store }) => {
    const { artworkId } = action.payload
    const actionType = 'curationIsSafeArtwork'
    observer.next(
      CurateActions.UPDATING_CURATION_ARTWORK_ACTION({
        artworkId,
        actionType,
      })
    )
    const { success } = await CurateApis.updateCurationArtworkIsSafe(action.payload)

    if (!success) {
      dispatchFlashMessage({
        observer,
        message: 'Problem updating the artwork safe status',
        type: 'error',
      })
    } else {
      observer.next(CurateActions.UPDATE_ARTWORK_CURATION_ISSAFE_COMPLETE(action.payload))
    }

    observer.next(
      CurateActions.UPDATING_CURATION_ARTWORK_ACTION({
        artworkId,
        actionType,
        complete: true,
      })
    )
    observer.complete()
  }
)
export const fetchCurationBookmarkEpic = observerEpicHelper(
  [Constants.FETCH_CURATION_BOOKMARK],
  async ({ action, observer, store }) => {
    const { payload, success } = await CurateApis.fetchCurationBookmark()

    if (!success) {
      dispatchFlashMessage({
        observer,
        message: 'Problem retrieving bookmark',
        type: 'error',
      })
    }

    observer.next(CurateActions.SET_CURATION_BOOKMARK(payload))
    observer.next(CurateActions.FETCH_CURATION_BOOKMARK_COMPLETE())
    observer.complete()
  }
)
export const saveCurationBookmarkEpic = observerEpicHelper(
  [Constants.SAVE_CURATION_BOOKMARK],
  async ({ action, observer, store }) => {
    const { curatorName, timestamp } = getBookmarkInfo({ ...store.value })
    const { artworkId, uploadedDate } = action.payload
    const confirmDeletion = confirm(
      `Are you sure you want to bookmark this? Previous bookmarked artwork was uploaded ${timestamp} (${curatorName}) and this artwork was uploaded ${getFormattedDateTime(
        uploadedDate
      )}. You will not be able to undo this action.`
    )

    if (confirmDeletion) {
      observer.next(
        CurateActions.UPDATING_CURATION_ARTWORK_ACTION({ ...action.payload, complete: true })
      )
      const { success } = await CurateApis.postCurationBookmark(action.payload)

      if (!success) {
        dispatchFlashMessage({
          observer,
          message: 'Problem saving bookmark',
          type: 'error',
        })
      } else {
        dispatchFlashMessage({
          observer,
          message: 'Successfully updated your bookmark',
          type: 'success',
        })
        const curatorUserId = getUserID({ ...store.value })
        observer.next(
          CurateActions.SET_CURATION_BOOKMARK({
            artworkId,
            curatorUserId,
            timestamp,
          })
        )
      }
    }

    observer.complete()
  }
)
export const fetchCuratorsEpic = observerEpicHelper(
  [Constants.FETCH_CURATORS],
  async ({ action, observer, store }) => {
    // Fetch Curation Users ( Curators )
    const curationUsers = getCurators({ ...store.value })

    if (!curationUsers.length) {
      const { success, payload } = await CurateApis.fetchCurators()

      if (!success) {
        dispatchFlashMessage({
          observer,
          message: 'Unable to load curation users',
          type: 'error',
        })
      } else {
        observer.next(CurateActions.SET_CURATORS(payload))
        observer.next(CurateActions.FETCH_CURATORS_COMPLETE())
      }
    }

    observer.complete()
  }
)
export const fetchCurationEmailFormsEpic = observerEpicHelper(
  [Constants.FETCH_CURATION_EMAIL_FORMS],
  async ({ action, observer, store }) => {
    const artworkId = getArtworkId({ ...store.value })
    const curatorId = findCuratorIdByName(action.payload)({ ...store.value })
    const { success, payload } = await CurateApis.fetchCurationArtistEmailForms({
      artworkId,
      curatorId,
    })

    if (!success || !payload) {
      dispatchFlashMessage({
        observer,
        message: 'Unable to load email form data',
        type: 'error',
      })
    } else {
      observer.next(
        CurateActions.SET_CURATE_DATA({
          emailForm: payload,
        })
      )
    }

    observer.complete()
  }
)
export const submitCurationEmailFormEpic = observerEpicHelper(
  [Constants.SEND_CURATION_EMAIL_FORM],
  async ({ action, observer, store }) => {
    const { success, payload } = await CurateApis.postCurationArtistEmailSubmit(action.payload)

    if (!success || !payload) {
      dispatchFlashMessage({
        observer,
        message: 'Unable to send email',
        type: 'error',
      })
    } else {
      dispatchFlashMessage({
        observer,
        message: 'Successfully sent email',
        type: 'success',
      })
      observer.next(CurateActions.SEND_CURATION_EMAIL_FORM_COMPLETE())
    }

    observer.complete()
  }
)
export default [
  deleteArtworkByCuratorEpic,
  deleteArtworkCurationStatusEpic,
  fetchCurationBookmarkEpic,
  fetchCuratorsEpic,
  fetchCurationEmailFormsEpic,
  saveCurationBookmarkEpic,
  submitCurationEmailFormEpic,
  updateArtworkCurationStatusEpic,
  updateArtworkIsSafeEpic,
]