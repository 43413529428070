import { observerEpicHelper } from 'epics/helpers'
import { Actions as PageActions } from 'actions/page'
import { Constants } from '../actions'
// SellOutRisk - Show a Callout for how many people are viewing/cart
// Only for Original Artwork (for-sale), 10+ Views, Buyers Only ( NO ARTIST )
export const setupSellOutRiskEpic = observerEpicHelper(
  [Constants.SELL_OUT_RISK],
  async ({ action, observer, store }): Promise<void> => {
    let type = 'none'

    // User still has a 1 in 3 chance of viewing experience
    if (Math.random() * 1 < 0.33) {
      type = 'view'
    }

    observer.next(
      PageActions.SET_DATA({
        sellOutRisk: type,
      })
    )

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'pdp_tactic_cart',
        message: type,
      })
    }

    observer.complete()
  }
)
export default [setupSellOutRiskEpic]