"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSku = parseSku;
exports.generateSku = generateSku;
function parseSku(sku) {
    const parts = sku.split('-');
    const skuParts = {};
    parts.forEach((part) => {
        let letter = part[0];
        if (!letter) {
            // Empty segment
            return;
        }
        letter = letter.toUpperCase();
        switch (letter) {
            case 'P':
                skuParts.productId = part;
                break;
            case 'U':
                skuParts.userId = part;
                break;
            case 'A':
                skuParts.artworkId = part;
                break;
            case 'L':
                skuParts.isLimited = true;
                break;
            case 'F':
                skuParts.frameId = part;
                break;
            case 'W':
                skuParts.canvasWrapId = part;
                break;
            case 'T':
                skuParts.type = part;
                break;
            default:
                break;
        }
    });
    if (typeof skuParts.isLimited === 'undefined') {
        skuParts.isLimited = false;
    }
    return skuParts; // { artworkId, canvasWrapId, frameId, productId, type, userId }
}
function generateSku(skuParts) {
    const { artworkId, canvasWrapId, frameId, isLimited, productId, type, userId } = skuParts;
    let sku = `${productId}-${userId}-${artworkId}`;
    if (type) {
        sku += `-${type}`;
    }
    if (isLimited) {
        sku += '-L';
    }
    if (frameId) {
        sku += `-${frameId}`;
    }
    if (canvasWrapId) {
        sku += `-${canvasWrapId}`;
    }
    return sku;
}
