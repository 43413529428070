import React from 'react'
import { PlainLink } from 'components/A'
import Tooltip from 'components/Tooltip'
import type { ConnectorProps as ProductsOriginalProps } from '../../../connectors/ProductsOriginal'
import ProductsOriginalConnector from '../../../connectors/ProductsOriginal'
import { ReasonsToBuyContainer, TooltipContent } from '../../styles/products'
import { InfoCircleIcon, CheckIcon } from '../../styles/icons'
import { ARTWORK, FOR_SALE } from '../../../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import TrustPilot from './TrustPilot'

type Results = React.ReactElement<React.ComponentProps<any>, any> | null
export default ProductsOriginalConnector(
  (props: ProductsOriginalProps): Results => {
    const { isAple, productStatus, productType } = props

    if (productStatus !== FOR_SALE || productType !== ARTWORK) {
      return null
    }

    return (
      <ReasonsToBuyContainer>
        <div data-type='reason-to-buy-item-container'>
          <SATypography variant={SATypographyVariant.SMALL}>
            <CheckIcon /> Shipping included
          </SATypography>
        </div>
        {!isAple && (
          <div data-type='reason-to-buy-item-container'>
            <SATypography variant={SATypographyVariant.SMALL}>
              <CheckIcon /> 14-day satisfaction guarantee
            </SATypography>
            <div style={{ marginTop: '4px' }}>
              <Tooltip
                position='bottom'
                color='#333'
                tip={
                  <PlainLink
                    href='https://support.saatchiart.com/hc/en-us/articles/205608487'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <TooltipContent color='black' row>
                      <img
                        src='//d3t95n9c6zzriw.cloudfront.net/common/7-day-money-back.png'
                        width='75'
                        height='75'
                        nopin='nopin'
                      />
                      <span>
                        We offer a 14-day satisfaction guarantee on all works purchased through
                        Saatchi Art, except for open and limited editions printed on-demand for you,
                        as well as original photography, mixed media and printmaking.
                      </span>
                    </TooltipContent>
                  </PlainLink>
                }
              >
                <InfoCircleIcon data-type='reason-to-buy' />
              </Tooltip>
            </div>
          </div>
        )}
        <div data-type='reason-to-buy-item-container'>
          <TrustPilot />
        </div>
      </ReasonsToBuyContainer>
    )
  }
)