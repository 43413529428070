/*
 * Wanted a quick way to keep reference to PREVIOUS value ( STATE || REDUCER )
 * Wild Card State because could be string, object, boolean, etc...
 */
import { useEffect, useRef } from 'react'

const usePreviousState = (value: any) => {
  const ref: Record<string, any> = useRef() // Store current value in ref

  useEffect(() => {
    ref.current = value
  }, [value])

  // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  // Return the initial value if NOT setup, effect has NOT fired
  if (typeof ref.current === 'undefined') {
    return value
  }

  return ref.current
}

export default usePreviousState