/* This is used by NAVIGATION */
import React from 'react'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'

const Modal = (props: Record<string, any>): React.ReactElement<React.ComponentProps<any>, any> => {
  const GalleryModal = dynamic(
    import(
      /* webpackChunkName: "artwork" */
      './Modal'
    ),
    noSSR
  )
  return <GalleryModal {...props} />
}

const Component = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { showModal } = props

  if (!showModal) {
    return null
  }

  return <Modal {...props} />
}

export default Component