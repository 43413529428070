import { createSelector } from 'reselect'
import { getPageData } from 'selectors/page'
import { replaceBadges } from 'lib/badges'
// Artist
export const getArtist = createSelector(
  [getPageData],
  (page): Record<string, any> => page.artist || {}
)
export const getArtistId = createSelector([getArtist], (artist): string => artist.userId || '')
export const getArtistInfo = createSelector(
  [getArtist],
  (artist): Record<string, any> => ({
    firstName: artist.firstName || '',
    lastName: artist.lastName || '',
    profileUrl: artist.profileUrl || '',
    userId: artist.userId || '',
  })
)
export const getArtistName = createSelector(
  [getArtistInfo],
  ({ firstName, lastName }): string => `${firstName} ${lastName}`
)
export const getArtistCountry = createSelector(
  [getArtist],
  (artist): string => artist.countryName || ''
)
export const getArtistAbout = createSelector([getArtist], (artist): string => artist.about || '')
export const getArtistAvatar = createSelector(
  [getArtist],
  (artist): string => artist.avatarUrl || ''
)
export const getArtistUserId = createSelector([getArtist], (artist): string => artist.userId || '')
export const getArtistProfileUrl = createSelector(
  [getArtist],
  (artist): string => artist.profileUrl || ''
)
export const getArtistBadges = createSelector(
  [getArtist],
  (artist): Array<Record<string, any>> => {
    const { badges } = artist
    return badges || []
  }
)
export const getArtistBadgesSorted = createSelector(
  [getArtist],
  (artist): Array<Record<string, any>> => {
    const { badgesSorted } = artist
    return badgesSorted || []
  }
)
export const getArtistBadgesList = createSelector(
  [getArtistBadges],
  (badges): Array<Record<string, any>> => replaceBadges(badges).map(({ title }) => title)
)
export const getArtistInfoTab = createSelector(
  [getArtistCountry, getArtistName, getArtistProfileUrl],
  (country, artistName, profileUrl): Record<string, any> => ({
    country,
    artistName,
    profileUrl,
  })
)