import React from 'react'
import AffirmModal from 'components/Affirm/Modal'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import type { ConnectorProps as ProductOriginalProps } from '../../../connectors/ProductsOriginal'
import ProductOriginalConnector from '../../../connectors/ProductsOriginal'
import { FOR_SALE } from '../../../helpers/constants'

const Component = (
  props: ProductOriginalProps
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { productStatus, listPrice } = props

  if (productStatus !== FOR_SALE) {
    return null
  }

  return <AffirmModal price={convertFromCentsToDollars(listPrice)} />
}

export default ProductOriginalConnector(Component)