import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import Dropdown from './Dropdown'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  border-radius: ${(props) => props.theme.borderRadius}px;
  will-change: border-color, color, background-color;
  contain: paint;
  transition: 0.3s ease border-color, 0.3s ease color, 0.3s ease background-color;
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 5px;
  padding: 10px;
  ${(props) =>
    props.selected &&
    `
    color: ${props.theme.colors.default};
    border-color: ${props.theme.colors.borderDark};
    background-color: ${props.theme.colors.borderMedium};
  `};
  :hover {
    border-color: ${(props) => lighten(0.01, props.theme.colors.borderDark)};
  }
  :focus {
    outline: none;
  }
  :last-child {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
`
type ListItem = {
  value?: string
  title: string
  note?: string
}
type PropTypes = {
  dataList: Array<ListItem>
  dataType?: string
  label: string | null | undefined
  onSelect: (...args: Array<any>) => any
  selected: ListItem | null | undefined
  selectedKey: string | null | undefined
}
export default function DropdownList(props: PropTypes) {
  const {
    dataList,
    dataType,
    label = 'Please Select',
    onSelect,
    selected = {},
    selectedKey,
  } = props

  if (!dataList.length) {
    return null
  }

  return (
    <Dropdown dataType={dataType || 'dropdown-list'} label={(selected && selected.title) || label}>
      {dataList.map((listItem, key) => {
        const isSelected = listItem.value === (selected || {})[selectedKey || 'value']
        return (
          <Item
            key={key}
            role='button'
            tabIndex={0}
            onClick={() => onSelect(listItem)}
            {...(isSelected
              ? {
                  'data-selected': true,
                  selected: true,
                }
              : {})}
          >
            <SATypography variant={SATypographyVariant.SMALL}>
              {listItem.title}
            </SATypography>
            {listItem.note &&
              <SATypography variant={SATypographyVariant.X_SMALL}>
                {listItem.note}
              </SATypography>
            }
          </Item>
        )
      })}
    </Dropdown>
  )
}