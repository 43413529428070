import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getScreenWidth } from 'selectors/screen'
import {
  getArtistName,
  getArtistCountry,
  getArtistAbout,
  getArtistProfileUrl,
  getArtistAvatar,
  getArtistBadgesSorted,
} from '../selectors/artist'
import { getArtworkId } from '../selectors/artwork'
const mapStateToProps = createStructuredSelector({
  artistAvatar: getArtistAvatar,
  artistAbout: getArtistAbout,
  artistCountry: getArtistCountry,
  artistName: getArtistName,
  artistProfileURL: getArtistProfileUrl,
  artworkID: getArtworkId,
  badges: getArtistBadgesSorted,
  screenWidth: getScreenWidth,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  artistAvatar: string
  artistAbout: string
  artistCountry: string
  artistName: string
  artistProfileURL: string
  artworkID: number
  badges: Array<Record<string, any>>
  screenWidth: number
}
export default connect(mapStateToProps, mapDispatchToProps)