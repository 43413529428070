import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { CloseIcon } from './icons'
export { CloseIcon }
export const SellOutRiskWrapper = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 5px;
  opacity: 0;
  transition: 0.8s ease opacity;
  cursor: default;
  > * {
    color: ${(props) => props.theme.colors.white};
  }
  p {
    margin-left: 7px;
    flex: 1 0 auto;
    padding-left: 8px;
  }
  strong {
    font-weight: bold;
  }
  ${(props) => props.fadeIn && `opacity: 1;`}
  ${CloseIcon} {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`
export const SellOutRiskFloating = styled.div`
  position: fixed;
  bottom: 20px;
  right: 50px;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${SellOutRiskWrapper} {
    position: relative;
    margin: 0;
    max-width: 400px;
    opacity: 1;
    padding: 30px;
  }
  @media (max-width: 1024px) {
    right: 0;
  }
`