import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import Viewing from 'svg/viewing.svg'
import CloseCircle from 'svg/close-circle.svg'
import Cart from 'svg/cart-added.svg'
import Minus from 'svg/minus.svg'
import Plus from 'svg/plus.svg'
import CaretDown from 'svg/caret-down.svg'
import ViewInARoom from 'svg/view-in-a-room.svg'
import FullScreen from 'svg/fullscreen.svg'
import ViewInRoomAR from 'svg/vir-2-0-button-icon.svg'
import Star from 'svg/star-full.svg'
import Comment from 'svg/comment.svg'
import InfoCircle from 'svg/info-circle.svg'
import Binoculars from 'svg/binoculars.svg'
import ArrowLeft from 'svg/arrow-left.svg'
import Heart from 'svg/heart-solid.svg'
import Check from 'svg/tick.svg'
export const CheckIcon = styled(Check)`
  width: 16px;
  height: 16px;
  top: 0.15em;
  position: relative;
`
export const HeartIcon = styled(Heart)`
  width: 16px;
  height: 16px;
`
export const ArrowLeftIcon = styled(ArrowLeft)`
  width: 16px;
  height: 16px;
  fill: #fff;
`
export const InfoCircleIcon = styled(InfoCircle)`
  width: 18px;
  height: 18px;
  margin-left: 5px;
  display: inline;
  position: relative;
  top: 0.15em;
`
export const CommentIcon = styled(Comment)`
  width: 15px;
  height: 15px;
`
export const BinocularsIcon = styled(Binoculars)`
  width: 19px;
  height: 19px;
`
export const ViewIcon = styled(Viewing)`
  width: 18px;
  height: 18px;
`
export const CartIcon = styled(Cart)`
  width: 18px;
  height: 18px;
`
export const CloseIcon = styled(CloseCircle)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  align-self: flex-start;
  justify-self: flex-end;
  circle {
    fill: none;
    stroke: ${(props) => props.theme.colors.gray};
  }
  path {
    stroke: ${(props) => props.theme.colors.gray};
  }
`
export const CaretIcon = styled(CaretDown)`
  width: 15px;
  padding: ${(props) => props.theme.space[1]}px 0;
  cursor: pointer;
  ${(props) => props.up && 'transform: rotate(180deg)'};
  ${(props) => props.left && 'transform: rotate(90deg)'};
  ${(props) => props.right && 'transform: rotate(-90deg)'};
  path {
    stroke: ${(props) => props.theme.colors.lightGray};
  }
`
export const PlusIcon = styled(Plus)`
  width: 15px;
  height: 15px;
  cursor: pointer;
`
export const MinusIcon = styled(Minus)`
  width: 15px;
  height: 15px;
  cursor: pointer;
`
export const Close = styled(Plus)`
  width: 15px;
  transform: rotate(45deg);
  cursor: pointer;
  transition: 0.3s ease opacity;
  &:hover {
    opacity: 0.5;
  }
`
export const ViewInARoomIcon = styled(ViewInARoom)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  > * {
    fill: #fff;
  }
`
export const ViewInRoomARIcon = styled(ViewInRoomAR)`
  margin-left: 12px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  > * {
    fill: #fff;
  }
`
export const FullScreenIcon = styled(FullScreen)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  > * {
    fill: white;
  }
`
export const StarIcon = styled(Star)`
  width: 16px;
  height: 18px;
`
