import React from 'react'
import type { ConnectorProps as ProductInfoProps } from '../../connectors/ProductsMetaInfo'
import ProductInfoConnector from '../../connectors/ProductsMetaInfo'
import MetaSegment from './MetaSegment'
import { MetaInfo } from '../styles/products'
export type MetaInfoProps = {
  inGallery?: boolean
} & ProductInfoProps

const MetaInfoComponent = (
  props: MetaInfoProps
) => {
  const { abTest, artworkId, inGallery, likes, views } = props
  return (
    <MetaInfo data-meta data-context={inGallery ? 'gallery' : null}>
      <MetaSegment type='Views' count={views} abTest={abTest} />
      <MetaSegment type='Likes' artworkId={artworkId} count={likes} abTest={abTest} />
    </MetaInfo>
  )
}

export default ProductInfoConnector(MetaInfoComponent)