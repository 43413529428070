import { createSelector } from 'reselect'
import { convertSizeForClient } from 'components/MeasurementUnit/helper'
import { getScreenOrientation, getScreenWidth, getScreenHeight } from 'selectors/screen'
import { getIsMobileTraffic } from 'selectors/shared/mobileTraffic'
import {
  getArtworkAdditionalImages,
  getArtworkCategory,
  getArtworkDimensions,
  getArtworkImage,
} from './artwork'
import type { SelectedFrameOption } from './product'
import {
  getCurrentProductType,
  getCurrentTab,
  getSelectedProductStatus,
  getSelectedProduct,
  getSelectedFrameOption,
} from './product'
import {
  ARTWORK,
  PRINT,
  PAPER_PRINT_FRAME_IMAGE,
  CANVAS_WRAP_FRAME_IMAGE_WHITE,
  CANVAS_WRAP_FRAME_IMAGE_BLACK,
  ACRYLIC_FRAME_IMAGE,
  FINE_ART_PAPER,
  CANVAS,
  ACRYLIC,
  METAL,
  LIMITED_AUTHENTICITY_IMAGE,
  PAPER_PRINT_FRAME_BACKING_IMAGE,
  CANVAS_WRAP_FRAME_BACKING_IMAGE,
  ACRYLIC_FRAME_BACKING_IMAGE,
  PAPER_PRINT_SPECS_IMAGE,
  CANVAS_WRAP_PRINT_SPECS_IMAGE,
  ACRYLIC_SPECS_IMAGE,
  METAL_FRAME_IMAGE,
  METAL_FRAME_BACKING_IMAGE,
  METAL_SPECS_IMAGE,
} from '../helpers/constants'
const viewRoomData = {
  thumbnailUrl: 'https://d3t95n9c6zzriw.cloudfront.net/static/img/view_in_a_room_thumbs.jpg',
  viewInARoomImage: true,
}
export const getSelectedPrintMaterial = createSelector(
  [getSelectedProduct],
  (product): string => product.material || ''
)
export const getPrintSpecImages = createSelector(
  [getSelectedPrintMaterial, getSelectedFrameOption],
  (material: string, selectedFrameOption: SelectedFrameOption): Array<Record<string, any>> => {
    switch (material) {
      case FINE_ART_PAPER:
        return [PAPER_PRINT_FRAME_IMAGE, PAPER_PRINT_FRAME_BACKING_IMAGE, PAPER_PRINT_SPECS_IMAGE]

      case CANVAS:
        return [
          selectedFrameOption?.title === 'White'
            ? CANVAS_WRAP_FRAME_IMAGE_WHITE
            : CANVAS_WRAP_FRAME_IMAGE_BLACK,
          CANVAS_WRAP_FRAME_BACKING_IMAGE,
          CANVAS_WRAP_PRINT_SPECS_IMAGE,
        ]

      case ACRYLIC:
        return [ACRYLIC_FRAME_IMAGE, ACRYLIC_FRAME_BACKING_IMAGE, ACRYLIC_SPECS_IMAGE]

      case METAL:
        return [METAL_FRAME_IMAGE, METAL_FRAME_BACKING_IMAGE, METAL_SPECS_IMAGE]

      default:
        return [PAPER_PRINT_FRAME_IMAGE, PAPER_PRINT_FRAME_BACKING_IMAGE, PAPER_PRINT_SPECS_IMAGE]
    }
  }
)

const getPrintImages = (
  isLimited: boolean,
  printSpecImages: Array<Record<string, any>>
): Array<Record<string, any>> => {
  if (isLimited) {
    printSpecImages.splice(1, 0, LIMITED_AUTHENTICITY_IMAGE)
  }

  return printSpecImages
}

const addFullscreenImage = (data: Record<string, any>): Record<string, any> => {
  if (data.type === 'open') {
    // Print Crop Fullscreen Image
    data.fullscreenUrl = (data.imageUrl || '').replace('-32.jpg', '-33.jpg')
  }

  data.fullscreenUrl = (data.imageUrl || '').replace(/-(6|7).jpg/, '-8.jpg')
  return data
}

export const getIsViewInRoomAvailable = createSelector(
  [getArtworkCategory, getArtworkDimensions],
  (category, { width, height }): boolean => {
    // We need to take CM and convert to IN
    const widthIN = convertSizeForClient(width)
    const heightIN = convertSizeForClient(height)
    return category.search(/sculpture|installation/i) === -1 && widthIN < 133 && heightIN < 133
  }
)
export const getGalleryImages = createSelector(
  [
    getArtworkImage,
    getArtworkAdditionalImages,
    getIsViewInRoomAvailable,
    getCurrentProductType,
    getCurrentTab,
    getIsMobileTraffic,
    getSelectedProductStatus,
    getPrintSpecImages,
  ],
  (
    artworkImage,
    additionalImages,
    isViewInRoomAvailable,
    productType,
    currentTab,
    isMobile,
    selectedProductStatus,
    printSpecImages
  ): Array<Record<string, any>> => {
    let images = [
      {
        ...artworkImage,
        viewInARoom: true,
        type: productType, // art || print
      },
    ]

    // View in a Room - Not Available for Sculpture, Video, Installation
    if (isViewInRoomAvailable) {
      // Add View in a Room -> if PRINT TAB ? Make It First Item : Second Item
      images.push({ ...viewRoomData, ...artworkImage })
    }

    // Setup Artwork Images - FullScreen Url's
    if (productType === ARTWORK) {
      if (currentTab !== 'info' && selectedProductStatus !== 'notForSale') {
        images.push(...additionalImages)
      }
    }

    // Update Image Sizes
    images = images
      .filter(
        (data: Record<string, any>): Record<string, any> => {
          if (!data.imageUrl) {
            // check to avoid "undefined is not an object" errors
            return data
          }

          if (productType === PRINT) {
            // Print Crop Image
            data.imageUrl = data.imageUrl.replace(/-\d*.jpg$/, '-32.jpg')
          } else if (isMobile) {
            // If MOBILE Device load out smaller IMAGE
            data.imageUrl = data.imageUrl.replace(/-\d*.jpg$/, '-6.jpg')
          }

          return data
        }
      )
      .filter(addFullscreenImage)

    // Exit if Info Tab || Status = 'notForSale' , no more images Needed
    if (currentTab === 'info' || selectedProductStatus === 'notForSale') {
      return images
    }

    // Print images
    if (productType === PRINT) {
      images.push(...getPrintImages(currentTab === 'limited', printSpecImages))
    }

    // Finished
    return images
  }
)
export const isValidScreenOrientation = createSelector(
  [getScreenOrientation, getIsMobileTraffic],
  (screenOrientation, isMobile): string => {
    if (!isMobile) {
      return 'portrait'
    }

    return screenOrientation
  }
)
export const MAX_WIDTH_DESKTOP = 700
export const MAX_HEIGHT_DESKTOP = 800
export const MAX_WIDTH_MOBILE = 740
// We need a way to properly pass in SCREEN Size and determine the aspect ratio for the Gallery
export const getGalleryDimensions = createSelector(
  [getScreenWidth, getScreenHeight, getGalleryImages],
  (width, height, galleryImages): Record<string, any> => {
    if (width > 1024) {
      if (height < MAX_HEIGHT_DESKTOP) {
        return {
          width: MAX_WIDTH_DESKTOP,
          height: height - 100,
        }
      }

      return {
        width: MAX_WIDTH_DESKTOP,
        height: MAX_HEIGHT_DESKTOP,
      }
    } else {
      if (width > MAX_WIDTH_MOBILE) {
        return {
          width: MAX_WIDTH_MOBILE,
          height: height - 200,
        }
      }

      return {
        width: width - 30,
        height: height - 200,
      }
    }
  }
)
export const getGalleryModalDimensions = createSelector(
  [getScreenWidth, getScreenHeight],
  (width, height): Record<string, any> => {
    if (width > 1024) {
      return {
        width,
        height: height - 200,
      }
    } else {
      return {
        width: width - 20,
        height: height - 200,
      }
    }
  }
)