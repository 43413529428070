import React from 'react'
import SALink from 'components/SALink'
import Tooltip from 'components/Tooltip'
import { DropdownList } from 'components/Dropdowns'
import { getMaterialTooltip } from 'lib/artwork'
import { getPrintSizeTitle, getOptionTitle, createDropdownData } from '../../../helpers/product'

import ProductPrintConnector, {
  type ConnectorProps as ProductPrintProps,
} from '../../../connectors/ProductsPrint'

import { Row, TooltipContent } from '../../styles/products'
import { InfoCircleIcon } from '../../styles/icons'
import { CANVAS, LIMITED_EDITION } from '../../../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const PrintOptionsComponent = (
  props: ProductPrintProps
): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    printProducts,
    selectedCurrency,
    selectedCanvasWrapColorOption,
    selectedFrameOption,
    selectedProduct,
    updateProductSku,
    updateProductSkuFrameOption,
    updateProductSkuCanvasWrapColorOption,
    currentTab,
    userSizePreference,
  } = props

  if (!selectedProduct) {
    return <p>No Products Data Available</p>
  }

  const { material, isLimitedEdition, unitsProduced } = selectedProduct
  const dropdownData = createDropdownData(
    printProducts,
    selectedProduct,
    selectedCurrency,
    userSizePreference
  )
  const {
    frameOptions: frameDropdownOptions,
    canvasWrapColorOptions: canvasWrapColorDropdownOptions,
  } = dropdownData
  const materialTooltipList = getMaterialTooltip(selectedProduct.material)
  return (
    <>
      <Row data-type='option'>
        <SATypography
          data-type={currentTab === LIMITED_EDITION ? 'limited-title' : ''}
          variant={SATypographyVariant.H6}
        >
          {currentTab === LIMITED_EDITION && isLimitedEdition
            ? `Limited Edition of ${unitsProduced}`
            : 'Open Edition Prints Available:'}
        </SATypography>
        {currentTab === LIMITED_EDITION && isLimitedEdition ? (
          <Tooltip
            position='bottom'
            color='#333'
            tip={
              <TooltipContent p={1} fontSize={1}>
                <SALink href='/about-limited' target='_blank' rel='noopener noreferrer'>
                  Click here
                </SALink>{' '}
                to learn more about Saatchi Art Limited Editions
              </TooltipContent>
            }
          >
            <InfoCircleIcon />
          </Tooltip>
        ) : (
          <Tooltip
            color='#333'
            position='bottom'
            tip={
              <TooltipContent p={1} fontSize={1}>
                <SALink
                  href='https://www.saatchiart.com/stories/fine-art-prints'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  More information about Open Edition Prints
                </SALink>
              </TooltipContent>
            }
          >
            <InfoCircleIcon />
          </Tooltip>
        )}
      </Row>

      <Row data-type='option'>
        <SATypography variant={SATypographyVariant.H6}>Select a Material</SATypography>
        {materialTooltipList && materialTooltipList.length && (
          <Tooltip
            position='bottom'
            color='#333'
            tip={
              <TooltipContent p={1} fontSize={1}>
                <ul>
                  {materialTooltipList.map((materialData, key) => (
                    <li key={key}>{materialData}</li>
                  ))}
                </ul>
              </TooltipContent>
            }
          >
            <InfoCircleIcon />
          </Tooltip>
        )}
      </Row>
      <DropdownList
        dataType='dropdown-materials'
        dataList={dropdownData.materials}
        label={selectedProduct.material || 'Select a material'}
        onSelect={updateProductSku}
        selected={selectedProduct}
        selectedKey='material'
      />

      <Row data-type='option'>
        <SATypography variant={SATypographyVariant.H6}>Select a Size</SATypography>
      </Row>
      <DropdownList
        dataType='dropdown-sizes'
        dataList={dropdownData.sizes}
        label={
          getPrintSizeTitle(selectedProduct, selectedCurrency, userSizePreference) ||
          'Select a size'
        }
        onSelect={updateProductSku}
        selected={selectedProduct}
        selectedKey='sku'
      />

      {material === CANVAS && (
        <>
          <Row data-type='option'>
            <SATypography variant={SATypographyVariant.H6}>Select a Canvas Wrap</SATypography>
          </Row>

          <DropdownList
            dataType='dropdown-options'
            label='No Frame'
            dataList={canvasWrapColorDropdownOptions}
            onSelect={(optionValue: Record<string, any>): void => {
              const { value } = optionValue
              // optionValue comes back as '' when no frame is
              // selected. Set the option value to '' instead.
              updateProductSkuCanvasWrapColorOption({
                value,
              })
            }}
            selected={
              {
                ...(selectedCanvasWrapColorOption || {}),
                title:
                  selectedCanvasWrapColorOption?.id &&
                  getOptionTitle(selectedCanvasWrapColorOption, selectedCurrency),
                value: `${selectedCanvasWrapColorOption?.id || ''}`,
              } || {}
            }
            selectedKey='id'
          />
        </>
      )}

      <Row data-type='option'>
        <SATypography variant={SATypographyVariant.H6}>Add a Frame</SATypography>
        {selectedFrameOption?.extendedDescription && (
          <Tooltip
            position='bottom'
            color='#333'
            tip={
              <TooltipContent p={1} fontSize={1}>
                <ul>
                  {selectedFrameOption?.extendedDescription.split('|').map((data, key) => (
                    <li key={key}>{data}</li>
                  ))}
                </ul>
              </TooltipContent>
            }
          >
            <InfoCircleIcon />
          </Tooltip>
        )}
      </Row>

      <DropdownList
        dataType='dropdown-options'
        label='No Frame'
        dataList={frameDropdownOptions}
        onSelect={(optionValue: Record<string, any>): void => {
          const { value } = optionValue
          // optionValue comes back as '' when no frame is
          // selected. Set the option value to '' instead.
          updateProductSkuFrameOption({
            value,
          })
        }}
        selected={
          {
            ...(selectedFrameOption || {}),
            title: selectedFrameOption?.id && getOptionTitle(selectedFrameOption, selectedCurrency),
            value: `${selectedFrameOption?.id || ''}`,
          } || {}
        }
        selectedKey='value'
      />
    </>
  )
}

export default ProductPrintConnector(PrintOptionsComponent)