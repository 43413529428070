import React from 'react'
import MobileSwipe from 'components/MobileSwipe'
import usePreviousState from 'hooks/previousState'
import BackIcon from 'svg/caret-left.svg'
import NextIcon from 'svg/caret-right.svg'
import { Controls, Gallery } from './styles'
import Image from './Image'
type Props = {
  carouselHook: Record<string, any>
  clickImage: (...args: Array<any>) => any
  currentTab: string
  dimensions: Record<string, any>
  galleryRef: Record<string, any>
  images: Array<Record<string, any>>
  isAdmin: boolean
  selectedProduct: Record<string, any>
  selectedFrameOption: Record<string, any>
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    carouselHook,
    clickImage,
    currentTab,
    dimensions,
    galleryRef,
    images,
    isAdmin,
    selectedProduct,
    selectedFrameOption,
  } = props
  const previousTab = usePreviousState(currentTab)
  const { width, height } = dimensions
  const { index, setIndex, prev, next } = carouselHook
  const element = galleryRef?.current?.getBoundingClientRect?.()?.toJSON?.() || {}
  const elementWidth = element.width || 0
  const offset = elementWidth * index
  const currentImage = images[index || 0]
  // We need to handle switching between Tabs/Products ( hideAnimations )
  const disableAnimations = previousTab !== currentTab

  const getGalleryAspectRatio = () => {
    const { viewInARoomImage, type, width: imgWidth, height: imgHeight } = currentImage

    if (viewInARoomImage) {
      return {
        aspectRatio: '1',
      }
    } else if (type === 'print') {
      const smallBorder = 2 // inches for Prints width < 25

      const largeBorder = 3 // inches for Prints width >= 25

      const frameBorder = 0.625 // inches

      const frameColor = selectedFrameOption?.title
      const { width: printWidthInInches, height: printHeightInInches } = selectedProduct
      const scaleBy =
        printWidthInInches / printHeightInInches > Number(imgWidth) / Number(imgHeight)
          ? 'width'
          : 'height'
      const formatSize = Number(scaleBy === 'width' ? printWidthInInches : printHeightInInches)
      const matBorder = printWidthInInches < 18 ? smallBorder : largeBorder
      const totalSize = formatSize + matBorder * 2 + (frameColor ? frameBorder * 2 : 0)
      const inchToPixel = (scaleBy === 'width' ? Number(imgWidth) : Number(imgHeight)) / totalSize
      const matSize = inchToPixel * matBorder
      const frameSize = inchToPixel * frameBorder
      return {
        aspectRatio: `${imgWidth / (imgHeight + Math.round(matSize) + Math.round(frameSize))}`,
      }
    }

    return {
      aspectRatio: `${imgWidth / imgHeight}`,
    }
  }

  return (
    <>
      <Gallery
        data-section='gallery'
        ref={galleryRef}
        width={width}
        height={height}
        style={getGalleryAspectRatio()}
        {...(disableAnimations
          ? {
              disableAnimations,
            }
          : null)}
        {...(isAdmin
          ? {
              isAdmin,
            }
          : null)}
      >
        <MobileSwipe
          minimumDragDistance={50}
          onSwipeComplete={(direction) => {
            direction === 'next' ? next() : prev()
          }}
          onClick={clickImage}
        />

        <div
          data-section='items'
          style={{
            transform: `translateX(-${offset}px)`,
          }}
        >
          {images.map((image, key) => {
            return (
              <button
                type='button'
                key={key}
                data-type='item'
                onClick={clickImage}
                {...(index === key
                  ? {
                      'data-style': 'active',
                    }
                  : {})}
              >
                <Image {...{ ...image, key, galleryRef }} hasHighFetchPriority={key === 0} />
              </button>
            )
          })}
        </div>
      </Gallery>

      {images.length > 1 && (
        <Controls data-section='controls' height={height}>
          <BackIcon data-type='back' onClick={prev} />

          <div data-section='dots'>
            {images.map((data, key) => (
              <button
                data-type='dot'
                type='button'
                key={key}
                onClick={() => setIndex(key)}
                {...(index === key
                  ? {
                      'data-style': 'active',
                    }
                  : {})}
              />
            ))}
          </div>

          <NextIcon data-type='next' onClick={next} />
        </Controls>
      )}
    </>
  )
}

export default Component