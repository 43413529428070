"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLegacyFormattedDateTime = exports.getFormattedDateTime = exports.getFormattedDate = exports.getLocaleDateStringFromSeconds = exports.parsedTimestampDate = void 0;
// Helpers to easily convert/set PHP timestamp to javasript
const i18_1 = require("lib/i18");
const defaults = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
};
const offset = 1000;
const parsedTimestampDate = (timestamp) => new Date(Number(timestamp) * offset);
exports.parsedTimestampDate = parsedTimestampDate;
const getLocaleDateStringFromSeconds = (dateInSeconds) => (0, exports.parsedTimestampDate)(dateInSeconds).toLocaleDateString();
exports.getLocaleDateStringFromSeconds = getLocaleDateStringFromSeconds;
// Result => "4/24/2019"
const getFormattedDate = (timestamp) => (0, exports.parsedTimestampDate)(timestamp).toLocaleDateString(i18_1.defaultLocale);
exports.getFormattedDate = getFormattedDate;
// Result => "4/24/2019, 2:19pm"
const getFormattedDateTime = (timestamp, format) => (0, exports.parsedTimestampDate)(timestamp)
    .toLocaleString(i18_1.defaultLocale, Object.assign(Object.assign({}, defaults), format))
    .replace(/(AM)/, 'am')
    .replace(/(PM)/, 'pm')
    .replace(/([\s|/]\d{4})(,)/, `$1`);
exports.getFormattedDateTime = getFormattedDateTime;
// Search ` 2021,` => ` 2021`
// Legacy API Response => '2019-03-21T22:39:50.000Z' => 'March 21, 2019'
const getLegacyFormattedDateTime = (timestamp, format) => new Date(timestamp).toLocaleString(i18_1.defaultLocale, Object.assign(Object.assign({}, defaults), format));
exports.getLegacyFormattedDateTime = getLegacyFormattedDateTime;
