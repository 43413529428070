import { createSelector } from 'reselect'
import { objectToQueryString } from 'lib/helpers'
import { ORIGINAL } from '../helpers/constants'
import { getArtistName } from './artist'
import {
  getArtworkCategory,
  getArtworkImageUrl,
  getArtworkTitle,
  getArtworkDimensions,
} from './artwork'
import { getSelectedProductPrice, getSelectedProductSku, getSelectedProductType } from './product'
export const getARExperienceAvailable = createSelector(
  [getArtworkCategory, getSelectedProductType],
  (category, productType): boolean => {
    return category.search(/sculpture|installation/i) === -1 && productType === ORIGINAL
  }
)

/*
// https://www.8thwall.com/saatchiart/art-preview/files -> query-url.js
let height = getParameterByName('h') || 71
let width = getParameterByName('w') || 144
let depth = getParameterByName('d') || 3
if(depth === 0) { depth = 0.5 } // Ensure that the box does not have zero thickness
let imageURL = getParameterByName('i') || './assets/gui/close.png'
let title = getParameterByName('t') || 'Art Title'
let sku = getParameterByName('s') || 'P1-U503607-A6639431-T1'
let lockable = getParameterByName('l') || 0
console.log(`lockable: ${lockable}`)
let price = getParameterByName('p') || 2000
let artist = getParameterByName('a') || 'Artist Name'
*/
export const getARExperienceLink = createSelector(
  [
    getArtistName,
    getArtworkImageUrl,
    getArtworkTitle,
    getArtworkDimensions,
    getSelectedProductPrice,
    getSelectedProductSku,
  ],
  (artistName, imageURL, title, { width, height, depth }, price, sku): Record<string, any> => {
    const queryObject = {
      a: artistName,
      d: depth,
      h: height,
      i: imageURL.replace(/\.(qa|local)/, ''),
      // HACK/TODO - QA has CORS issues so come back
      l: 0,
      // Locking Option in 8th Wall - HARDCODE
      p: price,
      s: sku,
      t: title,
      w: width,
    }
    return `https://ar.saatchiart.com/art-preview?${objectToQueryString(queryObject)}`
  }
)