import React, { useRef } from 'react'
import useOnScreen from 'hooks/onScreen'
import { LocaleCurrency as LocaleCurrencyComponent, USD } from 'components/FormatPrice'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import type { ConnectorProps as StickyCartProps } from '../../connectors/ProductStickyCart'
import StickyCartConnector from '../../connectors/ProductStickyCart'
import { StickyCart, Text, StickyAddToCartButton, ColumnStickyCart } from '../styles/products'
import { FOR_SALE, ORIGINAL } from '../../helpers/constants'
import MAO from './Original/MAO'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const Component = (
  props: StickyCartProps
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    artistName,
    artworkImage,
    // artworkImageAspectRatio,
    artworkTitle,
    checkMobileScreen,
    clickAddToCart,
    clickMakeAnOffer,
    currentTab,
    productPrice,
    productStatus,
    totalPriceInCentsOfSelectedOptions,
    sku,
    updateDataLayer,
  } = props
  const stickyRef: Record<string, any> = useRef()
  const { isSetup, isViewable, isFeatureAvailable } = useOnScreen(stickyRef)

  // Not a MODERN Browser ( iOS/Safari issue ) - do NOT render
  if (!isFeatureAvailable) {
    return null
  }

  // We need to Figure out the Position of REF on the Page to check if we have scrolled PAST
  const scrollBelowRef: boolean =
    stickyRef.current && stickyRef.current.getBoundingClientRect
      ? stickyRef.current.getBoundingClientRect().y < 0
      : false
  // We want a way to Show component when Mobile Screen, Passed Object, Below Component
  const isVisible = isSetup && !isViewable && checkMobileScreen && scrollBelowRef
  const thumbnailUrlBase = artworkImage.replace('-7.jpg', '')

  // // We need to use a smaller Image but want to keep the Aspect Ratio
  // // Let solve that by setting up the values
  // const [WIDTH, HEIGHT] = [60, 60] // Always STATIC
  // let width = HEIGHT * artworkImageAspectRatio // Dynamic Value
  // let height = HEIGHT
  // // We always want to constrain 60 x 60 and resize, nothing can be larger then 60
  // if (width > WIDTH) {
  //   width = WIDTH
  //   height = WIDTH / artworkImageAspectRatio
  // }
  // Hide if NO product or Not for Sale (reserved, sold, etc..)
  if (currentTab === ORIGINAL && productStatus !== FOR_SALE) {
    return null
  }

  return (
    <>
      <Text ref={stickyRef} data-type='sticky-text' />

      <StickyCart isVisible={isVisible} mobileWidth={770} data-section='sticky-cart'>
        <div data-type='sticky-image-text-container'>
          <img
            alt='product'
            className='lazyload'
            loading='lazy'
            data-type='image'
            src={`${thumbnailUrlBase}-22.jpg`}
            itemProp='image'
            width='60'
            height='60'
          />

          <ColumnStickyCart data-section='info'>
            <SATypography truncateText data-section='title' variant={SATypographyVariant.X_SMALL}>{artworkTitle}</SATypography>
            <SATypography truncateText data-section='artist' variant={SATypographyVariant.X_SMALL}>{artistName}</SATypography>

            <SATypography truncateText data-section='price' variant={SATypographyVariant.X_SMALL}>
              <LocaleCurrencyComponent
                price={convertFromCentsToDollars(productPrice + totalPriceInCentsOfSelectedOptions)}
              />
              <USD />
            </SATypography>
          </ColumnStickyCart>
        </div>
        <div data-type='sticky-actions'>
          <StickyAddToCartButton
            data-button='add-to-cart'
            onClick={(ev: React.SyntheticEvent<HTMLLinkElement>) => {
              ev.preventDefault()
              clickAddToCart(sku)
            }}
            title='Add to Cart'
          >
            <SATypography variant={SATypographyVariant.CTA_MEDIUM}>
              Add to Cart
            </SATypography>
          </StickyAddToCartButton>

          {productStatus === FOR_SALE && (
            <MAO
              onClick={clickMakeAnOffer}
              sku={sku}
              updateDataLayer={updateDataLayer}
              isSticky={isVisible}
            />
          )}
        </div>
      </StickyCart>
    </>
  )
}

export default StickyCartConnector(Component)