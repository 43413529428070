import { createSelector } from 'reselect'
import type { Curate } from 'types/Ops/Curate'
import 'types/Ops/Curate'
import { getUserID } from 'selectors/user/session'
import { getFormattedDateTime, getLegacyFormattedDateTime } from 'lib/date'
import { getOps } from './helpers'
export const getCurate = createSelector([getOps], (ops): Curate => ops.curate || {})
export const getIsUpdating = createSelector(
  [getCurate],
  (curate): boolean => curate.isUpdating || false
)
export const getArtworkId = createSelector([getCurate], (curate): string => curate.artworkId || '')
export const getCurators = createSelector(
  [getCurate],
  (curate): Array<Record<string, any>> => curate.curators || []
)
export const findCuratorIdByName = (name: string) =>
  createSelector([getCurators], (curators): number => {
    const curatorData = curators.find((curator) => curator.fullName === name)
    return curatorData ? curatorData.userId : 0
  })
export const findCuratorNameById = (curatorId: string) =>
  createSelector([getCurators], (curators): string => {
    const curatorData = curators.find((curator) => curator.userId === curatorId)
    return curatorData ? curatorData.fullName : 'Unknown'
  })
export const getCurationHistory = createSelector(
  [getCurate],
  (curate): Array<Record<string, any>> => curate.curationHistory || []
)
export const getCurationHistoryFormatted = createSelector(
  [getCurationHistory, getCurators, getUserID],
  (curationHistory, curators, currentUserId): Array<Record<string, any>> =>
    curationHistory.map((history) => {
      const { curatorUserId } = history
      const curatorData = curators.find((curator) => curator.userId === curatorUserId)
      history.fullName = curatorData
        ? `${currentUserId === curatorUserId ? 'You' : curatorData.fullName}`
        : curatorUserId
      history.date = getLegacyFormattedDateTime(history.date, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      return history
    })
)
export const getMyCurationVote = createSelector(
  [getCurationHistory, getUserID],
  (curationHistory, currentUserId): number => {
    const myVote = // clone & reverse order to FIND Last change
      [...curationHistory].reverse().find(({ curatorUserId }) => curatorUserId === currentUserId) ||
      {}
    return myVote.vote || 0
  }
)
export const getStatus = createSelector(
  [getCurate],
  (curate): Record<string, any> => curate.status || {}
)
export const getStatusByArtworkid = (artworkId: string) =>
  createSelector([getStatus], (status): string => status[artworkId] || 'none')
export const getBookmark = createSelector(
  [getCurate],
  (curate): Record<string, any> => curate.bookmark || {}
)
export const getBookmarkInfo = createSelector(
  [getBookmark, getCurators],
  (bookmark, curators): Record<string, any> => {
    const curatorData = curators.find((curator) => curator.userId === bookmark.curatorUserId)
    return {
      curatorName: curatorData ? curatorData.fullName : `'Unknown'`,
      timestamp: bookmark.timestamp ? getFormattedDateTime(bookmark.timestamp) : 'Unknown',
    }
  }
)
export const getBookmarkArtworkId = createSelector(
  [getBookmark],
  (bookmark): number => bookmark.artworkId || 0
)
export const getCuratorOptions = createSelector(
  [getCurators],
  (curators): Array<Record<string, any>> => curators.map((curator) => curator.fullName || '--')
)
export const getEmailForm = createSelector(
  [getCurate],
  (curate): Record<string, any> => curate.emailForm || {}
)
export const getEmailFormArtistEmail = createSelector(
  [getEmailForm],
  (form): string => form.email || ''
)
export const getEmailFormArtistName = createSelector(
  [getEmailForm],
  (form): string => form.artistEmail || ''
)
export const getEmailFormOptions = createSelector(
  [getEmailForm],
  (form): Array<string> => form.options || []
)
export const getEmailFormTemplates = createSelector(
  [getEmailForm],
  (form): Array<Record<string, any>> => form.templates || []
)
// 2 parts check if Current User Exist in LIST else use Default
export const getEmailFormDefaultCurator = createSelector(
  [getCurators, getUserID],
  (curators, userId): string => {
    const userIdName = curators.find((curator) => curator.userId === userId) || ''
    const defaultName = curators.find((curator) => curator.isDefault) || ''
    return (userIdName || defaultName || {}).fullName || ''
  }
)
export const getUpdatingArtworks = createSelector(
  [getCurate],
  (curate): Array<Record<string, any>> => curate.updatingArtworks || {}
)
export const getUpdatingArtworksById = (artworkId: number) =>
  createSelector(
    [getUpdatingArtworks],
    (updatingArtworks): string => updatingArtworks[artworkId] || ''
  )