import styled from 'styled-components'
import { space, fontSize, display } from 'styled-system'
export const BreadcrumbContainer = styled.nav`
  ${space};
  ${fontSize};
  ${display};

  > *,
  > * > * {
    margin-right: ${(props) => props.theme.space[2]}px;
    display: inline-block;
    vertical-align: middle;

    :last-child {
      margin-right: 0;
    }
  }
`
BreadcrumbContainer.defaultProps = {
  py: 2,
  fontSize: 1,
}
export default BreadcrumbContainer
