import React from 'react'
import type { ConnectorProps as ProductInfoProps } from '../../connectors/ProductsMetaInfo'
import ProductInfoConnector from '../../connectors/ProductsMetaInfo'
import { VisiiLink } from '../styles/products'
import { BinocularsIcon } from '../styles/icons'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const VisiiPDP = (props: ProductInfoProps) => {
  const { artworkId, isSessionSet, isVisiiAvailable, isArtist } = props

  if (!isSessionSet || isArtist || !isVisiiAvailable || !artworkId) {
    return null
  }

  return (
    <VisiiLink
      onClick={() => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'see more like this',
          })
        }
      }}
      href={`/vsearch?selected=${artworkId}&source=product_page_smlt_btn`}
      title='See more artworks like this'
    >
      <BinocularsIcon />
        <SATypography data-type='text' variant={SATypographyVariant.CTA_MEDIUM} bold>
          See More Like This
        </SATypography>
    </VisiiLink>
  )
}

export default ProductInfoConnector(VisiiPDP)