import { createSelector } from 'reselect'
import { getPageParams, getPageData } from 'selectors/page'
export { getPageParams, getPageData }
export const getCurationStatus = createSelector(
  [getPageData],
  (pageData): number => pageData.curationStatus || 0
)
export const getManageArtworkOpen = createSelector(
  [getPageData],
  (pageData): boolean => pageData.manageArtworkOpen || false
)
export const getIsVisiiAvailable = createSelector(
  [getPageData],
  (pageData): boolean => pageData.visiiExist || false
)
export const getVisiiMetadata = createSelector(
  [getPageData],
  (pageData): Record<string, any> | null | undefined => pageData.visiiMetadata
)