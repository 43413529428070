import React, { useRef } from 'react'
import Collapsable from 'components/Animations/Collapsable'
import useOnScreen from 'hooks/onScreen'
import { Wrapper, ChevronIcon } from '../styles/artworkDescriptionSection/aboutArtwork'
import { accordionScroll } from '../../helpers'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  children: React.ReactElement<React.ComponentProps<any>, any>
  headerChildren?: React.ReactElement<React.ComponentProps<any>, any>
  title: string
  showContent: boolean
  setShowContent: (...args: Array<any>) => any
  screenWidth: number
  dataType?: string
}
export default (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    children,
    headerChildren,
    title,
    showContent,
    setShowContent,
    screenWidth,
    dataType,
  } = props
  const accordionRef: Record<string, any> = useRef(null)
  const accordionTitleRef: Record<string, any> = useRef(null)
  const { isSetup, isViewable, isFeatureAvailable } = useOnScreen(accordionTitleRef)

  const toggleAccordion = (event) => {
    // 237 -> accounts for promoBar, nav, sticky cart
    const scrollBelowRef: boolean =
      accordionTitleRef.current && accordionTitleRef.current.getBoundingClientRect
        ? accordionTitleRef.current.getBoundingClientRect().y < 237
        : false
    const isScrollMobile =
      isFeatureAvailable &&
      isSetup &&
      showContent &&
      (!isViewable || (isViewable && scrollBelowRef))
    const isScrollDesktop = isFeatureAvailable && isSetup && !isViewable && showContent
    setShowContent(!showContent)

    if (screenWidth < 1024) {
      if (isScrollMobile) {
        // mobileOffset = 135 -> accounts for promoBar, nav, sticky cart
        // desktopOffset = 30 -> leave some height above ref while scrolling
        accordionScroll(accordionRef, screenWidth, 135, 30)
      }
    } else if (isScrollDesktop) {
      accordionScroll(accordionRef, screenWidth, 135, 30)
    }
  }

  return (
    <Wrapper data-type={dataType}>
      <div data-type='accordion-section'>
        <div data-type='accordion' ref={accordionRef}>
          <div data-type='title-col'>
            <SATypography
              data-type='title-text'
              ref={accordionTitleRef}
              variant={SATypographyVariant.H6}
              bold
            >
              {title}
            </SATypography>
          </div>
          <div data-type='data-row'>{headerChildren}</div>
        </div>
        <Collapsable open={showContent} animateIn duration='.6'>
          <div data-type='accordion-content'>{children}</div>
        </Collapsable>
      </div>
      <button
        type='button'
        data-type='accordion-mobile'
        aria-label={`${title} toggle`}
        onClick={(event) => toggleAccordion(event)}
      >
        <ChevronIcon data-type={showContent ? 'rotate-chevron' : ''} />
      </button>
    </Wrapper>
  )
}
