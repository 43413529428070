import React from 'react'
import {
  ArtworkInfo,
  Badges,
  MetaInfo,
  Original,
  Print,
  ReasonsToBuy,
  SellOutRiskInline,
  StickyCart,
  TabLinks,
  Visii,
} from '../components/Products'
import { Products, Content } from '../components/styles/products'
export default (): React.ReactElement<React.ComponentProps<any>, any> => (
  <Products>
    <TabLinks />

    <Content>
      <ArtworkInfo />
      <Original />
      <Print />
      <SellOutRiskInline />
      <StickyCart />
      <ReasonsToBuy />
      <MetaInfo />
      <Badges />
      <Visii />
    </Content>
  </Products>
)