import React, { useEffect, useState } from 'react'
import type { ConnectorProps as SellOutRiskProps } from '../../../connectors/SellOutRisk'
import SellOutRisk from '../../../connectors/SellOutRisk'
import { getText } from './helpers'
import { SellOutRiskWrapper } from '../../styles/sellOutRisk'
const NONE = 'none'
export default SellOutRisk((props: SellOutRiskProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { artworkViews, sellOutRisk } = props
  const [fadeIn, toggleFadeIn] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      toggleFadeIn(true)
    }
  }, [])

  // Do NOT render -> Fails Requirements || state.hidden || abTest => !'07'
  if (sellOutRisk === NONE) {
    return null
  }

  return (
    <SellOutRiskWrapper
      {...(fadeIn
        ? {
            fadeIn,
          }
        : {})}
    >
      {getText({
        type: sellOutRisk,
        views: artworkViews,
      })}
    </SellOutRiskWrapper>
  )
})