import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { Text } from '../index'
import { CaretIcon, PlusIcon, ViewInARoomIcon } from '../icons'
export { Column, Row, Text } from '../index'
export const AddArtwork = styled.div`
  cursor: pointer;
  width: 57px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 5px;
  border: 1px solid ${(props) => props.theme.colors.black};
  ${PlusIcon} {
    width: 20px;
    height: 20px;
    margin-bottom: ${(props) => props.theme.space[1]}px;
  }
  p {
    margin: 0;
    padding: 0;
    width: 57px;
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSizes[0]}px;
    text-transform: uppercase;
  }
`
export const ViewInARoomThumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 55px;
  min-height: 55px;
  background: #333;
  ${Text} {
    font-size: 9px;
    text-align: center;
    margin: 5px 0 0;
  }
`
export const Thumb = styled.div`
  width: 60px;
  height: 60px;
  border: 3px solid #fff;
  ${(props) =>
    props.selected &&
    `
      border: 3px solid #000;
    > * {
      width: 54px;
      height: 54px;
      min-width: 54px;
      min-height: 54px;
    }
  `}
`
export const Thumbnails = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 6px;
  top: 0;
  left: -50px;
  img {
    max-width: 55px;
    max-height: 55px;
    padding: 0;
  }

  ${Thumb} + ${Thumb} {
    margin-top: 8px;
  }
  button {
    width: 50px;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    transition: 0.2s ease transform, 0.2s ease opacity;
    &:hover {
      opacity: 0.6;
      transform: scale(1.1);
    }
  }
  ${CaretIcon} {
    width: 40px;
    path {
      stroke: ${(props) => props.theme.colors.black};
      stroke-width: 0.5;
    }
  }
  ${ViewInARoomIcon} {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`