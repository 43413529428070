import React, { useState, useEffect, useRef } from 'react'
import type { ConnectorProps as ArtworkDescriptionProps } from '../../connectors/ArtworkDescription'
import ArtworkDescriptionConnector from '../../connectors/ArtworkDescription'
import AccordionComponent from './AccordionComponent'
import { LIMITED_EDITION, FRAMING_OPTIONS } from '../../helpers/constants'
import { displayEllipticalString } from '../../helpers'
export default ArtworkDescriptionConnector((props: ArtworkDescriptionProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const {
    currentTab,
    selectedPrintProduct,
    screenWidth,
    artworkID,
    artworkTitle,
    artistName,
  } = props
  const [showContent, setShowContent] = useState(false)
  const textRef: Record<string, any> = useRef(null)
  const { height, width, unitsProduced } = selectedPrintProduct
  const textWidth = textRef.current ? textRef.current.offsetWidth : 0
  const textLengthValue = (textWidth * 328) / 725
  const text =
    artworkTitle &&
    artistName &&
    `Saatchi Art’s curators have worked with artists from around the world to hand-pick every artwork we offer as Limited in order to provide you with an unmatched selection of limited edition prints. We are proud to offer "${artworkTitle}" by ${artistName}, which is available exclusively on Saatchi Art.`

  const getText = () => {
    if (!showContent) {
      if (textRef.current && text.length > textLengthValue) {
        return displayEllipticalString(text, (textWidth * 260) / 725)
      } else {
        return text
      }
    } else {
      return text
    }
  }

  useEffect(() => {
    setShowContent(false)
  }, [artworkID])

  if (currentTab !== LIMITED_EDITION) {
    return null
  }

  return (
    <>
      <div data-type='top-divider' />
      <AccordionComponent
        dataType='limited-edition-details'
        title='Edition Details'
        showContent={showContent}
        setShowContent={setShowContent}
        screenWidth={screenWidth}
        headerChildren={
          <>
            <div data-type='accordion-sub-section'>
              <p>
                <span data-type='accordion-sub-header'>Exclusive to Saatchi Art:</span>
                <span data-type='accordion-sub-value' ref={textRef}>
                  {getText()}
                </span>
              </p>
            </div>
          </>
        }
      >
        <>
          {width && height && artworkTitle && unitsProduced && (
            <div data-type='accordion-sub-section'>
              <p>
                <span data-type='accordion-sub-header'>Limited Edition Prints:</span>
                <span data-type='accordion-sub-value'>
                  {`Artworks in a limited edition have a set number of prints available for each size offered. For the ${width} x ${height} size edition, "${artworkTitle}" comes in a limited edition of only ${unitsProduced} prints. Once all ${unitsProduced} of the prints in this edition size have sold out, there will be no other prints of "${artworkTitle}" available in the size of ${height} inches high by ${width} inches wide. Limited edition prints of this artwork may be available in other sizes.`}
                </span>
              </p>
            </div>
          )}
          <div data-type='accordion-sub-section'>
            <p>
              <span data-type='accordion-sub-header'>Framing Options:</span>
              <span data-type='accordion-sub-value'>{FRAMING_OPTIONS}</span>
            </p>
          </div>
        </>
      </AccordionComponent>
    </>
  )
})