"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseProductStatusText = exports.getProductStatusTextStudio = exports.getProductStatusText = exports.getProductStatus = exports.getMaterialTooltip = exports.getPackagingTooltip = exports.getPackagingType = exports.getOriginalStatus = void 0;
exports.getOriginalStatus = {
    avail: 'For Sale',
    unavail: 'Not For Sale',
    sold: 'Sold',
};
exports.getPackagingType = {
    rolled: 'Ships in a Tube',
    flat_cardboard: 'Ships in a Box',
    flat_crate: 'Ships in a Crate',
};
exports.getPackagingTooltip = {
    rolled: 'This work will ship in a dent-resistant tube. Rolled works can easily be restretched.',
    flat_cardboard: 'This work will ship flat in a sturdy, well-protected cardboard box.',
    flat_crate: 'This work will ship in a secure wooden crate.',
};
const getMaterialTooltip = (material) => {
    let tooltipItems = [];
    switch (material) {
        case 'Fine Art':
        case 'Fine Art Paper':
            tooltipItems = [
                '100% cotton, acid-free, museum quality paper',
                'Printed with archival pigmented inks',
                'Has a 2"-3" white border with matte finish',
            ];
            break;
        case 'Photo Paper':
        case 'Photo Paper Print':
            tooltipItems = [
                'Premium glossy photo paper to provide superior color gamut and stunning image quality',
                'Printed with archival pigmented inks',
                'Has a 2"-3" white border with glossy finish',
            ];
            break;
        case 'Canvas':
            tooltipItems = [
                'Archival acid-free canvas',
                'Laminated with protective lustre surface',
                'Hand-stretched over 100% renewable solid pine stretcher bars, 1.25" thick',
            ];
            break;
        case 'Acrylic':
            tooltipItems = [
                'Photo quality prints face-mounted to plexiglass with polished edges',
                'Printed with archival pigmented inks',
                'Heavy 1/4" inch thick material with white paper backing and french cleat hanging hardware',
            ];
            break;
        case 'Metal':
            tooltipItems = [
                'State-of-the-art coating infuses images directly to aluminum to make art come alive with vibrancy and clarity',
                'The heavy, ultra-hard, scratch-resistant surface is water and moisture resistant and can be cleaned easily – just avoid direct sunlight',
                'Features a robust 3/4" inset exhibit mount frame. Hardware installation provided',
            ];
            break;
        default:
            break;
    }
    return tooltipItems;
};
exports.getMaterialTooltip = getMaterialTooltip;
const getProductStatus = (product) => {
    const { isOriginal, isAvailableForSale, isSoldOut, isReserved } = product;
    if (!isAvailableForSale && !isSoldOut && !isReserved) {
        return 'notForSale';
    }
    if (!isOriginal) {
        return 'editions';
    }
    if (isSoldOut) {
        return 'soldOut';
    }
    if (isReserved) {
        return 'reserved';
    }
    // isAvailableForSale
    return 'forSale';
};
exports.getProductStatus = getProductStatus;
// You already have the product `status` available
const getProductStatusText = (status) => {
    switch (status) {
        case 'notForSale':
            return 'Not For Sale';
        case 'editions':
            return 'Editions';
        case 'soldOut':
            return 'Sold Out';
        case 'reserved':
            return 'Reserved';
        default:
            return 'For Sale';
    }
};
exports.getProductStatusText = getProductStatusText;
// artwork status for studio
// You already have the product `status` available
const getProductStatusTextStudio = (status) => {
    switch (status) {
        case 'not-for-sale':
        case 'artist-banned':
        case 'artist_on_vacation':
        case 'delisted':
            return 'Not For Sale';
        case 'editions':
            return 'Editions';
        case 'sold-out':
            return 'Sold';
        case 'reserved':
            return 'Reserved';
        default:
            return 'For Sale';
    }
};
exports.getProductStatusTextStudio = getProductStatusTextStudio;
// You need to decode a PRODUCT object for the `status`
const parseProductStatusText = (product) => {
    const status = (0, exports.getProductStatus)(product);
    return (0, exports.getProductStatusText)(status);
};
exports.parseProductStatusText = parseProductStatusText;
