import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { Column, Row } from '../index'
import { ACRYLIC, METAL, METAL_LIGHT_PEWTER, METAL_DARK_PEWTER } from '../../../helpers/constants'
export { Column, Row, Text } from '../index'
export * from './loader'
export * from './actions'
const PAPER_FRAME_COLORS = {
  Black: `#000000`,
  White: `#f0f0f0`,
  'Natural Wood': `#efe3d2`,
  'Light Pewter': 'transparent',
  'Dark Pewter': 'transparent',
}
const CANVAS_WRAP_COLORS = {
  'Black Canvas': `#000000`,
  'White Canvas': `#f0f0f0`,
}
const CANVAS_WRAP_FRAME_COLORS = {
  Black: '#2D2D2D',
  White: '#f0f0f0',
}
const CANVAS_WRAP_GAP_COLORS_BY_FRAME_COLOR = {
  Black: 'black',
  White: '#4E4C4D',
}
export const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`
export const List = styled.ul`
  margin: 0;
  padding: 0 ${(props) => props.theme.space[4]}px;
  color: ${(props) => props.theme.colors.gray};
  li + li {
    margin-top: ${(props) => props.theme.space[1]}px;
  }
`
export const ViewInARoomContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  &[data-type='thumb'] {
    min-height: unset;
  }
  > ${Column} {
    position: absolute;
    top: ${(props) => topOffset(props.type)}%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  ${(props) =>
    props.isAbTest02 &&
    `
  @media (max-width: 1024px) {
  justify-content: center;
  display: flex;
  }`}
`

const topOffset = (val) => {
  if (val === 'xlarge') {
    return 45
  } else if (val === 'large') {
    return 32
  } else if (val === 'medium') {
    return 28
  }

  // Small
  return 38
}

export const ViewInARoomBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${(props) =>
    props.isAbTest02 &&
    `
  @media (max-width: 1024px) {
    min-height: 345px;
    max-height: 430px;
  }`}
`
export const ViewInARoomArtwork = styled.img`
  background: #ffffff;
  ${(props) =>
    !props.border &&
    `
    position: absolute;
    width: ${props.widthValue};
    top: ${topOffset(props.type)}%;
    left: ${props.type === 'xlarge' ? 55 : 50}%;
    transform: translate(-50%, -50%);
    z-index: 1;
    box-shadow: 0px 1px 5px 2px #cccccc;
  `}
  ${(props) =>
    props.isAbTest02 &&
    `
    max-width: 130px;
  `}
    &[data-type='thumb'] {
    /* height: ${(props) => (props.isVertical ? 40 : 25)}%;
    width: unset;
    max-width: 50%; */
  }
`
export const ViewInARoomPrintContainer = styled.div`
  position: absolute;
  top: ${(props) => topOffset(props.type)}%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  ${Column} {
    width: 100%;
    height: 100%;
  }
  [data-type='overlay'] {
    /* TODO - Hide Acrylic shine until prints can be fixed */
    display: none;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background: linear-gradient(
      107.26deg,
      rgba(255, 255, 255, 0.4) 28.92%,
      rgba(227, 227, 227, 0.4) 36.73%,
      rgba(217, 217, 217, 0) 44.07%
    );
  }
`
export const MobileSwipe = styled.div`
  touch-action: pan-y;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`
export const Spacer = styled.div`
  height: 1em;
  width: 100%;
`
export const TextSpacer = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
    height: 3em;
  }
`
export const CanvasWrapPrintImage = styled.img`
  border-radius: 0px 10px;
  ${(props) => `border-right: 10px solid ${CANVAS_WRAP_COLORS[props.canvasWrapColor]};
  border-bottom: 10px solid ${CANVAS_WRAP_COLORS[props.canvasWrapColor]};`}
  border-top-color: transparent;
  border-left-color: transparent;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 1px 5px 2px #cccccc;
`
export const CanvasWrapPrintImageWithFrame = styled.img`
  padding: ${(props) => props.gapSizeInPixels}px;
  background: ${(props) => CANVAS_WRAP_GAP_COLORS_BY_FRAME_COLOR[props.frameColor]};
  border: ${(props) => `${props.frameSize}px solid ${CANVAS_WRAP_FRAME_COLORS[props.frameColor]}`};
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 1px 5px 2px #cccccc;
`
export const PaperPrintImage = styled.img`
  background: white;
  padding: ${(props) => props.matSize}px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 1px 5px 2px #cccccc;
  ${(props) => `border: 15px solid ${PAPER_FRAME_COLORS[props.frameColor] || 'transparent'};
    border-width: ${props.frameSize || 0}px;
    ${
      props.frameColor
        ? `box-shadow: 0px 1px 5px 2px #cccccc, 2px 2px 2px 0px #cccccc inset;`
        : `border: 1px solid #ededed;`
    };
     ${
       props.frameColor === METAL_LIGHT_PEWTER
         ? `border-right: ${props.frameSize || 0}px solid #C0B7A5;
        border-left: ${props.frameSize || 0}px solid #C0B7A5;
        border-top: ${props.frameSize || 0}px solid #C0B7A5b2;
        border-bottom: ${props.frameSize || 0}px solid #C0B7A5b2;`
         : ``
     };
    ${
      props.frameColor === METAL_DARK_PEWTER
        ? `border-right: ${props.frameSize || 0}px solid #79766f;
          border-left: ${props.frameSize || 0}px solid #79766f;
          border-top: ${props.frameSize || 0}px solid #79766fb2;
          border-bottom: ${props.frameSize || 0}px solid #79766fb2;`
        : ``
    };
  `}
  ${(props) =>
    (props.type === ACRYLIC || props.type === METAL) &&
    `
      padding: 0;
      box-shadow: 5px 8px 8px 2px rgba(0, 0, 0, 0.25);
  `}
`
export const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ImageContainer = styled.div`
  width: 100%;
  min-height: 160px;
  max-width: 700px;
  ${(props) =>
    props.isAbTest02 &&
    `
  @media (max-width: 1024px) {
    min-height: 345px;
    max-height: 430px;
  }`}
  margin-bottom: 10px;
`
export const MainImage = styled.div`
  width: 100%;
  height: 100%;
  overflow: visible;
  > [data-type='original-image'] {
    width: 100%;
    height: auto;
    min-width: 200px;
    min-height: 200px;
    object-fit: contain;
  }
    ${(props) =>
      props.isAbTest02 &&
      `
  > [data-type='original-image'] {
    @media (max-width: 1024px) {
      min-height: 345px;
      max-height: 430px;
    }
  }
  > [data-type='print-image'] {
    @media (max-width: 1024px) {
      width: auto;
    }
    }
  `}
  ${(props) =>
    (props.type === 'art' || props.type === undefined) &&
    props.isAbTest02 &&
    `@media (max-width: 1024px) {
  display: flex;
  justify-content: center;
  }`}
  ${(props) =>
    props.type === 'print' &&
    props.isAbTest02 &&
    `@media (max-width: 1024px) {
  display: block;
  text-align: center;
  }`}
`
export const GalleryContent = styled(Row)`
  align-items: center;
  justify-content: flex-start;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-left: 0;
  }
  ${Column} {
    justify-content: center;
    align-items: center;
  }
`