import React from 'react'
import { replaceBadges } from 'lib/badges'
import type { ConnectorProps as AboutArtistProps } from '../connectors/AboutArtist'
import AboutArtistConnector from '../connectors/AboutArtist'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Results = React.ReactElement<React.ComponentProps<any>, any> | null
export default AboutArtistConnector(
  (props: AboutArtistProps): Results => {
    const { badges } = props

    if (!badges || !badges.length) {
      return null
    }

    return (
      <div data-type='artist_badges'>
        <div data-type='title-section'>
          <SATypography variant={SATypographyVariant.H6}>Artist Recognition</SATypography>
        </div>
        <div data-type='badge-row'>
          {replaceBadges(badges).map(({ description, image, title }, key) => (
            <div data-type='badge-col' key={title}>
              <img className='lazyload' data-src={image} alt={title} width='30px' height='30px' />
              <SATypography
                data-type='badge-text'
                variant={SATypographyVariant.MEDIUM}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
)