import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { PlainLink } from 'components/A'
import { BreadcrumbContainer } from 'components/Breadcrumb'
export const Breadcrumbs = styled(BreadcrumbContainer)`
  width: 90vw;
  max-width: 1060px;
  display: flex;
  justify-content: flex-start;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 0;
  margin-top: 20px;
  font-size: 13px;
  [data-type='breadcrumbUL'] {
    margin: 0;
    padding: 0;
    height: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    list-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: 700px;
    scrollbar-width: none;
    li {
      padding: 0;
      text-align: center;
      white-space: nowrap;
      margin: 0;
      a {
        color: ${(props) => props.theme.colors.secondary};
        font-weight: normal;

        :hover {
          color: ${(props) => props.theme.colors.lightGray};
        }
        &:after {
          content: '/';
          color: #333333;
          margin: 0 8px;
        }
      }
    }
    li:last-child {
      a {
        &:after {
          content: '';
        }
      }
    }
  }
  [data-type='breadcrumbUL']::-webkit-scrollbar {
    display: none;
  }
  span {
    white-space: nowrap;
  }
`
export const Link = styled(PlainLink)``
export const Span = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: bold;
`