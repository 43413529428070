import React from 'react'
import SALink from 'components/SALink'
import { slugify } from 'lib/slugify'
import type { ConnectorProps as RelatedSearchesProps } from '../connectors/RelatedSearches'
import RelatedSearchesConnector from '../connectors/RelatedSearches'
import { getLinkCategory } from '../helpers'
import { RelatedSearches } from './styles/relatedSearches'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default RelatedSearchesConnector((props: RelatedSearchesProps) => {
  const { category, keywords, productType } = props
  const reservedKeywordArray = [
    'artwork',
    'artworks',
    'digital',
    'drawing',
    'drawings',
    'painting',
    'paintings',
    'photo',
    'photograph',
    'photographs',
    'photography',
    'photos',
    'printmaking',
    'sculpture',
    'sculptures',
  ]
  const linkCategory = getLinkCategory(category)

  const getKeywordHref = (keyword) => {
    if (reservedKeywordArray.includes(slugify(keyword))) {
      return `/${slugify(linkCategory)}`
    } else if (keyword === 'print' || keyword === 'prints') {
      return `/prints`
    } else if (productType === 'print') {
      return `/prints/${slugify(linkCategory)}/${slugify(keyword)}/feature`
    } else return `/${slugify(linkCategory)}/${slugify(keyword)}/feature`
  }

  return (
    <RelatedSearches>
      <SATypography variant={SATypographyVariant.H4} data-type='related-searches-title' bold>
        Related Searches
      </SATypography>
      {keywords.length && (
        <div data-type='keyword-row'>
          {(keywords || []).map((keyword) => (
            <SALink
              key={keyword}
              href={getKeywordHref(keyword)}
              title={`${keyword} ${linkCategory}`}
            >
              <SATypography variant={SATypographyVariant.SMALL}>{keyword}</SATypography>
            </SALink>
          ))}
        </div>
      )}
    </RelatedSearches>
  )
})