import React from 'react'
import type { ConnectorProps as GalleryProps } from '../../connectors/Gallery/Thumbnails'
import GalleryThumbnailsConnector from '../../connectors/Gallery/Thumbnails'
import ARExperience from '../ARExperience/Desktop'
import { AddArtwork, Thumbnails, Thumb, ViewInARoomThumb } from '../styles/gallery/thumbnails'
import {
  PlusIcon,
  /* ViewInARoomIcon */
} from '../styles/icons'
import ViewInARoomSized from './ViewInARoomThumb'
type Props = GalleryProps & {
  clickThumbnail: (...args: Array<any>) => any
  currentItem: number
  rendered: boolean
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    canEditArtwork,
    clickAddArtworkThumbnail,
    clickThumbnail,
    currentItem,
    currentTab,
    galleryImages,
    isARExperienceAvailable,
    isClientReady,
    rendered,
    selectedProduct,
    title,
  } = props

  if (galleryImages.length <= 1 && !canEditArtwork && !isARExperienceAvailable) {
    return null
  }

  return (
    <Thumbnails data-type='thumbnails'>
      {galleryImages.map((galleryImage, index) => {
        const { description, viewInARoomImage, thumbnailUrl } = galleryImage
        return (
          <Thumb
            key={`thumb-${currentTab}-${index}`}
            index={index}
            alt={description || `view additional image ${index + 1}`}
            selected={currentItem === index}
            onClick={() => clickThumbnail(index)}
          >
            {viewInARoomImage ? (
              <ViewInARoomThumb>
                <ViewInARoomSized
                  imageUrl={galleryImages[0].imageUrl}
                  title={title}
                  selectedProduct={selectedProduct}
                  abTest=''
                  isClientReady={isClientReady}
                />
              </ViewInARoomThumb>
            ) : (
              <img
                className='lazyload'
                data-src={thumbnailUrl}
                alt={description || `view additional image ${index + 1}`}
                src={rendered ? thumbnailUrl : null}
              />
            )}
          </Thumb>
        )
      })}

      <ARExperience />

      {canEditArtwork && (
        <AddArtwork onClick={clickAddArtworkThumbnail}>
          <PlusIcon />
          <p>
            Add
            <br />
            Image
          </p>
        </AddArtwork>
      )}
    </Thumbnails>
  )
}

export default GalleryThumbnailsConnector(Component)