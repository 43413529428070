import fetch from 'isomorphic-fetch'
import { parseJSON, visiiProxyOptions } from 'apis/helpers'
import { type VisiiResponse } from 'types/Visii'

const { SITE_URL } = process.env
// Absolute URLs required ( used SSR )
const DOMAIN = SITE_URL

export async function checkVisiiIdsExistApi(artworkId: string): Promise<VisiiResponse> {
  const response = await fetch(
    `${DOMAIN}/easel_api/visii/artwork-details/${artworkId}`,
    visiiProxyOptions
  )
  const json = await parseJSON(response, 'checkVisiiIdsExistApi')
  return json
}
export async function fetchVisiiSimilarApi(
  artworkId: string,
  destinationZone?: string
): Promise<VisiiResponse> {
  const response = await fetch(
    `${DOMAIN}/easel_api/visii/similar/${artworkId}?destinationZone=${destinationZone}`,
    visiiProxyOptions
  )
  const json = await parseJSON(response, 'fetchVisiiSimilarApi')
  return json
}
export async function fetchVisiiRecommendArtistApi(artistId: string): Promise<VisiiResponse> {
  const response = await fetch(
    `${DOMAIN}/easel_api/visii/recommend?artistId=${artistId}`,
    visiiProxyOptions
  )
  const json = await parseJSON(response, 'fetchVisiiRecommendArtistApi')
  return json
}