import React from 'react'
import type { ConnectorProps as ProductsOriginalProps } from '../../../connectors/ProductsOriginal'
import ProductsOriginalConnector from '../../../connectors/ProductsOriginal'
import FairchainMessage from './FairchainMessage'
import ForSale from './ForSale'
import NotForSale from './NotForSale'
import Packaging from './Packaging'
import Reserved from './Reserved'
import SoldOut from './SoldOut'
import { BlackBox } from '../../styles/products'
import { ARTWORK } from '../../../helpers/constants'
type Results = React.ReactElement<React.ComponentProps<any>, any> | null
export default ProductsOriginalConnector(
  (props: ProductsOriginalProps): Results => {
    const { productType, productStatus, isFairchain } = props

    if (productType !== ARTWORK) {
      return null
    }

    return (
      <>
        <Packaging />

        <FairchainMessage isFairchain={isFairchain} />

        <BlackBox data-type='original' data-status={productStatus}>
          <ForSale />
          <NotForSale />
          <Reserved />
          <SoldOut />
        </BlackBox>
      </>
    )
  }
)