import React from 'react'
import type { ConnectorProps as ARExperienceProps } from '../../connectors/ARExperience'
import ARExperienceConnector from '../../connectors/ARExperience'
import { ViewInRoomARIcon, Action, VIRActionStatic } from '../styles/gallery'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = ARExperienceProps

const Mobile2 = (props: Props) => {
  const { openARExperience } = props

  return (
    <VIRActionStatic>
      <Action
        onClick={() => {
          openARExperience()
        }}
        data-type='view-in-room-AR'
        title='View this art on your wall'
      >
        <ViewInRoomARIcon />
        <SATypography variant={SATypographyVariant.CTA_MEDIUM}>View In My Room</SATypography>
      </Action>
    </VIRActionStatic>
  )
}

export default ARExperienceConnector(Mobile2)