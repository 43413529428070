import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getIsArtist, isAuthenticated, isSessionSet, getUserID } from 'selectors/user/session'
import { getFavoriteItems, getFavoriteSelected } from 'selectors/user/favorite'
import { getABTestValues } from 'selectors/user/abTest'
import { Actions as FavoriteActions } from 'actions/user/favorite'
import { Actions as FlashMessageActions } from 'actions/shared/flashMessage'
import { Actions } from '../actions'
import { getArtistBadgesSorted } from '../selectors/artist'
import { getArtworkId, getArtworkLikes, getArtworkViews } from '../selectors/artwork'
import { getIsVisiiAvailable } from '../selectors/general'
const mapStateToProps = createStructuredSelector({
  abTest: getABTestValues,
  artworkId: getArtworkId,
  badges: getArtistBadgesSorted,
  favorites: getFavoriteItems,
  isArtist: getIsArtist,
  isAuthenticated,
  isSessionSet,
  isVisiiAvailable: getIsVisiiAvailable,
  likes: getArtworkLikes,
  selectedFavorite: getFavoriteSelected,
  userId: getUserID,
  views: getArtworkViews,
})

const mapDispatchToProps = (dispatch) => ({
  clickArtistBadge: (payload: string) => dispatch(Actions.CLICK_ARTIST_BADGE(payload)),
  seeMore: () => dispatch(Actions.SEE_MORE()),
  favoriteItem: (favorite: { id: number; favorite: boolean }) => {
    dispatch(FavoriteActions.UPDATE(favorite))
  },
  addMessage: (message, type) => {
    dispatch(
      FlashMessageActions.ADD({
        messages: [message],
        type: type || 'error',
      })
    )
  },
})

export type ConnectorProps = {
  abTest: Record<string, any>
  addMessage: (...args: Array<any>) => any
  artworkId: number
  badges: Array<Record<string, any>>
  clickArtistBadge: (...args: Array<any>) => any
  favoriteItem: (...args: Array<any>) => any
  favorites: Array<number>
  handleProfileInfo: (...args: Array<any>) => any
  isArtist: boolean
  isAuthenticated: boolean
  isSessionSet: boolean
  isVisiiAvailable: boolean
  likes: number
  selectedFavorite: number
  seeMore: (...args: Array<any>) => any
  userId: number
  views: number
}
export default connect(mapStateToProps, mapDispatchToProps)