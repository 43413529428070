/*
 * Measure a Components Width & Height via REF Object
 * this was made specifically to work with <img> in PDP
 */
import { useEffect, useState } from 'react'
type State = {
  width: number | null | undefined
  height: number | null | undefined
}
const DEFAULT: State = {
  width: 1,
  height: 1,
}

const useComponentSize = (ref: Record<string, any>) => {
  const [size, handleSize] = useState(DEFAULT)

  const calculateSize = () => {
    if (ref.current) {
      const { clientWidth, clientHeight } = ref.current
      handleSize({
        width: clientWidth,
        height: clientHeight,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', calculateSize)
    calculateSize()
    return () => {
      window.removeEventListener('resize', calculateSize)
    }
  }, [ref.current])
  return { ...size, recalculate: calculateSize }
}

export default useComponentSize