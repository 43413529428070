import { getPathObject, getQueryObject } from 'lib/url/parse'
export const convertFromCentsToDollars = (price: number): number => Number(price / 100)
export const getDate = (timestamp: number): string => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const date = new Date(timestamp * 1000)
  // return date.toLocaleDateString('en-US') - Returns 1/23/18
  return `${months[date.getMonth()]} ${date.getFullYear()}`
}
export const joinArray = (arr: Array<string> | null | undefined, str: string = ''): string =>
  arr && arr.length > 0 ? `${arr.join(', ')}${str}` : ''
export const buildPath = (arr: Array<string>): string =>
  arr && arr.length > 0 ? `/${(arr || []).filter(Boolean).join('/')}` : ''
export const getProductStatus = (productData: Record<string, any>): string => {
  const {
    isOriginal: original,
    isAvailableForSale: available,
    isSoldOut: soldOut,
    isReserved: reserved,
  } = productData

  if (!available && !soldOut && !reserved) {
    return 'notForSale'
  }

  if (!original) {
    return 'editions'
  }

  if (soldOut) {
    return 'soldOut'
  }

  if (reserved) {
    return 'reserved'
  }

  // available
  return 'forSale'
}
export const getLinkCategory = (value: string): string => {
  let category

  switch (value) {
    case 'Drawing':
      category = 'Drawings'
      break

    case 'Painting':
      category = 'Paintings'
      break

    default:
      category = value
      break
  }

  return category
}
// Returns...
// a) a price (number) if convertToLocale is false
// b) a price (string) if convertToLocale is true
// c) 0.00 (string) if showOriginalOrPrintPrice is true
// ---------------------------
// showOriginalOrPrintPrice is based on original/print
// being available for sale
export const formatPrice = (
  value: string | number,
  convertToLocale: boolean,
  showOriginalOrPrintPrice: boolean = false
): number | string => {
  let price = Number(value)

  if (!price || !showOriginalOrPrintPrice) {
    return '0.00'
  } else {
    price /= 100
    return convertToLocale ? price.toLocaleString() : price
  }
}
export const getPluralizedCategory = (value: string): string => {
  if (value.search(/photography|new.+media/i) > -1) {
    return value
  } else if (value.search(/print/i) > -1) {
    return 'Prints'
  }

  return `${value}s`
}
export const getUrlParams = (href: string): Record<string, any> => ({
  ...getQueryObject(href),
  ...getPathObject(href, ['productType', 'title', 'artistId', 'artworkId']),
})
export const updateDataLayer = (gtmEventData: Record<string, any>): void => {
  window.dataLayer.push({ ...gtmEventData })
}
// function to replace long string with ... based on string length value
export const displayEllipticalString = (string: string, stringLength: number) => {
  return string.slice(0, stringLength).concat('...')
}
// function to trim string based if string has line breaks
export const displayStringWithoutLineBreak = (string: string) => {
  return string.replace(/(\r\n|\n|\r)/gm, ' ')
}
// scroll functionality for artwork description accordion
export const accordionScroll = (
  accordionRef: Record<string, any>,
  screenWidth: number,
  mobileOffset: number,
  desktopOffset: number
) => {
  screenWidth < 1024
    ? window.scroll({
        // offset accounts for promoBar, nav and sticky cart
        top: accordionRef.current.offsetTop - mobileOffset,
        left: 0,
        behavior: 'smooth',
      })
    : window.scroll({
        top: accordionRef.current.offsetTop + desktopOffset,
        left: 0,
        behavior: 'smooth',
      })
}
// uppercase first letter of word
export const toTitleCase = (s: string) => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase()
// function to slice string at 2500 char if string.length >2500, fetch string until the last full stop (end of sentence)
export const getTruncatedText = (str: string) => {
  const slicedString = str.slice(0, 2500)
  return str.length > 2500 ? slicedString.substr(0, slicedString.lastIndexOf('.') + 1) : str
}
export const getTitleCategory = (value: string): string => {
  let category

  switch (value) {
    case 'Mixed Media':
    case 'Digital':
      category = 'Artwork'
      break

    case 'Photography':
      category = 'Photograph'
      break

    case 'Printmaking':
      category = 'Print'
      break

    default:
      category = value
      break
  }

  return category
}
export const getCategoryForRecCarousel = (value: string): string => {
  if (value.search(/Sculpture|Collage|Installation/i) > -1) {
    return `${value}s`
  } else if (value.search(/print/i) > -1) {
    return 'Prints'
  } else if (value === 'All') {
    return 'Artworks'
  }

  return value
}
export const getProductSchemaCategory = (category: string, productType: string): string | null => {
  if (productType === 'print') {
    return 'Home Decor > Fine Wall Art > Artworks > Posters, Prints, & Visual Artwork > Fine Art Prints'
  }

  const productCategorySchemaMapping = {
    Sculpture: 'Home Decor > Fine Art > Artworks > Sculptures & Statues',
    Installation: 'Home Decor > Fine Art > Artworks > Sculptures & Statues > Installations',
    Painting:
      'Home Decor > Fine Wall Art > Artworks > Posters, Prints, & Visual Artwork > Paintings',
    Photography:
      'Home Decor > Fine Wall Art > Artworks > Posters, Prints, & Visual Artwork > Photographs',
    Drawing: 'Home Decor > Fine Wall Art > Artworks > Posters, Prints, & Visual Artwork > Drawings',
    Printmaking:
      'Home Decor > Fine Wall Art > Artworks > Posters, Prints, & Visual Artwork > Prints',
    Digital:
      'Home Decor > Fine Wall Art > Artworks > Posters, Prints, & Visual Artwork > Fine Art Prints',
    'Mixed Media':
      'Home Decor > Fine Wall Art > Artworks > Posters, Prints, & Visual Artwork > Mixed Media',
    Collage: 'Home Decor > Fine Wall Art > Artworks > Posters, Prints, & Visual Artwork > Collages',
  }
  return productCategorySchemaMapping[category] ?? null
}