import fetch from 'isomorphic-fetch'
import { parseJSON } from 'apis/helpers'
const { APP_ENV } = process.env
const isDev = APP_ENV === 'local'
// Helper for LOCAL uploads, `local.saatchiart.com` does NOT exist ( so point to QA )
const URL = isDev ? `/easel_api/localToQA` : ``

async function genericImageUploadAPI(file: File, url: string): Record<string, any> {
  const formData = new FormData()
  formData.append('file', file)
  const response = await fetch(url, {
    body: formData,
    method: 'POST',
  })
  const json = await parseJSON(response, 'stageImage')
  return json
}

export function stageArtworkImage(file: File): Promise<Record<string, any>> {
  return genericImageUploadAPI(file, `${URL}/art/update/stageextraimage`)
}
export function artAdvisoryImageUploader(file: File): Promise<Record<string, any>> {
  return genericImageUploadAPI(file, `${URL}/artadvisory/upload-image`)
}
export async function updateArtworkImages(params: {
  files: Array<Record<string, any>>
  artworkId: number
}): Promise<Record<string, any>> {
  const { files, artworkId } = params
  const formData = new FormData()
  formData.append('id_user_art', String(artworkId))
  files.forEach((file, index) => {
    const { s3key, description, prefix } = file

    if (file.is_staging) {
      // New
      formData.append(`extra_images[${index}][s3key]`, s3key)
      formData.append(`extra_images[${index}][is_staging]`, '1')
    } else {
      // Existing
      formData.append(`extra_images[${index}][prefix]`, prefix)
    }

    if (description) {
      // Optional
      formData.append(`extra_images[${index}][description]`, description)
    }
  })
  formData.append('extra_images_length', String(files.length))
  const response = await fetch(`${URL}/art/update/extraimages`, {
    body: formData,
    method: 'POST',
  })
  const json = await parseJSON(response, 'stageImage')
  return json
}