
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/saatchi/artdetail2",
      function () {
        return require("private-next-pages/saatchi/artdetail2.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/saatchi/artdetail2"])
      });
    }
  