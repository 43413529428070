import React, { useEffect, useReducer, useRef } from 'react'
import { useCarousel } from 'hooks/carousel'
import type { ConnectorProps as GalleryProps } from '../../connectors/Gallery'
import GalleryConnector from '../../connectors/Gallery'
import MobileARExperience2 from '../ARExperience/Mobile2'
import MetaInfo from '../Products/MetaInfo'
import Gallery from './Gallery'
import GalleryModal from './Modal'
import Thumbnails from './Thumbnails'
import { GalleryLayout } from './styles'
type State = {
  currentItem: number
  viewInARoomPosition: number
  rendered: boolean
}
const initialState: State = {
  currentItem: 0,
  viewInARoomPosition: 0,
  rendered: true,
}

const reducer = (state: State, data: Record<string, any>) => ({ ...state, ...data })

const Component = (
  props: GalleryProps
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    artworkId,
    currentTab,
    galleryDimensions,
    galleryImages,
    isAdmin,
    updateDataLayer,
    selectedProduct,
    selectedFrameOption,
  } = props
  const { material } = selectedProduct
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    eventsAvailable: false,
    // This attributes is used for datalayer ( GTM events )
    rendered: false,
    showModal: false,
  })
  const { eventsAvailable, rendered, showModal } = state
  const carouselHook = useCarousel(galleryImages.length) //  next, prev,

  const { index, setIndex } = carouselHook
  const galleryRef: Record<string, any> = useRef()
  useEffect(() => {
    setIndex(0)
  }, [material])
  useEffect(() => {
    // Prevent events firing on INITIAL load
    if (!eventsAvailable) {
      dispatch({
        eventsAvailable: true,
      }) // Enable Events

      return
    }

    // Any time the image changes we need to fire a GTM event
    const isVIR = index === 1
    updateDataLayer({
      event: isVIR ? 'multiple images - view in a room' : 'multiple images - artwork',
    })
  }, [index])
  useEffect(() => {
    // First Ever Rendering of a page - handle mobile
    if (!rendered) {
      // Lets handle VIEW IN ROOM query
      const viewInRoomQuery = window.location.search.search(/vir=/) > -1
      const viewInARoomPosition = galleryImages.findIndex((item) => item.viewInARoomImage)

      if (viewInRoomQuery) {
        setIndex(viewInARoomPosition || 0)
        updateDataLayer({
          event: 'toaf view in room query',
        })
      }
    } else {
      dispatch({
        eventsAvailable: false,
      }) // Prevent Events on NEW Product

      setIndex(0)
    }

    // Only needs to fire ONCE
    if (!rendered) {
      dispatch({
        rendered: true,
      })
    } // Update Reset Logic on SKU change
  }, [currentTab, artworkId])

  // Handle switching between TABS/PRODUCTS and new image counts
  if (!galleryImages[index]) {
    return null
  }

  return (
    <>
      <GalleryLayout data-section='gallery-content'>
        <Thumbnails
          {...{
            clickThumbnail: setIndex,
            currentItem: index,
            rendered,
          }}
        />

        <Gallery
          currentTab={currentTab}
          dimensions={galleryDimensions}
          galleryRef={galleryRef}
          images={galleryImages}
          isAdmin={isAdmin}
          carouselHook={carouselHook}
          clickImage={() => {
            if (galleryImages.length > 1) {
              dispatch({
                showModal: true,
              })
              updateDataLayer({
                event: 'view fullscreen',
              })
            }
          }}
          updateDataLayer={updateDataLayer}
          selectedProduct={selectedProduct}
          selectedFrameOption={selectedFrameOption}
        />

        <div data-section='mobile-actions'>
          <MetaInfo inGallery />

          <MobileARExperience2 />
        </div>
      </GalleryLayout>

      <GalleryModal
        closeModal={() => {
          dispatch({
            showModal: false,
          })
          updateDataLayer({
            event: 'close fullscreen',
          })
        }}
        startPosition={index}
        showModal={showModal}
      />
    </>
  )
}

export default GalleryConnector(Component)