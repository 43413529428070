import React from 'react'
import Favorite from 'components/Artworks/Actions/Favorites/Action'
import { MetaItem } from '../styles/products'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export type MetaSegmentProps = {
  artworkId?: number
  count: number
  type: string
}

const MetaSegment = (
  props: MetaSegmentProps
): React.ReactElement<React.ComponentProps<any>, any> => {
  const { artworkId, count, type } = props

  if (type === 'Views') {
    return (
      <MetaItem data-meta data-type='views'>
        <SATypography variant={SATypographyVariant.SMALL}>{`${count} `} Views</SATypography>
      </MetaItem>
    )
  }

  if (type === 'Likes') {
    return (
      <MetaItem data-meta data-type='favorite'>
        <Favorite artworkId={Number(artworkId)} dataStyle='profile' />
        <SATypography variant={SATypographyVariant.SMALL}>{count}</SATypography>
      </MetaItem>
    )
  }

  // Default layout. Keep as sole return when abtest is complete.
  return (
    <MetaItem>
      <SATypography variant={SATypographyVariant.SMALL}>{count}</SATypography>
      <SATypography variant={SATypographyVariant.SMALL}>
        {type === 'Views' ? 'Views' : 'Favorites'}
      </SATypography>
    </MetaItem>
  )
}

export default MetaSegment
