import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { space } from 'styled-system'
import SALink from 'components/SALink'
import { ArtworkContainer } from 'components/Carousel/Artworks/styles'
import { H2 } from 'components/Sans'
import { Actions } from './gallery/actions'

const flexParams = (props) => `
  ${props.justify && `justify-content: ${props.justify};`};
  ${props.align && `align-items: ${props.align};`};
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => flexParams(props)};
  ${space};
  ${(props) => props.click && `cursor: pointer;`}
`
export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => flexParams(props)};
  ${space};
  ${(props) =>
    props.box &&
    `
    padding: 20px;
    border: 1px solid #e9e9e9;`}
  ${Actions} ~ p {
    margin-bottom: -35px;
  }
`
export const ColumnStickyCart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${(props) => flexParams(props)};
`
export const Title = styled(H2)`
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.default};
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.default};
  }
`
export const Text = styled.p`
  display: block;
  width: 100%;
  ${(props) => props.center && `text-align: center;`}
  ${(props) => props.italic && `font-style: italic;`}
  ${space};
`
export const Bold = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  color: ${(props) => props.theme.colors.default};
  margin: 0;
  text-transform: uppercase;
  ${space};
`
export const ArtworkWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${space}

  @media (max-width: ${(props) => props.theme.maxWidth}) {
    padding: 0 15px;
  }

  ${ArtworkContainer} + ${ArtworkContainer} {
    margin-left: 35px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      margin-left: ${(props) => props.theme.space[3]}px;
    }
  }
`
//
// export const Row = styled.div`
//   ${space};
//   display: flex;
//   align-items: flex-end;
//   justify-content: space-between;
//   @media (max-width: 550px) {
//     flex-direction: column;
//     align-items: flex-start;
//   }
// `
export const Link = styled(SALink)`
  display: inline-block;
  margin: 0;
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  color: ${(props) => props.theme.colors.lightGray};
  text-decoration: none;
`
export const Button = styled.button`
  cursor: pointer;
  color: ${(props) => props.theme.colors.white};
  min-width: 175px;
  height: 50px;
  padding: 0 ${(props) => props.theme.space[3]}px;
  background: #231f20;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  transition: 0.3s ease opacity;
  text-transform: uppercase;
  outline: none;
  &:hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.underlined &&
    `
    background: none;
    width: auto;
    height: auto;
    min-width: initial;
    padding: ${props.theme.space[1]}px 0;
    color: ${props.theme.colors.default};
    border: none;
    border-bottom: 1px solid ${props.theme.colors.default};
  `};
  ${space};
`
