import React from 'react'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'
const ABArtistArtworksCarousel = dynamic(
  import('../components/Carousels/ABTest/ArtistArtworks'),
  noSSR
)
const ABRelatedArtworksCarousel = dynamic(
  import('../components/Carousels/ABTest/RelatedArtworks'),
  noSSR
)
const ABRecommendedArtworksCarousel = dynamic(
  import('../components/Carousels/GoogleRecommended/pdp'),
  noSSR
)

const Carousels = ({
  shouldLoadComponent,
  setShouldLoadComponent,
}: Record<string, any>): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <>
      <div data-section='artist-carousel'>
        <ABArtistArtworksCarousel setShouldLoadComponent={setShouldLoadComponent} />
      </div>
      <div data-section='visii-carousel'>
        <ABRelatedArtworksCarousel
          shouldLoadComponent={shouldLoadComponent}
          setShouldLoadComponent={setShouldLoadComponent}
        />
      </div>
      <div data-section='rec-carousel'>
        <ABRecommendedArtworksCarousel
          shouldLoadComponent={shouldLoadComponent}
          setShouldLoadComponent={setShouldLoadComponent}
        />
      </div>
    </>
  )
}

export default Carousels
