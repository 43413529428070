import styled from 'styled-components'
import { space, fontSize, color, fontWeight } from 'styled-system'

const BASE = (props) => `
  font-weight: normal;
  margin: 0;
  ${space};
  ${fontSize};
  ${fontWeight};
  ${color};
  ${props.italic && 'font-style: italic'};
  ${props.upper && 'text-transform: uppercase'};
  ${props.bold && 'font-weight: bold;'};
  ${props.wide && 'letter-spacing: 0.5px;'};
  ${props.inlineBlock && 'display: inline-block'};

`

export const Sans = styled.div`
  ${(props) => BASE(props)};
`
export const Span = styled.span`
  ${(props) => BASE(props)};
`
export const H1 = styled.h1`
  ${(props) => BASE(props)};
`
H1.defaultProps = {
  fontSize: 6,
}
export const H2 = styled.h2`
  ${(props) => BASE(props)};
`
H2.defaultProps = {
  fontSize: 5,
}
export const H3 = styled.h3`
  ${(props) => BASE(props)};
`
H3.defaultProps = {
  fontSize: 4,
}
export const H4 = styled.h4`
  ${(props) => BASE(props)};
`
H4.defaultProps = {
  fontSize: 3,
}
export const H5 = styled.h5`
  ${(props) => BASE(props)};
`
H5.defaultProps = {
  fontSize: 1,
  upper: true,
  bold: true,
}
export const H6 = styled.h6`
  ${(props) => BASE(props)};
`
H6.defaultProps = {
  fontSize: 0,
  upper: true,
  bold: true,
  wide: true,
  mb: 1,
}
export const EmphasizedText = styled(Sans)``
EmphasizedText.defaultProps = {
  upper: true,
  bold: true,
  wide: true,
  fontSize: 0,
}
export default Sans