import { observerEpicHelper } from 'epics/helpers'
import { addStoreLocaleByCookie } from 'components/GeoLocation/helpers'
import { Constants as Client } from 'actions/shared/client'
import { Actions as AlgoliaActions } from 'actions/algolia'
import { Actions as DataLayerActions } from 'actions/shared/dataLayer'
import { Actions as FlashMessageActions } from 'actions/shared/flashMessage'
import { Actions as FavoritesActions, Constants as Favorites } from 'actions/user/favorite'
import { Actions as PageActions } from 'actions/page'
import { Actions as ScrollActions } from 'actions/utility/scroll'
import { convertFromCentsToDollarsString } from 'components/FormatPrice/helpers'
import { postCartItem } from 'apis/cart'
import { isAuthenticated } from 'selectors/user/session'
import { getSelectedCurrencyCode } from 'selectors/user/cookie'

import { updateAppUrl } from 'lib/url/route'
import DataLayer from 'lib/dataLayer'
import { getGoogleCookieUserId } from 'lib/cookies'
import { parseSku } from 'lib/product'
import { DEFAULT_APP, DEFAULT_URL } from '../helpers/constants'
import { getARExperienceLink } from '../selectors/arExperience'
import { getArtistName } from '../selectors/artist'
import { getArtwork, getArtworkId } from '../selectors/artwork'
import { getDataLayer, getDataLayerGA4 } from '../selectors/dataLayer'
import { getPath } from '../selectors/metadata'
import type {
  SelectedCanvasWrapColorOption as CanvasWrapColor,
  SelectedFrameOption,
} from '../selectors/product'
import {
  getSelectedProduct,
  getSelectedProductSku,
  getSelectedCanvasWrapColorOption,
  getSelectedFrameOption,
  getSelectedProductPrice,
} from '../selectors/product'
import { Constants, Actions as ArtDetailActions } from '../actions'
import { incrementViewCount } from '../apis/artwork'

const { SITE_URL } = process.env
let timer
const timerDuration = 6000

const getHost = (): string => {
  if (typeof window !== 'undefined') {
    return window.location.origin
  } else {
    return SITE_URL || ''
  }
}

export const getClientSideDataEpic = observerEpicHelper(
  [Client.NEW_CLIENT_COMPLETE],
  async ({ action, observer, store }): Promise<void> => {
    const state = { ...store.value }

    if (isAuthenticated(state)) {
      observer.next(FavoritesActions.FETCH())
    }

    // Increment View Count
    const artworkId = getArtworkId({ ...store.value })

    if (artworkId) {
      incrementViewCount(artworkId)
    }

    observer.next(ArtDetailActions.SELL_OUT_RISK())
    const currencyCode = getSelectedCurrencyCode({ ...store.value })
    DataLayer.setCurrency(currencyCode)
    const dataLayerGA4 = getDataLayerGA4({ ...store.value })
    clearTimeout(timer)
    observer.next(
      DataLayerActions.UPDATE_DATALAYER({
        ecommerce: null,
      })
    )
    timer = setTimeout(() => {
      observer.next(DataLayerActions.UPDATE_DATALAYER(dataLayerGA4))
      observer.complete()
    }, timerDuration) // wait 6 seconds to fire the page impression event
  }
)
export const updateProductSkuEpic = observerEpicHelper(
  [Constants.UPDATE_PRODUCT_SKU],
  async ({ action, observer, store }) => {
    const { sku } = action.payload?.data || {}
    const path = getPath({ ...store.value })
    const url = `${path}?sku=${sku}`
    observer.next(ArtDetailActions.UPDATE_URL(url))
    observer.complete()
  }
)
export const updateProductSkuFrameOptionEpic = observerEpicHelper(
  [Constants.UPDATE_PRODUCT_SKU_FRAME_OPTION],
  async ({ action, observer, store }) => {
    const { value: selectedFrameOptionId } = action.payload || null
    const { sku } = getSelectedProduct({ ...store.value })
    const selectedCanvasWrapColorId: CanvasWrapColor = getSelectedCanvasWrapColorOption({
      ...store.value,
    })?.id
    const selectedOptionIds = [selectedCanvasWrapColorId, selectedFrameOptionId].filter(Boolean)
    const selectedProductSku = [sku, ...selectedOptionIds].join('-')
    const path = getPath({ ...store.value })
    const url = `${path}?sku=${selectedProductSku}`
    observer.next(ArtDetailActions.UPDATE_URL(url))
    observer.complete()
  }
)
export const updateProductSkuCanvasWrapColorOptionEpic = observerEpicHelper(
  [Constants.UPDATE_PRODUCT_SKU_CANVAS_WRAP_COLOR_OPTION],
  async ({ action, observer, store }) => {
    const { value: selectedCanvasWrapColorId } = action.payload || null
    const { sku } = getSelectedProduct({ ...store.value })
    const selectedFrameOptionId: SelectedFrameOption = getSelectedFrameOption({ ...store.value })
      ?.id
    const selectedOptionIds = [selectedCanvasWrapColorId, selectedFrameOptionId].filter(Boolean)
    const selectedProductSku = [sku, ...selectedOptionIds].join('-')
    const path = getPath({ ...store.value })
    const url = `${path}?sku=${selectedProductSku}`
    observer.next(ArtDetailActions.UPDATE_URL(url))
    observer.complete()
  }
)
export const updateUrlEpic = observerEpicHelper(
  [Constants.UPDATE_URL],
  async ({ action, observer, store }) => {
    const href = `${DEFAULT_URL}${action.payload.replace(getHost(), '')}`
    const app = DEFAULT_APP
    updateAppUrl({
      app,
      href,
    })
    observer.complete()
  }
)
export const clientsideTransitionsEpic = observerEpicHelper(
  [Constants.CLIENTSIDE_TRANSITION],
  async ({ action, observer, store }) => {
    // Update Data Layer
    const dataLayer = getDataLayer({ ...store.value })
    const dataLayerGA4 = getDataLayerGA4({ ...store.value })
    observer.next(DataLayerActions.UPDATE_DATALAYER({ ...dataLayer, event: 'pageview' }))
    observer.next(
      DataLayerActions.UPDATE_DATALAYER({
        ecommerce: null,
      })
    )
    clearTimeout(timer)
    observer.next(
      DataLayerActions.UPDATE_DATALAYER({
        ecommerce: null,
      })
    )
    timer = setTimeout(() => {
      observer.next(DataLayerActions.UPDATE_DATALAYER(dataLayerGA4))
      observer.complete()
    }, timerDuration) // wait 6 seconds to fire the page impression event
  }
)
export const clickArtistBadgeEpic = observerEpicHelper(
  [Constants.CLICK_ARTIST_BADGE],
  async ({ action, observer, store }): Promise<void> => {
    // Find Artist Recognition Section
    const artistRecognition = document.querySelector('[data-type="artist_badges"]')

    if (artistRecognition) {
      observer.next(
        ScrollActions.SCROLL_TO_ELEMENT({
          offset: artistRecognition.offsetTop - 10,
          duration: 350,
        })
      )
      observer.next(
        DataLayerActions.UPDATE_DATALAYER({
          event: 'badge',
          info: action.payload,
        })
      )
    }

    observer.complete()
  }
)
export const clickAddArtworkThumbnailEpic = observerEpicHelper(
  [Constants.CLICK_ADD_ARTWORK_THUMBNAIL],
  async ({ action, observer, store }): Promise<void> => {
    const artworkId = getArtworkId({ ...store.value })
    const location = addStoreLocaleByCookie(`/studio/art/${artworkId}/image`)
    window.location = location
    observer.complete()
  }
)
export const clickAddToCartEpic = observerEpicHelper(
  [Constants.CLICK_ADD_TO_CART, Constants.CLICK_ADD_TO_CART_STICKY],
  async ({ action, observer, store }): Promise<void> => {
    const sku = action.payload
    // Track STICKY Event
    const eventType =
      action.type === Constants.CLICK_ADD_TO_CART_STICKY ? 'pdp-sticky-bar' : 'pdp-inline'
    observer.next(PageActions.IS_READY(false))
    const { message, success } = await postCartItem(sku)

    if (!success) {
      observer.next([
        FlashMessageActions.ADD({
          messages: [message],
          type: 'error',
        }),
        PageActions.IS_READY(true),
      ])
      return
    }

    observer.next(
      DataLayerActions.UPDATE_DATALAYER({
        event: `addToCart`,
        prompt: eventType,
      })
    )
    const artworkId = getArtworkId({ ...store.value })
    observer.next(
      AlgoliaActions.EVENT({
        eventName: 'add to cart',
        eventType: 'conversion',
        objectIDs: [artworkId],
      })
    )
    // NEW - GA4 event
    const artwork = getArtwork(store.value)
    // const product = getSelectedProduct({ ...store.value })
    const artistName = getArtistName(store.value)
    const productPrice = getSelectedProductPrice({ ...store.value })
    const { title, category } = artwork
    // const { price } = product
    const { productTypeId } = parseSku(sku) // [configurationId, userId, artworkId, productTypeId]

    const productTypes = {
      [`1`]: 'original',
      [`2`]: 'print',
      [`3`]: 'print',
      [`4`]: 'original',
    }
    const checkoutLocation = addStoreLocaleByCookie(`/checkout`)

    // GTM/Datalayer is DISABLED
    if (!window.dataLayerEnabled) {
      window.location = checkoutLocation
    } else {
      observer.next(
        DataLayerActions.UPDATE_DATALAYER({
          event: `add_to_cart`,
          ecommerce: {
            currency: 'USD',
            [`page_element`]: eventType,
            value: convertFromCentsToDollarsString(productPrice), // artwork price
            items: [
              {
                item_id: sku || '',
                item_name: title || '',
                affiliation: 'Online Store',
                index: 0,
                item_art_id: artworkId || '',
                item_brand: artistName || '',
                item_category: category || '',
                item_variant: productTypes[productTypeId] || 'original',
                item_list_id: '', // product list from where the product was added to cart
                price: convertFromCentsToDollarsString(productPrice),
                quantity: 1,
              },
            ],
          },
          eventCallback() {
            // console.log('-- ga complete --')
            location.assign(checkoutLocation)
          },

          eventTimeout: 2000,
        })
      )
    }

    observer.complete()
  }
)
export const clickMakeAnOfferEpic = observerEpicHelper(
  [Constants.CLICK_MAKE_AN_OFFER, Constants.CLICK_MAKE_AN_OFFER_STICKY],
  async ({ action, observer, store }): Promise<void> => {
    const artworkId = getArtworkId({ ...store.value })
    observer.next(
      AlgoliaActions.EVENT({
        eventName: 'mao',
        eventType: 'conversion',
        objectIDs: [artworkId],
      })
    )
    observer.complete()
  }
)
export const clickViewMoreEpic = observerEpicHelper(
  [Constants.CLICK_VIEW_MORE],
  async ({ action, observer, store }): Promise<void> => {
    const { artworkId } = action.payload
    observer.next(
      DataLayerActions.UPDATE_DATALAYER({
        event: 'view more - prints',
      })
    )
    observer.next(
      AlgoliaActions.EVENT({
        eventName: 'view more - prints',
        eventType: 'conversion',
        objectIDs: [artworkId],
      })
    )
    observer.complete()
  }
)
export const updateFavoriteCountEpic = observerEpicHelper(
  [Favorites.UPDATE],
  async ({ action, observer, store }): Promise<void> => {
    // Increment/Decrement `likes` count
    const artwork = getArtwork(store.value)
    const { likes } = artwork
    const { favorite } = action.payload
    const newFavoriteCount = !favorite ? likes + 1 : likes - 1
    observer.next(
      PageActions.SET_DATA({
        artwork: { ...artwork, likes: newFavoriteCount < 0 ? 0 : newFavoriteCount },
      })
    )
    observer.complete()
  }
)
export const openARExperienceEpic = observerEpicHelper(
  [Constants.OPEN_AR_EXPERIENCE],
  async ({ action, observer, store }): Promise<void> => {
    const sku = getSelectedProductSku({ ...store.value })
    const googleUserID = getGoogleCookieUserId()
    const arExperienceURL = getARExperienceLink({ ...store.value })
    const arExperienceURLWithGA = `${arExperienceURL}&_ga=${googleUserID}`
    observer.next(
      DataLayerActions.UPDATE_DATALAYER({
        event: 'Web AR',
        info: sku,
      })
    )
    window.open(arExperienceURLWithGA, '_blank')
    observer.complete()
  }
)
export default [
  clickAddArtworkThumbnailEpic,
  clickAddToCartEpic,
  clickArtistBadgeEpic,
  clickMakeAnOfferEpic,
  clickViewMoreEpic,
  clientsideTransitionsEpic,
  getClientSideDataEpic,
  openARExperienceEpic,
  updateFavoriteCountEpic,
  updateProductSkuEpic,
  updateProductSkuFrameOptionEpic,
  updateProductSkuCanvasWrapColorOptionEpic,
  updateUrlEpic,
]