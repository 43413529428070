import React, { useRef, useEffect } from 'react'
import { getPrintAltText } from 'components/Artworks/Image/helpers'
import useComponentSize from 'hooks/componentSize'
import {
  CanvasWrapPrintImage,
  CanvasWrapPrintImageWithFrame,
  PaperPrintImage,
} from '../../styles/gallery'
import {
  METAL,
  ACRYLIC,
  CANVAS_WRAP_FRAME_SIZE_IN_INCHES,
  CANVAS_WRAP_GAP_SIZE_IN_INCHES,
} from '../../../helpers/constants'
const smallBorder = 2 // inches for Prints width < 25

const largeBorder = 3 // inches for Prints width >= 25

const frameBorder = 0.625 // inches

type Props = {
  alt: string
  frameColor: string | null | undefined
  canvasWrapColor: string | null | undefined
  height: number
  imageUrl: string
  overrides?: Record<string, any>
  type: string
  width: number
  hasHighFetchPriority?: boolean
  isVirImage?: boolean
  artistName: string
  subject: string
  styles: ReadonlyArray<string>
  category: string
}

function getCanvasWrapFrameAndGapSizeInPixels(
  printWidthInInches: number,
  printWidthInPixels: number,
  printHeightInInches: number,
  printHeightInPixels: number
): {
  frameSizeInPixels: number
  gapSizeInPixels: number
} {
  const scale =
    printWidthInInches > printHeightInPixels
      ? printWidthInInches / printWidthInPixels
      : printHeightInInches / printHeightInPixels
  return {
    frameSizeInPixels: Math.round(CANVAS_WRAP_FRAME_SIZE_IN_INCHES / scale),
    gapSizeInPixels: Math.round(CANVAS_WRAP_GAP_SIZE_IN_INCHES / scale),
  }
}

const Component = (props: Props) => {
  const printRef: Record<string, any> = useRef(null)
  const { width: containerWidth, height: containerHeight, recalculate } = useComponentSize(printRef)
  const {
    alt,
    frameColor,
    canvasWrapColor,
    height: printHeightInInches,
    imageUrl,
    overrides,
    type,
    width: printWidthInInches,
    hasHighFetchPriority,
    isVirImage,
    artistName,
    subject,
    styles,
    category,
  } = props
  const { width: overrideWidth, height: overrideHeight } = overrides || {}
  useEffect(() => {
    recalculate()
  }, [printHeightInInches, printWidthInInches, type])

  if ((!overrideWidth || !overrideHeight) && !printWidthInInches && !printHeightInInches) {
    return null
  }

  if (canvasWrapColor) {
    if (!frameColor) {
      return (
        <CanvasWrapPrintImage
          alt={`${alt} - Print`}
          data-type='print-image'
          onLoad={recalculate}
          canvasWrapColor={canvasWrapColor}
          ref={printRef}
          src={imageUrl}
          data-material={type}
          isVirImage={isVirImage}
          {...(hasHighFetchPriority
            ? {
                fetchPriority: 'high',
              }
            : {})}
        />
      )
    }

    const { frameSizeInPixels, gapSizeInPixels } = getCanvasWrapFrameAndGapSizeInPixels(
      Number(printWidthInInches),
      Number(overrideWidth || containerWidth),
      Number(printHeightInInches),
      Number(overrideHeight || containerHeight)
    )
    return (
      <CanvasWrapPrintImageWithFrame
        alt={`${alt} - Print`}
        data-type='print-image'
        onLoad={recalculate}
        canvasWrapColor={canvasWrapColor}
        frameColor={frameColor}
        gapSizeInPixels={gapSizeInPixels}
        frameSize={frameSizeInPixels}
        ref={printRef}
        src={imageUrl}
        data-material={type}
        isVirImage={isVirImage}
        {...(hasHighFetchPriority
          ? {
              fetchPriority: 'high',
            }
          : {})}
      />
    )
  }

  const scaleBy =
    printWidthInInches / printHeightInInches >
    Number(overrideWidth || containerWidth) / Number(overrideHeight || containerHeight)
      ? 'width'
      : 'height'
  const formatSize = Number(scaleBy === 'width' ? printWidthInInches : printHeightInInches)
  const matBorder = printWidthInInches < 18 ? smallBorder : largeBorder
  const totalSize = formatSize + matBorder * 2 + (frameColor ? frameBorder * 2 : 0)
  const inchToPixel =
    (scaleBy === 'width'
      ? Number(overrideWidth || containerWidth)
      : Number(overrideHeight || containerHeight)) / totalSize
  const matSize = inchToPixel * matBorder
  const frameSize = inchToPixel * frameBorder
  return (
    <>
      <PaperPrintImage
        alt={getPrintAltText({
          artistName,
          subject,
          styles,
          category,
        })}
        data-type='print-image'
        frameColor={frameColor}
        frameSize={frameColor ? Math.round(frameSize) : 0}
        matSize={Math.round(matSize)}
        onLoad={recalculate}
        ref={printRef}
        src={imageUrl}
        type={type}
        data-material={type}
        isVirImage={isVirImage}
        {...(hasHighFetchPriority
          ? {
              fetchPriority: 'high',
            }
          : {})}
      />
      {isVirImage && (type === ACRYLIC || type === METAL) ? (
        <div
          data-type='overlay'
          style={{
            height: `${Number(overrideHeight || containerHeight)}px`,
            width: `${Number(overrideHeight || containerWidth)}px`,
          }}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Component