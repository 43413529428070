import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getIsClientReady } from 'selectors/shared/client'
import { Actions } from '../../actions'
import { getCanEditArtwork } from '../../selectors/admin'
import { getARExperienceAvailable } from '../../selectors/arExperience'
import { getArtworkTitle } from '../../selectors/artwork'
import { getCurrentTab, getSelectedProduct } from '../../selectors/product'
import { getGalleryImages } from '../../selectors/gallery'
const mapStateToProps = createStructuredSelector({
  canEditArtwork: getCanEditArtwork,
  currentTab: getCurrentTab,
  galleryImages: getGalleryImages,
  isARExperienceAvailable: getARExperienceAvailable,
  isClientReady: getIsClientReady,
  selectedProduct: getSelectedProduct,
  title: getArtworkTitle,
})

const mapDispatchToProps = (dispatch) => ({
  clickAddArtworkThumbnail: (payload: Record<string, any>) =>
    dispatch(Actions.CLICK_ADD_ARTWORK_THUMBNAIL()),
})

export type ConnectorProps = {
  canEditArtwork: boolean
  clickAddArtworkThumbnail: (...args: Array<any>) => any
  currentTab: string
  galleryImages: Array<Record<string, any>>
  isARExperienceAvailable: boolean
  isClientReady: boolean
  selectedProduct: Record<string, any>
  title: string
}
export default connect(mapStateToProps, mapDispatchToProps)