import React from 'react'
import type { ConnectorProps as ArtworkDescriptionProps } from '../../../connectors/ArtworkDescription'
import ArtworkDescriptionConnector from '../../../connectors/ArtworkDescription'
import { HELP_SECTION_LINK, CONTACT_US_LINK } from '../../../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const ContactUs = (props: ArtworkDescriptionProps): React.ReactElement<React.ComponentProps<any>, any> => {
    const { screenWidth } = props
    const targetValue = screenWidth > 1064 ? '_blank' : '_self'
    const relValue = screenWidth > 1064 ? 'noopener noreferrer' : ''
    return (
    <div data-type='accordion-sub-section' data-type-variant='help'>
      <div data-type='accordion-sub-header-title'>
        <SATypography variant={SATypographyVariant.H6}>Have additional questions?</SATypography>
      </div>
      <div data-type='accordion-sub-header-content'>
        <SATypography variant={SATypographyVariant.MEDIUM}>
          {`Please visit our `}
          <a
            data-type='support-link'
            target={targetValue}
            rel={relValue}
            href={HELP_SECTION_LINK}
            title='help section'
          >
            help section
          </a>
          {` or `}
          <a
            data-type='support-link'
            target={targetValue}
            rel={relValue}
            href={CONTACT_US_LINK}
            title='contact us'
          >
            contact us.
          </a>
        </SATypography>
      </div>
    </div>
  )
}


export default ArtworkDescriptionConnector(ContactUs)