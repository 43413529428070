import { createSelector } from 'reselect'
import { getPageData } from 'selectors/page'
import { FAIRCHAIN_ARTWORK_IDS } from '../helpers/fairchainArtworkIds'
export const getArtwork = createSelector(
  [getPageData],
  (page): Record<string, any> => page.artwork || {}
)
export const getArtworkId = createSelector(
  [getArtwork],
  (artwork): string => artwork.artworkId || ''
)
export const getIsFairchainArtwork = createSelector([getArtworkId], (artworkId): boolean => {
  return FAIRCHAIN_ARTWORK_IDS.indexOf(Number(artworkId)) > -1
})
export const getArtworkCategory = createSelector(
  [getArtwork],
  (artwork): string => artwork.category || ''
)
export const getArtworkDescription = createSelector(
  [getArtwork],
  (artwork): string => artwork.description || ''
)
export const getArtworkKeywords = createSelector(
  [getArtwork],
  (artwork): Array<string> => artwork.keywords || []
)
export const getArtworkMaterials = createSelector(
  [getArtwork],
  (artwork): Array<string> => artwork.materials || []
)
export const getArtworkMediums = createSelector(
  [getArtwork],
  (artwork): Array<string> => artwork.mediums || []
)
export const getArtworkStyles = createSelector(
  [getArtwork],
  (artwork): Array<string> => artwork.styles || []
)
export const getArtworkSubject = createSelector(
  [getArtwork],
  (artwork): string => artwork.subject || ''
)
export const getArtworkTitle = createSelector(
  [getArtwork],
  (artwork): string => artwork.title || ''
)
export const getArtworkYearProduced = createSelector(
  [getArtwork],
  (artwork): number => artwork.yearProduced || 0
)
export const getArtworkMultiPanel = createSelector(
  [getArtwork],
  (artwork): number => artwork.isMultiPanel || false
)
export const getArtworkTitleSafe = createSelector([getArtworkTitle], (title): string =>
  title.replace(/["]/g, '')
)
export const getArtworkDimensions = createSelector(
  [getArtwork],
  (artwork): Record<string, any> => ({
    width: artwork.width || 0,
    height: artwork.height || 0,
    depth: artwork.depth || 0,
  })
)
export const getArtworkImage = createSelector(
  [getArtwork],
  (artwork): Record<string, any> => artwork.artworkImage || {}
)
export const getArtworkImageUrl = createSelector(
  [getArtworkImage],
  (artworkImage): string => artworkImage.imageUrl || ''
)
export const getArtworkImageAspectRatio = createSelector(
  [getArtworkImage],
  (artworkImage): number => (artworkImage.width || 1) / (artworkImage.height || 1)
)
export const getArtworkAdditionalImages = createSelector(
  [getArtwork],
  (artwork): Array<Record<string, any>> => artwork.additionalImages || []
)
export const getArtworkHasOriginal = createSelector(
  [getArtwork],
  (artwork): boolean => artwork.hasOriginal || false
)
export const getArtworkHasOpenEditions = createSelector(
  [getArtwork],
  (artwork): boolean => artwork.hasOpenEditions || false
)
export const getArtworkHasLimitedEditions = createSelector(
  [getArtwork],
  (artwork): boolean => artwork.hasLimitedEditions || false
)
export const getArtworkLegacyUserArtId = createSelector(
  [getArtwork],
  (artwork): number => artwork.legacyUserArtId || 0
)
export const getArtworkLikes = createSelector([getArtwork], (artwork): number => artwork.likes || 0)
export const getArtworkUserId = createSelector(
  [getArtwork],
  (artwork): number => artwork.userId || 0
)
export const getArtworkViews = createSelector([getArtwork], (artwork): number => artwork.views || 0)
export const getArtworkPanels = createSelector(
  [getArtwork],
  (artwork): number => artwork.panels || 0
)
export const getArtworkInfoTab = createSelector(
  [getArtworkCategory, getArtworkTitle, getArtworkDimensions],
  (category, title, dimensions): Record<string, any> => ({
    category,
    title,
    dimensions,
  })
)
export const getArtworkMetaData = createSelector(
  [getArtworkId, getArtworkLikes, getArtworkViews],
  (artworkId, likes, views): Record<string, any> => ({
    artworkId,
    views,
    likes,
  })
)
export const getArtworkOriginal = createSelector(
  [
    getArtworkId,
    getArtworkCategory,
    getArtworkLegacyUserArtId,
    getArtworkTitle,
    getArtworkUserId,
    getArtworkDimensions,
  ],
  (artworkId, category, legacyUserArtId, title, artistId, dimensions): Record<string, any> => ({
    artworkId,
    category,
    legacyUserArtId,
    title,
    artistId,
    ...dimensions,
  })
)