import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { space } from 'styled-system'
import SALink from 'components/SALink'
import {
  Button as DropdownButton,
  Item as DropdownItem,
  Wrapper as DropdownWrapper,
} from 'components/Dropdowns'
import { Serif } from 'components/Serif'
import { SellOutRiskWrapper } from './sellOutRisk'
import { Column, Bold, Row, Text, ColumnStickyCart } from './index'
export { Column, Bold, Row, Text, ColumnStickyCart } from './index'
export const ProductTab = styled.div`
  align-items: flex-start;
`
export const Title = styled(Serif)`
  ${space};
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: 20px;
  }
`
export const SubTitle = styled(Serif)`
  font-size: 22px;
  font-style: italic;
  margin: 0;
  padding: 0;
  flex: 1 0 auto;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: 18px;
  }
`
export const Price = styled.div`
  text-align: center;
`
export const MaoButton = styled.button`
  background: ${(props) => props.theme.colors.darkerRed};
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  width: 250px;
  height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  gap: 12px;
  &[data-button='mao'] {
    color: #ffffff;
    width: 180px;
    font-size: 12px;
    white-space: nowrap;
    background: transparent;
    border: 1px solid transparent;
    svg {
      fill: #fff;
    }
  }
`
export const LinkButton = styled.button`
  background: ${(props) => props.theme.colors.darkerRed};
  color: #ffffff;
  border: none;
  cursor: pointer;
  width: 250px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  &[data-button='add-to-cart'] {
    width: 180px;
    color: #ffffff;
    margin: 10px 0 10px 10px;
  }
  &[data-button='view-more'] {
    color: #ffffff;
    margin: 10px 0;
  }
  &[data-button='reserved'] {
    font-size: 11px;
    font-weight: normal;
    color: #ffffff;
    width: 70px;
    height: 30px;
  }

  &[data-button='mao'] {
    color: #ffffff;
    width: 180px;
    font-size: 12px;
    white-space: nowrap;
    background: transparent;
    border: 1px solid transparent;
    svg {
      margin-right: 5px;
      fill: #fff;
    }
  }
  &[data-button='mao-blue'] {
    background: transparent;
    color: ${(props) => props.theme.colors.blue};
    font-size: 17px;
    font-weight: normal;
    height: 35px;
    cursor: pointer;
    .fa {
      display: none;
    }
  }
  ${(props) =>
    props.disabled &&
    !props.addingToCart &&
    `
    font-size: 11px;
    font-weight: normal;
    width: 70px;
    height: 30px;
    b {
      font-weight: bold;
      color: ${props.theme.colors.red};
    }
  `};
  ${(props) =>
    props.disabled &&
    props.addingToCart === 'true' &&
    `
    background: #E04100;
  `}
  ${(props) =>
    props.column &&
    `
      flex-direction: column;
    `};
`
export const StickyAddToCartButton = styled.button`
  display: flex;
  height: 32px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #da3b1f;
  background: #da3b1f;
  box-shadow: 0px 1px 3px 0px rgba(17, 17, 17, 0.1), 0px 1px 2px 0px rgba(17, 17, 17, 0.06);
  color: #ffffff;
`
export const TooltipContent = styled.div`
  padding: 10px;
  width: 300px;
  color: #ffffff;
  a,
  a:-webkit-any-link {
    color: #ffffff;
    text-decoration: underline;
  }
  ul {
    padding-left: 25px;
    li + li {
      margin-top: 10px;
    }
  }
  span {
    font-size: 14px;
  }
  ${(props) =>
    props.row &&
    `
    display: flex;
    text-align: left;
    img {
      width: 75px;
      height: 75px;
    }
    * + * {
      margin-left: 10px;
      width: 200px;
    }
  `};
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    width: 180px;
  }
  &[data-type='fairchain-message'] {
    font-size: 13px;
    line-height: 1.4;
  }
`
export const Sold = styled.div`
  ${space};
  background-color: ${(props) => props.theme.colors.red};
  color: #ffffff;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  flex: 0 0 auto;
`
export const BlackBox = styled(Row)`
  margin: 24px 0;
  padding: 10px;
  width: calc(100% + 30px);
  min-height: 80px;
  background: #333333;
  color: #ffffff;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    display: block;
    border-style: solid;
    border-color: #000 transparent transparent #000;
    border-width: 5px;
    bottom: -10px;
    right: 0;
  }
  ${Column} {
    background: transparent;
    padding: 0;
    margin: 0;
    &:first-child {
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
    }
  }
  span {
    font-size: 17px;
    color: #999;
    font-weight: normal;
  }
  &[data-status='forSale'] {
    flex-direction: column;
    justify-content: justify-content;
    ${Column} + ${Column} {
      align-items: center;
    }
  }
  &[data-status='notForSale'] {
    justify-content: center;
    align-items: center;
    min-height: 50px;
  }
  &[data-status='soldOut'] {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    ${Sold} {
       margin: 0 auto 10px;
    }
  }
  &[data-status='reserved'] {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    span {
      font-size: 16px;
    }
  }
  &[data-type='print'] {
    justify-content: space-between;
    align-items: center;
    ${Column} {
      width: auto;
    }
  }

  .affirm-product-modal {
    align-self: flex-end;
    display: block;
    padding: 0;
    text-align: left;
    [data-type='affirm-logo'] {
      display: flex;
      gap: 5px;
      margin-top: 5px;
      span {
        margin: 0;
      }
    }
  }
`
export const Tabs = styled(Row)`
  justify-content: space-between;
  background: #ffffff;
  a {
    height: 60px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    text-decoration: none;
    &:focus {
      outline: none;
    }
  }
  [data-type='tab-title'] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    background: #f3f3f3;
    padding: 0 0 10px;
    color: #333;
    h6 {
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        text-transform: uppercase;
      }
    }
  }
  &[data-tab='prints'],
  &[data-tab='limited'],
  &[data-tab='open'] {
    a:first-child [data-type='tab-title'] {
      background-color: #dcdcdc;
      height: 50px;
      padding: 0;
      margin: 0 10px 10px 0;
      &:hover {
        background-color: #e9e9e9;
      }
    }
  }
  &[data-tab='original'] {
    a:last-child [data-type='tab-title'] {
      background-color: #dcdcdc;
      height: 50px;
      padding: 0;
      margin: 0 0 10px 10px;
      &:hover {
        background-color: #e9e9e9;
      }
    }
  }
`
export const ReasonsToBuyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  [data-type='reason-to-buy-item-container'] {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  [data-type='trustpilot-container'] {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    [data-type='trustpilot-stars-container'] {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2px;
    }
  }
`
export const ReasonsToBuy = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  span {
    font-size: 17px;
  }
  > p {
    margin: 0 10px 3px 0;
  }
`
export const VisiiLink = styled(SALink)`
  text-decoration: none;
  background-color: #ffffff;
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  [data-type='text'] {
    color: #333333;
    flex: none;
    margin: 0px 0px 0px 10px;
  }
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(51, 51, 51);
    width: 37px;
    height: 37px;
    font-size: 19px;
    background: rgb(243, 243, 243);
    border-radius: 50%;
    transition: color 0.3s ease-in-out 0s, background 0.3s ease-in-out 0s;
    a:hover & {
      color: rgb(255, 255, 255);
      background: rgb(0, 0, 0);
    }
  }
`
export const MetaItem = styled(Column)`
  background: #ffffff;
  justify-content: center;
  align: items: flex-center;
  margin: 10px 0 5px;
  &[data-meta] {
    flex-direction: row;
    background: unset;
    justify-content: unset;
    align: unset;
    margin: 10px 0 5px;

    span {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #000;
    }
    span:first-child {
      padding-right: 6px;
    }
    a {
      width: 37px;
      padding-left: 2px;
      justify-content: flex-start;
    }
  }
`
export const MetaInfo = styled(Row)`
  display: flex;
  max-height: 24px;
  margin: 20px 0 30px;
  &[data-meta] {
    margin: 24px 0px 8px 0px;
    flex-direction: row-reverse;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      margin: 16px 0px 0px 0px;
      display: none;
    }
    &[data-context='gallery'] {
      display: none;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        display: flex;
      }
    }
  }
  ${MetaItem} {
    width: 50%;
    padding: 15px;
  }
  ${MetaItem}:first-child {
    margin-right: 5px;
  }
  ${MetaItem}:last-child {
    margin-left: 5px;
  }
  &[data-meta] {
    ${MetaItem}:first-child {
      margin-right: unset;
      margin-left: 5px;
      justify-content: flex-end;
      padding: 0 15px;
      &[data-type='views'] {
        padding-right: 5px;
        @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
          padding-right: 0;
        }
      }
    }
    ${MetaItem}:last-child {
      margin-left: unset;
      margin-right: 5px;
      justify-content: flex-start;
      align-items: center;
      padding: 0 15px;
      &[data-type='favorite'] {
        padding-left: 0;
      }
      [data-type='action'] {
        background: unset;
        padding-left: 0;
        z-index: 4;
        svg {
          path {
            fill: #fff;
            stroke: #666;
          }
          &[data-favorite] {
            path {
              fill: #ef542c;
              stroke: #ef542c;
            }
          }
          @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
            &:hover {
              path {
                fill: #ef542c;
                stroke: #ef542c;
              }
            }
          }
        }
      }
    }
  }
`
export const Badge = styled(Row)`
  margin-top: 8px;
  cursor: pointer;
  a {
    transition: 0.3s ease-in-out color;
  }
`
export const Badges = styled(Column)`
  background: #ffffff;
  padding: 16px;
  margin: 16px 0px;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  [data-type='badge-description'] {
    color: #e9c47c;
  }
`
export const Content = styled(Column)`
  padding: 16px 16px 24px 16px;
  [data-type='fairchain-message'] {
    font-size: 14px;
    margin: 20px 0 20px;
    [data-type='logo'] {
      display: flex;
      align-items: center;
      margin: 0 0 5px;
    }
    svg[data-type='logo'] {
      width: 110px;
      height: 14px;
      margin: 0 5px 0 0;
      path {
        fill: #333333;
      }
    }
    span {
      font-style: italic;
    }
  }
`
export const Products = styled(Column)`
  width: 100%;
  background: #f3f3f3;
  [data-type='main-artwork-info-container'] {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 24px;
  }
  [data-type='profile'] {
    text-decoration: none;
  }
  [data-type='profile-artist-name'] {
    color: rgb(240, 80, 38);
  }
  [data-type='sticky-text'] {
    margin:0;
  }
  ${Title}, ${Text}, ${Bold} {
    text-align: left;
    a {
      color: #f05026;
    }
  }
  ${Bold} {
    color: #333;
  }
  ${DropdownWrapper} {
    margin-bottom: 16px;
    ${DropdownButton} {
      background: #ffffff;
      padding: 10px 10px;
      p {
        margin: 0;
      }
    }
    ${DropdownItem} {
      background: #dcdcdc;
      p {
        color: #333333;
        margin: 0;
      }
      &[data-selected] {
        background: #333333;
        p {
          color: #ffffff;
        }
      }
    }
  }
  ${SellOutRiskWrapper} {
    margin: 5px auto 15px;
    background-color: #d8d8d8;
    p {
      color: #333333;
      flex: none;
      @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
        font-size: 14px;
      }
    }
    svg > * {
      fill: #333333;
    }
  }
  [data-type='option'] {
    margin: 0 0 8px 0;
    [data-type='limited-title'] {
      flex: none;
    }
    p {
      width: auto;
      margin: 0 5px 0 0;
    }
    ${SubTitle} {
      margin: 0;
    }
    a {
      color: #ffffff;
    }
    justify-content: flex-start;
  }
    [data-type='packaging'] {
    justify-content: flex-start;
    p {
      width: auto;
      margin: 0 5px 0 0;
    }
  }
`
export const StickyCart = styled.div`
  position: fixed;
  z-index: 15;
  top: 54px;
  left: 0;
  transform: translateY(-110%);
  opacity: 0;
  transition: 0.2s ease-in-out transform, 0.2s ease opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  background: #ffffff;
  width: 100%;
  height: 88px;
  gap: 8px;
  border-bottom: 1px solid #eee;
  box-shadow: 0 5px 15px  0 rgba(0, 0, 0, 0.2);
  ${(props) =>
    props.isVisible &&
    `
    transform: translateY(0);
    opacity: 1;
  `}
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    top: 0;
  }

  padding: 8px;
  img {
    max-width: 42px;
    max-height: 42px;
  }
  [data-type='sticky-image-text-container'] {
    width: 100%;
    display: flex;
    gap: 8px;
    overflow: hidden;
    min-width: 140px;
  }
  [data-type='sticky-actions'] {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
  [data-section='info'] {
    gap: 2px;
    [data-section='price'] {
      margin: 0;
      text-align: left;
    }
  }
  [data-type='sticky-actions'] {
    min-width: 132px;
    [data-button='mao'] {
    width: unset;
    display: flex;
    justify-content: flex-end;
    height: 22px;
    align-items: center;
    gap: 12px;
    padding: 0;
      svg{
        fill: #333;
      }
    }
  }
`
