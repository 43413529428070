import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getLocaleCountryCode } from 'selectors/shared/locale'
import { getMeasurementUnit } from 'selectors/user/session'
import { getSelectedCurrency } from 'selectors/user/cookie'
import { Actions } from '../actions'
import { getArtistCountry, getArtistName, getArtistProfileUrl } from '../selectors/artist'
import {
  getArtworkId,
  getArtworkCategory,
  getArtworkTitle,
  getArtworkUserId,
  getArtworkDimensions,
} from '../selectors/artwork'
import type { SelectedCanvasWrapColorOption, SelectedFrameOption } from '../selectors/product'
import {
  getCurrentProductType,
  getPrintProducts,
  getSelectedCanvasWrapColorOption,
  getSelectedFrameOption,
  getTotalPriceInCentsOfSelectedOptions,
  getSelectedProduct,
  getSelectedProductSku,
  getSelectedProductStatus,
  getCurrentTab,
} from '../selectors/product'
const mapStateToProps = createStructuredSelector({
  artistName: getArtistName,
  artworkId: getArtworkId,
  category: getArtworkCategory,
  country: getArtistCountry,
  currentTab: getCurrentTab,
  dimensions: getArtworkDimensions,
  localizationCountry: getLocaleCountryCode,
  printProducts: getPrintProducts,
  productStatus: getSelectedProductStatus,
  productType: getCurrentProductType,
  profileUrl: getArtistProfileUrl,
  selectedCurrency: getSelectedCurrency,
  selectedFrameOption: getSelectedFrameOption,
  selectedCanvasWrapColorOption: getSelectedCanvasWrapColorOption,
  totalPriceInCentsOfSelectedOptions: getTotalPriceInCentsOfSelectedOptions,
  selectedProduct: getSelectedProduct,
  selectedProductSku: getSelectedProductSku,
  title: getArtworkTitle,
  userId: getArtworkUserId,
  userSizePreference: getMeasurementUnit,
})

const mapDispatchToProps = (dispatch) => ({
  clickAddToCart: (sku: string) => dispatch(Actions.CLICK_ADD_TO_CART(sku)),
  updateProductSku: (payload: Record<string, any>) => dispatch(Actions.UPDATE_PRODUCT_SKU(payload)),
  updateProductSkuFrameOption: (payload: { value: string }) =>
    dispatch(Actions.UPDATE_PRODUCT_SKU_FRAME_OPTION(payload)),
  updateProductSkuCanvasWrapColorOption: (payload: { value: string }) =>
    dispatch(Actions.UPDATE_PRODUCT_SKU_CANVAS_WRAP_COLOR_OPTION(payload)),
  updateUrl: (payload: string) => dispatch(Actions.UPDATE_URL(payload)),
})

export type ConnectorProps = {
  artistName: string
  artworkId: number
  category: number
  clickAddToCart: (...args: Array<any>) => any
  country: string
  currentTab: string
  dimensions: Record<string, any>
  localizationCountry: string
  printProducts: Array<Record<string, any>>
  productStatus: string
  productType: string
  profileUrl: string
  selectedCurrency: Record<string, any>
  selectedFrameOption: SelectedFrameOption
  selectedCanvasWrapColorOption: SelectedCanvasWrapColorOption
  totalPriceInCentsOfSelectedOptions: number
  selectedProduct: Record<string, any>
  selectedProductSku: string
  title: string
  updateProductSku: (...args: Array<any>) => any
  updateProductSkuFrameOption: (...args: Array<any>) => any
  updateProductSkuCanvasWrapColorOption: (...args: Array<any>) => any
  userId: number
  userSizePreference: 'in' | 'cm'
}
export default connect(mapStateToProps, mapDispatchToProps)