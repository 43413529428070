import React from 'react'
import SALink from 'components/SALink'
import { USPList, CONTACT_US_LINK, ART_ADVISORY_LINK } from '../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import styles from './styles/uspSection.module.scss'

const USPSection = () => {
  return (
    <div className={styles.usp__section__container} sa-type='usp-section'>
      <div className={styles.usp__items__container}>
        {USPList.map((item, index) => (
          <div key={`usps-${index}`} className={styles.usp__items__column}>
            <div className={styles.usp__items__icon}>{item.image}</div>
            <div className={styles.usp__items__title}>
              <SATypography variant={SATypographyVariant.H6}>
                {item.title}
              </SATypography>
            </div>
            <div className={styles.usp__items__description}>
              <SATypography variant={SATypographyVariant.X_SMALL}>{item.description}</SATypography>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.usp__needMoreHelp}>
        <SATypography variant={SATypographyVariant.H4}>
          Need More Help?
        </SATypography>
        <div className={styles.usp__needMoreHelp__buttons__container}>
            <div className={styles.usp__needMoreHelp__buttons__box}>
              <SALink
                href={ART_ADVISORY_LINK}
                title='art advisory'
                target='_blank'
                rel='noopener noreferrer'
              >
                <SATypography variant={SATypographyVariant.CTA_SMALL}>
                    Enjoy Complimentary Art Advisory
                </SATypography>
              </SALink>
            </div>
            <div className={styles.usp__needMoreHelp__buttons__box}>
              <SALink href={CONTACT_US_LINK} title='support' target='_blank' rel='noopener noreferrer'>
                <SATypography variant={SATypographyVariant.CTA_SMALL}>
                Contact Customer Support
                </SATypography>
              </SALink>
            </div>
        </div>
      </div>
    </div>
  )
}

export default USPSection