import React from 'react'
import { replaceBadges } from 'lib/badges'
import type { ConnectorProps as ProductInfoProps } from '../../connectors/ProductsMetaInfo'
import ProductInfoConnector from '../../connectors/ProductsMetaInfo'
import { Badges, Badge } from '../styles/products'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ProductInfoConnector((props: ProductInfoProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { badges, clickArtistBadge } = props

  if (!badges.length) {
    return null
  }

  return (
    <Badges>
      <div data-type='title-section'>
        <SATypography variant={SATypographyVariant.H6}>Artist Recognition</SATypography>
      </div>
      {replaceBadges(badges).map(({ image, title }, key) => {
        return (
          <Badge
            key={title + key}
            justify='flex-start'
            align='center'
            onClick={() => clickArtistBadge(title)}
          >
            <img className='lazyload' data-src={image} alt={`link - ${title}`} />
            <div data-type='badge-description'>
              <SATypography variant={SATypographyVariant.SMALL}>{title}</SATypography>
            </div>
          </Badge>
        )
      })}
    </Badges>
  )
})