import React, { PureComponent } from 'react'
import styled from 'styled-components'
import TransitionableHeightContainer, { Inner } from 'components/TransitionableHeightContainer'
import CaretDownSvg from '../../svg/caret-down.svg'
import CloseSvg from '../../svg/close.svg'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  ${Inner} {
    padding: ${(props) => props.theme.space[2]}px 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
`
export const Button = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  :focus {
    outline: none;
  }
  p {
    color: #1E1E1E;
  }
`

const iconVisible = (props) => `
  position: absolute;
  right: 3px;
  top: 0;
  opacity: 0;
  transform: scale(0);
  ${
    props.show &&
    `
    opacity: 1;
    transform: scale(1);
  `
  };
  transition: 0.3s ease opacity, 0.2s ease transform;
  will-change: opacity, transform;
`

const Icons = styled.div`
  position: relative;
  width: 30px;
  height: 10px;
`
const Down = styled(CaretDownSvg)`
  height: 14px;
  width: 14px;
  ${(props) => iconVisible(props)};
`
const Close = styled(CloseSvg)`
  height: 12px;
  width: 12px;
  right: 3px;
  ${(props) => iconVisible(props)};
`
type Props = {
  children: React.ReactElement<React.ComponentProps<any>, any>[]
  dataType?: string
  label: string | null | undefined
}
type State = {
  isOpen: boolean
}
export default class Dropdown extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  toggle = () =>
    this.setState({
      isOpen: !this.state.isOpen,
    })
  open = () =>
    this.setState({
      isOpen: true,
    })
  close = () =>
    this.setState({
      isOpen: false,
    })

  render() {
    const { children, dataType, label } = this.props
    const { isOpen } = this.state
    return (
      <Wrapper data-type={dataType || 'dropdown'}>
        <Button role='button' tabIndex={0} onClick={this.toggle}>
          {label &&
            <SATypography data-selected={label} variant={SATypographyVariant.SMALL}>
              {label}
            </SATypography>
          }
          <Icons>
            <Down show={!isOpen ? 'show' : ''} onClick={this.toggle} />
            <Close show={isOpen ? 'show' : ''} onClick={this.close} />
          </Icons>
        </Button>

        <TransitionableHeightContainer open={isOpen}>{children}</TransitionableHeightContainer>
      </Wrapper>
    )
  }
}