import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getCheckIsMobileScreenWidth } from 'selectors/screen'
import { Actions as DataLayerActions } from 'actions/shared/dataLayer'
import { Actions } from '../actions'
import { getArtistId, getArtistName } from '../selectors/artist'
import {
  getArtworkId,
  getArtworkImageUrl,
  getArtworkImageAspectRatio,
  getArtworkLegacyUserArtId,
  getArtworkTitle,
} from '../selectors/artwork'
import {
  getCurrentTab,
  getSelectedProductPrice,
  getSelectedProductSku,
  getSelectedProductStatus,
  getTotalPriceInCentsOfSelectedOptions,
} from '../selectors/product'
const mapStateToProps = createStructuredSelector({
  artistId: getArtistId,
  artistName: getArtistName,
  artworkId: getArtworkId,
  artworkImage: getArtworkImageUrl,
  artworkImageAspectRatio: getArtworkImageAspectRatio,
  artworkTitle: getArtworkTitle,
  checkMobileScreen: (store, props) => getCheckIsMobileScreenWidth(props.mobileWidth)(store),
  // pass in custom value
  currentTab: getCurrentTab,
  legacyUserArtId: getArtworkLegacyUserArtId,
  productPrice: getSelectedProductPrice,
  productStatus: getSelectedProductStatus,
  totalPriceInCentsOfSelectedOptions: getTotalPriceInCentsOfSelectedOptions,
  sku: getSelectedProductSku,
})

const mapDispatchToProps = (dispatch) => ({
  clickAddToCart: (sku: string) => dispatch(Actions.CLICK_ADD_TO_CART_STICKY(sku)),
  clickMakeAnOffer: () => dispatch(Actions.CLICK_MAKE_AN_OFFER_STICKY()),
  updateDataLayer: (payload: Record<string, any>) =>
    dispatch(DataLayerActions.UPDATE_DATALAYER(payload)),
})

export type ConnectorProps = {
  artistId: number
  artistName: string
  artworkId: string
  artworkImage: string
  artworkImageAspectRatio: number
  artworkTitle: string
  checkMobileScreen: boolean
  clickAddToCart: (...args: Array<any>) => any
  clickMakeAnOffer: (...args: Array<any>) => any
  currentTab: string
  legacyUserArtId: number
  productPrice: number
  productStatus: string
  totalPriceInCentsOfSelectedOptions: int
  sku: string
  updateDataLayer: (...args: Array<any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps)