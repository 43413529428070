import { dispatchFlashMessage, observerEpicHelper } from 'epics/helpers'
import { Constants as CurateConstants, Actions as CurateActions } from 'actions/ops/curate'
import { Actions as PageActions } from 'actions/page'
import { deleteMyArtwork } from 'apis/artwork'
import { stageArtworkImage, updateArtworkImages } from 'apis/upload'
import { getPageParams, getPageData } from 'selectors/page'
import copyToClipboard from 'lib/copyToClipboard'
import { getArtDetails } from '../apis/artwork'
import { Constants, Actions as AppActions } from '../actions'
import { getArtworkId } from '../selectors/artwork'
import { getSelectedProductSku } from '../selectors/product'
import { updateDataLayer } from '../helpers'
// SellOutRisk - Show a Callout for how many people are viewing/cart
// Only for Original Artwork (for-sale), 10+ Views, Buyers Only ( NO ARTIST )
// Redux Store
// { page: { data: { sellOutRisk: { ${artworkId}: 'view' | 'cart' | 'none' } } } }
export const getArtworkAdminDataEpic = observerEpicHelper(
  [Constants.GET_ARTWORK_ADMIN_DATA],
  async ({ action, observer, store }): Promise<void> => {
    const state = { ...store.value }
    const { artworkId, artistId } = getPageParams(state)
    const { success, payload } = await getArtDetails({
      artistId,
      artworkId,
    })

    if (success) {
      const { admin, artwork } = payload
      observer.next(CurateActions.SET_ARTWORK_ID(artworkId))
      observer.next(CurateActions.SET_CURATION_HISTORY(admin.curationHistory))
      // Need Curators to map history of votes or curatorId to a curators name
      observer.next(CurateActions.FETCH_CURATORS())
      // Remove Curation History Info
      delete admin.curationHistory
      observer.next(
        PageActions.SET_DATA({
          admin,
          artwork,
        })
      )
    } else {
      // SET ADMIN, prevent infinite loops
      observer.next(
        PageActions.SET_DATA({
          admin: {},
        })
      )
    }

    observer.complete()
  }
)
export const actionCompleteEpic = observerEpicHelper(
  [
    CurateConstants.DELETE_ARTWORK_CURATION_STATUS_COMPLETE,
    CurateConstants.UPDATE_ARTWORK_CURATION_ISSAFE_COMPLETE,
    CurateConstants.UPDATE_ARTWORK_CURATION_STATUS_COMPLETE,
  ],
  async ({ action, observer, store }): Promise<void> => {
    observer.next(AppActions.GET_ARTWORK_ADMIN_DATA())
    observer.complete()
  }
)
export const copySkuEpicFromPayload = observerEpicHelper(
  [Constants.COPY_SKU],
  async ({ action, observer, store }) => {
    const selectedProductSku = getSelectedProductSku({ ...store.value })
    copyToClipboard({
      copyText: `${selectedProductSku}`,
      callback: () =>
        dispatchFlashMessage({
          observer,
          message: 'SKU Copied!',
          type: 'success',
        }),
    })
    observer.complete()
  }
)
export const deleteArtworkEpic = observerEpicHelper(
  [Constants.DELETE_ARTWORK],
  async ({ action, observer, store }) => {
    const { artistId } = getPageParams({ ...store.value })
    const {
      artwork: { artworkId },
    } = getPageData({ ...store.value })
    const { success } = await deleteMyArtwork({
      artworkId,
    })

    if (!success) {
      dispatchFlashMessage({
        observer,
        message: 'Problem deleting the artwork',
        type: 'error',
      })
    } else {
      dispatchFlashMessage({
        observer,
        message: 'Successfully deleted the artwork',
        type: 'success',
      })
      window.location.href = `/account/artworks/${artistId}`
    }

    observer.complete()
  }
)
export const editArtworkEpic = observerEpicHelper(
  [Constants.EDIT_ARTWORK],
  async ({ action, observer, store }) => {
    // const { artworkId } = getPageParams({ ...store.value })
    const {
      artwork: { artworkId },
    } = getPageData({ ...store.value })
    // window.location.href = `/upload/edit/art/${artworkId}/artist/${artistId}#/prints-status`
    window.location.href = `/studio/art/${artworkId}/overview`
    window.dataLayer.push({
      event: 'edit - start',
    })
    observer.complete()
  }
)
export const uploadArtworkImagesEpic = observerEpicHelper(
  [Constants.UPLOAD_ARTWORK_IMAGES],
  async ({ action, observer, store }) => {
    const { files } = action.payload
    const { onSuccess, onFailure } = action.meta
    const orderedFiles = files.sort((a, b) => {
      if (a.order > b.order) {
        return 1
      } else if (a.order < b.order) {
        return -1
      }

      return 0
    })
    const artworkId = getArtworkId(store.value)
    const response = await updateArtworkImages({
      files: orderedFiles,
      artworkId,
    })

    if (response.status === 'OK') {
      onSuccess()
      updateDataLayer({
        event: 'add additional image success',
      })
      dispatchFlashMessage({
        observer,
        message: 'You have successfully saved your artwork.',
        type: 'success',
      })
      observer.next(AppActions.REFRESH_PAGE())
    } else {
      onFailure()
      dispatchFlashMessage({
        observer,
        message: response.error,
        type: 'error',
      })
    }

    observer.complete()
  }
)
export const uploadStageImageEpic = observerEpicHelper(
  [Constants.UPLOAD_STAGE_IMAGE],
  async ({ action, observer, store }) => {
    const file = action.payload
    const { onSuccess, onFailure } = action.meta
    const [data] = file
    const json = await stageArtworkImage(data)
    const { s3key } = json

    if (s3key) {
      onSuccess({
        data,
        s3key,
      })
    } else {
      onFailure()
      const { error } = json
      dispatchFlashMessage({
        observer,
        message: error,
        type: 'error',
      })
    }

    observer.complete()
  }
)
export default [
  actionCompleteEpic,
  copySkuEpicFromPayload,
  deleteArtworkEpic,
  editArtworkEpic,
  getArtworkAdminDataEpic,
  uploadArtworkImagesEpic,
  uploadStageImageEpic,
]