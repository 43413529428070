import React from 'react'
import withRedux from 'next-redux-wrapper'
import Theme from 'components/Theme'
import { checkVisiiIdsExistApi } from 'apis/visii'
import { App, Store } from 'apps/saatchi/artdetail2'
import { Actions as ArtDetailActions } from 'apps/saatchi/artdetail2/actions'
import { Actions as PageActions } from 'actions/page'
import { getDataLayer } from 'apps/saatchi/artdetail2/selectors/dataLayer'
import { getMetaData } from 'apps/saatchi/artdetail2/selectors/metadata'
import { getUrlParams } from 'apps/saatchi/artdetail2/helpers'
import { getPageParams } from 'selectors/page'
import parse from 'lib/url/parse'
import { getArtDetails, incrementViewCount } from 'apps/saatchi/artdetail2/apis/artwork'

import { storeSetup } from 'lib/pageSetup'

// Visii Logic for CHECKING if Artwork Exists
const checkVisiiResults = async (artworkId: number): Record<string, any> => {
  try {
    const { results } = await checkVisiiIdsExistApi(artworkId.toString())
    const visiiExist = !!results && !!results.length // [{ explore_url, id, metadata }]

    const { metadata } = visiiExist ? results[0] : {} // Contains => {category: "Painting", price: 335, size: "m"}

    return {
      visiiExist,
      visiiMetadata: metadata,
    }
  } catch (error) {
    console.error({
      error,
    })
    return {
      visiiExist: false,
      visiiMetadata: null,
    }
  }
}

const ArtDetail = (): React$ElementRef<any> => (
  <Theme>
    <App />
  </Theme>
)

ArtDetail.clientSideTransitions = async ({ store, href }) => {
  if (window) {
    store.dispatch(PageActions.IS_READY(false))
    // Pull Old Data - Need to compare
    const oldParams = getPageParams({ ...store.value })
    // const href = (action.payload || '').replace(DEFAULT_URL, '')
    const newParams = getUrlParams(href)
    const query = parse(href).query || {}
    const path = parse(href).path || ''
    const updatedArtwork = oldParams.artworkId !== newParams.artworkId
    const storeActions = []
    storeActions.push(PageActions.SET_PARAMS({ ...query, ...newParams }))
    storeActions.push(PageActions.SET_PATH(path))
    // Check if artworkId has changed
    if (updatedArtwork) {
      const { success, payload } = await getArtDetails(newParams) // Initial Palette Call
      if (!success) {
        // Escape Early -> Custom Error Page
        throw new Error(`Bad ArtDetail API - ${href}`)
      }
      storeActions.push(PageActions.SET_DATA({ ...payload }))
      // Increment View Count
      incrementViewCount(newParams.artworkId)
      // Update Sell Out Risk
      storeActions.push(ArtDetailActions.SELL_OUT_RISK())
      // Update Visii
      const { visiiExist, visiiMetadata } = await checkVisiiResults(payload.artwork.artworkId)
      storeActions.push(PageActions.SET_DATA({ visiiExist, visiiMetadata }))
    }
    storeActions.push(PageActions.IS_READY(true))
    // Dispatch Array<ACTIONS>
    store.dispatch(storeActions)
  }
}

ArtDetail.getInitialProps = async ({ req, store, res, query }) => {
  // Page Data Setup
  // Client-Side
  if (!req) {
    const { href, pathname, search } = window.location
    store.dispatch(PageActions.SET_PATH(`${pathname}${search}`))
    await ArtDetail.clientSideTransitions({ store, href })
    const state = { ...store.getState() }
    const metaData = getMetaData(state)
    store.dispatch([ArtDetailActions.CLIENTSIDE_TRANSITION(href)])
    return { metaData }
  }

  // SSR
  storeSetup(store, req)
  const { page } = query
  const { artwork } = page.data
  const { artworkId } = artwork
  const { originalUrl, params, storeLocale } = req
  const { artistId } = params
  const { visiiExist, visiiMetadata } = await checkVisiiResults(artworkId)
  // Update Redux
  store.dispatch(PageActions.SET({ path: originalUrl, ...page }))
  store.dispatch(PageActions.SET_DATA({ visiiExist, visiiMetadata }))

  const storeTag = storeLocale === 'en-us' ? `${storeLocale}, en-global` : `${storeLocale}`
  res.set(
    'Edge-Cache-Tag',
    `page-artDetail, userId-${artistId}, artId-${artworkId}, page-artDetail-${artworkId}, ${storeTag}`
  )
  // Metadata & Datalayer
  const state = { ...store.getState() }
  const metaData = getMetaData(state)
  const dataLayer = getDataLayer(state)
  return {
    dataLayer,
    metaData,
  }
}

export default withRedux(Store)(ArtDetail)
