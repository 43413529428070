import React from 'react'
import { LocaleCurrency } from 'components/FormatPrice'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import type { ConnectorProps as ProductOriginalProps } from '../../../connectors/ProductsOriginal'
import ProductOriginalConnector from '../../../connectors/ProductsOriginal'
import { LinkButton, Sold } from '../../styles/products'
import { SOLD_OUT } from '../../../helpers/constants'
import RequestCommission from './RequestCommission'

const Component = (
  props: ProductOriginalProps
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    artworkId,
    clickViewMore,
    closeModal,
    listPrice,
    openModal,
    productStatus,
    tabUrls,
    updateUrl,
  } = props

  if (productStatus !== SOLD_OUT) {
    return null
  }

  const { openUrl, limited } = tabUrls
  const showCommissionLink = listPrice * 0.01 >= 1000

  const handleClickViewMore = () => {
    clickViewMore(artworkId)
    return updateUrl(openUrl)
  }

  return (
    <>
      <Sold>SOLD</Sold>

      <span>
        Originally listed for <LocaleCurrency price={convertFromCentsToDollars(listPrice)} />
      </span>

      {showCommissionLink && (
        <RequestCommission artworkId={artworkId} closeModal={closeModal} openModal={openModal} />
      )}

      {(openUrl || limited) && (
        <LinkButton
          data-type='Prints'
          data-button='view-more'
          href={openUrl || limited}
          onClick={handleClickViewMore}
          onKeyUp={handleClickViewMore}
          title='View more print options'
        >
          View Print Options
        </LinkButton>
      )}
    </>
  )
}

export default ProductOriginalConnector(Component)