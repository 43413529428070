export const FAIRCHAIN_ARTWORK_IDS = [
  2730425,
  2777724,
  3317154,
  3470357,
  5807931,
  5926353,
  6866865,
  7735029,
  7751607,
  7829865,
  7954364,
  8206943,
  8271238,
  8275474,
  8385866,
  8417197,
  8418284,
  8424056,
  8448069,
  8456982,
  8473341,
  8473371,
  8474241,
  8476087,
  8481792,
  8544174,
  8719642,
  8856340,
  8870245,
  9004999,
  9007141,
  9035587,
  9215425,
  9246809,
  9263555,
  9271909,
  9473591,
  9482823,
  9528085,
  9578235,
  9648663,
  9682927,
  9720851,
  9741861,
  9741857,
]