import React from 'react'
import Tooltip from 'components/Tooltip'
import FairchainIcon from 'svg/fairchain.svg'
import { InfoCircleIcon } from '../../styles/icons'
import { Row, TooltipContent } from '../../styles/products'
type Props = {
  isFairchain: boolean
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { isFairchain } = props

  // Palette will only send text. Product need FE to prepend contextual imaging
  // to the text. Will move this to a helper as more use cases are known.
  if (!isFairchain) {
    return null
  }

  return (
    <Row data-type='packaging'>
      <div data-type='fairchain-message'>
        <div data-type='logo'>
          <FairchainIcon data-type='logo' />

          <Tooltip
            position='bottom'
            color='#333'
            tip={
              <TooltipContent p={1} data-type='fairchain-message'>
                Important note: This artwork is registered on Fairchain, an important tool for
                protecting your cultural investment. Fairchain’s blockchain-backed certificates
                protect collectors from forgeries and disputes, ensure artworks’ inclusion in an
                artist’s catalogue raisonné, and create an immutable record of the provenance and
                narrative behind every work. Delivery of this artwork and receipt of the certificate
                of title and authenticity is contingent on signing the Fairchain Agreement, which
                buyers will receive by email shortly after purchase. In the event of any future
                resale, collectors will direct a resale commission to the artist.
                <p>
                  <a
                    href='https://fairchain.art/for-collectors/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Read more on the benefits of Fairchain certificates
                  </a>
                </p>
              </TooltipContent>
            }
          >
            <InfoCircleIcon />
          </Tooltip>
        </div>
        <span>
          This artwork is been registered on Fairchain which provides collectors with
          blockchain-backed certificates of title and authenticity to the benefit of both artist and
          collector.
        </span>
      </div>
    </Row>
  )
}

export default Component