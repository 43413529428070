import { createSelector } from 'reselect'
import type { Shared } from 'types/Shared'
import 'types/Shared'
import { getShared } from './helpers'
export const getClient = createSelector(
  [getShared],
  (shared: Shared): Record<string, any> => shared.client || {}
)
export const isClientReady = createSelector(
  [getClient],
  (client: Record<string, any>): boolean => client.setup || false
)
export const getIsClientReady = createSelector(
  [getClient],
  (client: Record<string, any>): boolean => client.setup || false
)