import React from 'react'
import { slugify } from 'lib/slugify'
import { Breadcrumbs, Link } from './styles/breadcrumbs'
import { getLinkCategory } from '../helpers'
import type { ConnectorProps as BreadcrumbProps } from '../connectors/Breadcrumb'
import BreadcrumbConnector from '../connectors/Breadcrumb'
export default BreadcrumbConnector((props: BreadcrumbProps) => {
  const { artistName, artistUserId, category, currentTab } = props
  const baseLink = `/${slugify(getLinkCategory(category) || 'all')}`
  const medium =
    currentTab !== 'original' && currentTab !== 'info' ? 'Prints' : getLinkCategory(category)
  const mediumLink = currentTab !== 'original' ? '/prints' : baseLink
  return (
    <Breadcrumbs {...props} mx={[3, 3, 4]} mt={3} py={0}>
      <ul data-type='breadcrumbUL'>
        <li>
          <Link href='/all' title='See all artworks'>
            All Artworks
          </Link>
        </li>

        <li>
          <Link href={mediumLink} title='See more artworks'>
            {medium}
          </Link>
        </li>

        <li>
          <Link
            href={`/account/artworks/${artistUserId}`}
            title={`See more Artworks by ${artistName}`}
          >
            {artistName} Works
          </Link>
        </li>
      </ul>
    </Breadcrumbs>
  )
})