/*
 * Building a component that will check if it is in VIEW
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * https://developers.google.com/web/updates/2016/04/intersectionobserver
 * Need a Polyfill for IE -> https://caniuse.com/#feat=intersectionobserver
 */
import { useState, useEffect } from 'react'

function buildThresholdList() {
  const thresholds = []
  const numSteps = 50

  for (let i = 1.0; i <= numSteps; i++) {
    const ratio = i / numSteps
    thresholds.push(ratio)
  }

  thresholds.push(0)
  return thresholds
}

const options = {
  root: null,
  rootMargin: '0px',
  threshold: buildThresholdList(),
}
export default (refElement: Record<string, any>) => {
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(false)
  const [isSetup, setIsSetup] = useState(false)
  const [isViewable, setIsViewable] = useState(false)
  const [direction, setDirection] = useState('down')
  const [intersectionRatio, setIntersectionRatio] = useState(0)

  function handleIntersect(entries, observer) {
    entries.forEach((entry: Record<string, any>) => {
      // Convert from 0-1 to Percentage 0-100
      const percentage = parseInt(entry.intersectionRatio * 100, 10)
      setIsViewable(percentage > 0)
      setDirection(percentage >= intersectionRatio ? 'up' : 'down') // TODO - try to get working

      setIntersectionRatio(percentage)
    })
  }

  useEffect(() => {
    setIsFeatureAvailable(typeof IntersectionObserver !== 'undefined')
    const element = refElement ? refElement.current : null

    if (element && typeof IntersectionObserver !== 'undefined') {
      if (!isSetup) {
        const observer = new IntersectionObserver(handleIntersect, options)
        observer.observe(element)
        setIsSetup(true)
      }
    }
  })
  return {
    direction,
    intersectionRatio,
    isFeatureAvailable,
    isSetup,
    isViewable,
  }
}