// CONSTANTS
export const Constants = {
  CLICK_ADD_TO_CART: 'CLICK_ADD_TO_CART_PDP',
  CLICK_ADD_TO_CART_STICKY: 'CLICK_ADD_TO_CART_STICKY_PDP',
  CLICK_ADD_ARTWORK_THUMBNAIL: 'CLICK_ADD_ARTWORK_THUMBNAIL_PDP',
  CLICK_ARTIST_BADGE: 'CLICK_ARTIST_BADGE_PDP',
  CLICK_CAROUSEL_LINK: 'CLICK_CAROUSEL_LINK_PDP',
  CLICK_MAKE_AN_OFFER: 'CLICK_MAKE_AN_OFFER_PDP',
  CLICK_MAKE_AN_OFFER_STICKY: 'CLICK_MAKE_AN_OFFER_STICKY_PDP',
  CLICK_VIEW_MORE: 'CLICK_VIEW_MORE_PDP',
  CLIENTSIDE_TRANSITION: 'CLIENTSIDE_TRANSITION_PDP',
  COPY_SKU: 'COPY_SKU_ARTWORK_DETAILS',
  DELETE_ARTWORK: 'DELETE_ARTWORK_PDP',
  EDIT_ARTWORK: 'EDIT_ARTWORK_PDP',
  GET_ARTWORK_ADMIN_DATA: 'GET_ARTWORK_ADMIN_DATA_PDP',
  OPEN_AR_EXPERIENCE: 'OPEN_AR_EXPERIENCE',
  REFRESH_PAGE: 'REFRESH_PAGE_PDP',
  SEE_MORE: 'SEE_MORE_PDP',
  SELL_OUT_RISK: 'SETUP_SELL_OUT_RISK_PDP',
  UPDATE_PRODUCT_SKU_FRAME_OPTION: 'UPDATE_PRODUCT_SKU_FRAME_OPTION_PDP',
  UPDATE_PRODUCT_SKU_CANVAS_WRAP_COLOR_OPTION: 'UPDATE_PRODUCT_SKU_CANVAS_WRAP_COLOR_OPTION_PDP',
  UPDATE_PRODUCT_SKU: 'UPDATE_PRODUCT_SKU_PDP',
  UPDATE_URL: 'UPDATE_URL_PDP',
  UPLOAD_ARTWORK_IMAGES: 'UPLOAD_ARTWORK_IMAGES_PDP',
  UPLOAD_STAGE_IMAGE: 'UPLOAD_STAGE_IMAGE_PDP',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.CLICK_ADD_TO_CART
      payload: string
    }
  | {
      type: typeof Constants.CLICK_ADD_TO_CART_STICKY
      payload: string
    }
  | {
      type: typeof Constants.CLICK_ADD_ARTWORK_THUMBNAIL
    }
  | {
      type: typeof Constants.CLICK_ARTIST_BADGE
      payload: string
    }
  | {
      type: typeof Constants.CLICK_CAROUSEL_LINK
      payload: string
    }
  | {
      type: typeof Constants.CLICK_MAKE_AN_OFFER
    }
  | {
      type: typeof Constants.CLICK_MAKE_AN_OFFER_STICKY
    }
  | {
      type: typeof Constants.CLICK_VIEW_MORE
    }
  | {
      type: typeof Constants.CLIENTSIDE_TRANSITION
      payload: string
    }
  | {
      type: typeof Constants.COPY_SKU
    }
  | {
      type: typeof Constants.DELETE_ARTWORK
    }
  | {
      type: typeof Constants.EDIT_ARTWORK
    }
  | {
      type: typeof Constants.GET_ARTWORK_ADMIN_DATA
    }
  | {
      type: typeof Constants.OPEN_AR_EXPERIENCE
    }
  | {
      type: typeof Constants.REFRESH_PAGE
    }
  | {
      type: typeof Constants.SEE_MORE
    }
  | {
      type: typeof Constants.SELL_OUT_RISK
    }
  | {
      type: typeof Constants.UPDATE_PRODUCT_SKU_FRAME_OPTION
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_PRODUCT_SKU_CANVAS_WRAP_COLOR_OPTION
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_PRODUCT_SKU
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_URL
      payload: string
    }
  | {
      type: typeof Constants.UPLOAD_ARTWORK_IMAGES
      payload: Record<string, any>
      meta: Record<string, any>
    }
  | {
      type: typeof Constants.UPLOAD_STAGE_IMAGE
      payload: Record<string, any>
      meta: Record<string, any>
    }
// ACTION CALLS
export const Actions = {
  CLICK_ADD_TO_CART: (payload: string): ActionTypes => ({
    type: Constants.CLICK_ADD_TO_CART,
    payload,
  }),
  CLICK_ADD_TO_CART_STICKY: (payload: string): ActionTypes => ({
    type: Constants.CLICK_ADD_TO_CART_STICKY,
    payload,
  }),
  CLICK_ARTIST_BADGE: (payload: string): ActionTypes => ({
    type: Constants.CLICK_ARTIST_BADGE,
    payload,
  }),
  CLICK_ADD_ARTWORK_THUMBNAIL: (): ActionTypes => ({
    type: Constants.CLICK_ADD_ARTWORK_THUMBNAIL,
  }),
  CLICK_CAROUSEL_LINK: (payload: string): ActionTypes => ({
    type: Constants.CLICK_CAROUSEL_LINK,
    payload,
  }),
  CLICK_MAKE_AN_OFFER: (): ActionTypes => ({
    type: Constants.CLICK_MAKE_AN_OFFER,
  }),
  CLICK_MAKE_AN_OFFER_STICKY: (): ActionTypes => ({
    type: Constants.CLICK_MAKE_AN_OFFER_STICKY,
  }),
  CLICK_VIEW_MORE: (payload: { artworkId: string }): ActionTypes => ({
    type: Constants.CLICK_VIEW_MORE,
    payload,
  }),
  CLIENTSIDE_TRANSITION: (payload: string): ActionTypes => ({
    type: Constants.CLIENTSIDE_TRANSITION,
    payload,
  }),
  COPY_SKU: (): ActionTypes => ({
    type: Constants.COPY_SKU,
  }),
  DELETE_ARTWORK: (): ActionTypes => ({
    type: Constants.DELETE_ARTWORK,
  }),
  EDIT_ARTWORK: (): ActionTypes => ({
    type: Constants.EDIT_ARTWORK,
  }),
  GET_ARTWORK_ADMIN_DATA: (): ActionTypes => ({
    type: Constants.GET_ARTWORK_ADMIN_DATA,
  }),
  OPEN_AR_EXPERIENCE: (): ActionTypes => ({
    type: Constants.OPEN_AR_EXPERIENCE,
  }),
  REFRESH_PAGE: (): ActionTypes => ({
    type: Constants.REFRESH_PAGE,
  }),
  SEE_MORE: (): ActionTypes => ({
    type: Constants.SEE_MORE,
  }),
  SELL_OUT_RISK: (): ActionTypes => ({
    type: Constants.SELL_OUT_RISK,
  }),
  UPDATE_PRODUCT_SKU: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATE_PRODUCT_SKU,
    payload,
  }),
  UPDATE_PRODUCT_SKU_FRAME_OPTION: (payload: { value: string }): ActionTypes => ({
    type: Constants.UPDATE_PRODUCT_SKU_FRAME_OPTION,
    payload,
  }),
  UPDATE_PRODUCT_SKU_CANVAS_WRAP_COLOR_OPTION: (payload: { value: string }): ActionTypes => ({
    type: Constants.UPDATE_PRODUCT_SKU_CANVAS_WRAP_COLOR_OPTION,
    payload,
  }),
  UPDATE_URL: (payload: string): ActionTypes => ({
    type: Constants.UPDATE_URL,
    payload,
  }),
  UPLOAD_ARTWORK_IMAGES: (
    payload: Record<string, any>,
    meta: Record<string, any>
  ): ActionTypes => ({
    type: Constants.UPLOAD_ARTWORK_IMAGES,
    payload,
    meta,
  }),
  UPLOAD_STAGE_IMAGE: (payload: Record<string, any>, meta: Record<string, any>): ActionTypes => ({
    type: Constants.UPLOAD_STAGE_IMAGE,
    payload,
    meta,
  }),
}