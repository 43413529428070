import React from 'react'
import SALink from 'components/SALink'
import type { ConnectorProps as ProductTabProps } from '../../connectors/Products'
import ProductTabConnector from '../../connectors/Products'
import { Tabs } from '../styles/products'
import {
  OPEN_EDITION,
  OPEN_EDITION_TAB,
  LIMITED_EDITION_TAB,
  LIMITED_EDITION,
} from '../../helpers/constants'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default ProductTabConnector((props: ProductTabProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { updateDataLayer, currentTab, tabs, tabUrls, updateUrl } = props

  if (tabs.length < 2) {
    return null
  }

  const { artUrl, openUrl } = tabUrls
  const [, printTab] = tabs // `,` used for destructuring, don't need first value

  const updateProduct = (ev: React.SyntheticEvent<Record<string, any>>) => {
    ev.preventDefault()
    const { href } = ev.currentTarget
    const type = ev.currentTarget.getAttribute('data-type')
    updateDataLayer({
      event: `${type.toLowerCase()} tab`,
    })
    return updateUrl(href)
  }

  return (
    <Tabs data-tab={currentTab}>
      <SALink
        data-type='Original'
        gtm-data-event='Click Original'
        href={artUrl}
        onClick={updateProduct}
        onKeyUp={updateProduct}
        role='button'
        tabIndex='0'
        title='View original'
      >
        <div data-type='tab-title'>
          <SATypography uppercase variant={SATypographyVariant.H5} bold>
            Original
          </SATypography>
        </div>
      </SALink>

      <SALink
        data-type='Prints'
        gtm-data-event='Click Prints'
        href={openUrl}
        onClick={printTab === OPEN_EDITION || LIMITED_EDITION ? updateProduct : null}
        onKeyUp={printTab === OPEN_EDITION || LIMITED_EDITION ? updateProduct : null}
        role='button'
        tabIndex='0'
        title='View prints'
      >
        <div data-type='tab-title'>
          <SATypography uppercase variant={SATypographyVariant.H5} bold>
            {printTab === OPEN_EDITION ? OPEN_EDITION_TAB : LIMITED_EDITION_TAB}
          </SATypography>
        </div>
      </SALink>
    </Tabs>
  )
})