import React from 'react'
import type { ConnectorProps as ProductsPrintProps } from '../../../connectors/ProductsPrint'
import ProductsPrintConnector from '../../../connectors/ProductsPrint'
import Packaging from './Packaging'
import Options from './Options'
import ForSale from './ForSale'
import { PRINT } from '../../../helpers/constants'
export default ProductsPrintConnector((props: ProductsPrintProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { productType } = props

  if (productType !== PRINT) {
    return null
  }

  return (
    <>
      <Packaging />

      <Options />

      <ForSale />
    </>
  )
})