import React, { useState } from 'react'
import DisableContextMenu from 'components/DisableContextMenu'
// import TrustPilotWidget from 'components/TrustPilot/Widget'
import VisibilityTrigger from 'components/VisibilityTrigger'
import Breadcrumb from '../components/Breadcrumb'
// import ArtworkDescription from '../components/ArtworkDescription'
// import ArtistRecognition from '../components/ArtistRecognition'
import RelatedSearches from '../components/RelatedSearches'
// import ArtworkInfo from '../components/ArtworkInfo'
import { MainContent } from '../components/styles/layout'
import ArtworkDescriptionSection from '../components/ArtworkDescriptionSection'
import AboutArtist from '../components/AboutArtist'
import USPSection from '../components/USPSection'
import type { ConnectorProps as LayoutProps } from '../connectors/Layout'
import LayoutConnector from '../connectors/Layout'
import Admin from './Admin'
import Carousels from './Carousels'
import Products from './Products'
import Gallery from './Gallery'
import Modal from './Modal'
export default LayoutConnector(
  (props: LayoutProps): React.ReactElement<React.ComponentProps<any>, any> => {
    const { pageIsReady } = props
    const [shouldLoadComponent, setShouldLoadComponent] = useState(false)
    return (
      <>
        {pageIsReady && (
          <DisableContextMenu>
            <Breadcrumb className='link-group-container' />

            <MainContent>
              <Gallery />

              <Products />
            </MainContent>

            <Admin />

            <ArtworkDescriptionSection />

            <VisibilityTrigger setShouldLoadComponent={setShouldLoadComponent} />

            <AboutArtist />

            <Carousels
              shouldLoadComponent={shouldLoadComponent}
              setShouldLoadComponent={setShouldLoadComponent}
            />

            <USPSection />

            {/* <TrustPilotWidget styles={`${'max-width: 1256px;
        margin: 0 auto; padding: 45px 0;'}`} /> */}
            <RelatedSearches />

            <Modal />
          </DisableContextMenu>
        )}
      </>
    )
  }
)