import React from 'react'
import { formatDimensionInCm } from 'components/MeasurementUnit/helper'
import { ViewInARoomContainer, ViewInARoomArtwork, ViewInARoomBackground } from '../styles/gallery'
type Props = Record<string, any> & {
  abTest: Record<string, any>
  isClientReady: boolean
}
const maxWidth = 700
const [small, medium, large, xlarge] = [
  'https://d3t95n9c6zzriw.cloudfront.net/static/img/view_in_a_room_2019_1b.jpg',
  'https://d3t95n9c6zzriw.cloudfront.net/static/img/view_in_a_room_2019_2b.jpg',
  'https://d3t95n9c6zzriw.cloudfront.net/static/img/view_in_a_room_2019_3b.jpg',
  'https://d3t95n9c6zzriw.cloudfront.net/static/img/view_in_a_room_2019_4b.jpg',
]

const Component = (props: Props) => {
  const {
    imageUrl,
    selectedProduct: { height, isOriginal, width },
  } = props
  const containerWidth = 55
  const isVertical = parseInt(height, 10) > parseInt(width, 10)
  let backgroundImage = small
  let scale = isOriginal ? 1.25 : 5.81
  let type = 'small'
  const isLargePrint =
    !isOriginal && (formatDimensionInCm(width) > 6 || formatDimensionInCm(height) > 9)

  if (isLargePrint) {
    scale = 2
  }

  // convert width/height from centimeters to inches for originals to determine background image
  // don't do any conversion for prints as its already in inches
  if (isOriginal) {
    if (formatDimensionInCm(width) > 72 || formatDimensionInCm(height) > 72) {
      // 72 => 6ft
      backgroundImage = xlarge
      scale = 0.5
      type = 'xlarge'
    } else if (formatDimensionInCm(width) > 42 || formatDimensionInCm(height) > 42) {
      // 42 => 3.5ft
      backgroundImage = large
      scale = 0.65
      type = 'large'
    } else if (formatDimensionInCm(width) > 36 || formatDimensionInCm(height) > 36) {
      // 36 => 3ft
      backgroundImage = medium
      scale = 1.07
      type = 'medium'
    }
  }

  //     room size width                    artwork pixel width
  // -----------------------------   =  -----------------------------
  //  actual space width (inches)        actual artwork width (inches)
  // const spaceWidth = 68 // inches
  // const actualFrameWidth = 0.75 // inches
  const roomWidth = 8 * 12 // Feet X Inches

  const artworkWidth =
    (((containerWidth < maxWidth ? containerWidth : maxWidth) *
      Number(formatDimensionInCm(width))) /
      roomWidth) *
    scale
  return (
    <ViewInARoomContainer data-type='thumb'>
      <ViewInARoomArtwork
        data-type='thumb'
        src={imageUrl}
        widthValue={`${parseInt(artworkWidth, 10)}px`}
        type={type}
        isVertical={isVertical}
        alt='View in a Room Artwork'
      />

      <ViewInARoomBackground src={backgroundImage} alt='View in a Room Background' />
    </ViewInARoomContainer>
  )
}

export default Component