import React from 'react'
import { getImageAltText } from 'components/Artworks/Image/helpers'
type Props = {
  hasHighFetchPriority: boolean
} & Record<string, any>

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    hasHighFetchPriority,
    height,
    imageBaseUrl,
    imageUrl,
    large,
    width,
    category,
    subject,
    styles,
    artistName,
  } = props
  const imageSrc = imageBaseUrl ? `${imageBaseUrl}-${large ? 8 : 7}.jpg` : imageUrl
  return (
    <div data-type='image'>
      <img
        src={imageSrc}
        itemProp='image'
        width={width}
        height={height}
        alt={getImageAltText({
          artistName,
          subject,
          styles,
          category,
        })}
        {...(hasHighFetchPriority
          ? {
              fetchPriority: 'high',
            }
          : {})}
      />
    </div>
  )
}

export default Component