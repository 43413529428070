import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'
import Modal from 'components/Modal/v2'
import { LinkButton } from '../../styles/products'
import { updateDataLayer } from '../../../helpers'

// If Modal base is dynamically imported, if causes a layout shift on click,
// so just import the content
const LaunchModal = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const CommissionModal = dynamic(
    import(
      /* webpackChunkName: "commissionModal" */
      'components/RequestCommission/CommissionModal'
    ),
    noSSR
  )
  return <CommissionModal {...props} />
}

type Props = {
  artworkId: number
  closeModal: (...args: Array<any>) => any
  openModal: (...args: Array<any>) => any
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { artworkId, closeModal, openModal } = props
  const [showModal, handleShowModal] = useState(false)
  return (
    <>
      <LinkButton
        data-button='mao-blue'
        onClick={() => {
          updateDataLayer({
            event: 'commission start',
          })
          handleShowModal(true)
          openModal('commissionModal')
        }}
        title='Request a commission'
        rel='nofollow'
        role='button'
        tabIndex='0'
      >
        Request Commission
      </LinkButton>
      {showModal && (
        <>
          <Modal name='commissionModal'>
            <LaunchModal closeModal={closeModal} artworkId={artworkId} page='pdp' />
          </Modal>
        </>
      )}
    </>
  )
}

export default Component