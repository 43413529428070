import styled from 'styled-components'

export const RelatedSearches = styled.div`
  width: 100%;
  order: 7;
  margin-bottom: 50px;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-bottom: 0px;
  }
  [data-type='keyword-row'] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  a {
    padding: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-decoration: none;
    background-color: #eee;
    color: #333;
  }
  [data-type='related-searches-title'] {
    margin-bottom: 32px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      margin-bottom: 24px;
    }
  }
`