import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Actions as DataLayerActions } from 'actions/shared/dataLayer'
import { Actions } from '../actions'
import {
  getCurrentTab,
  getSelectedProductStatus,
  getTabs,
  getProductTabLinkUrls,
} from '../selectors/product'
const mapStateToProps = createStructuredSelector({
  currentTab: getCurrentTab,
  productStatus: getSelectedProductStatus,
  tabs: getTabs,
  tabUrls: getProductTabLinkUrls,
})

const mapDispatchToProps = (dispatch) => ({
  updateDataLayer: (payload: Record<string, any>) =>
    dispatch(DataLayerActions.UPDATE_DATALAYER(payload)),
  updateUrl: (payload: string) => dispatch(Actions.UPDATE_URL(payload)),
})

export type ConnectorProps = {
  updateDataLayer: (...args: Array<any>) => any
  currentTab: string
  productStatus: string
  tabs: Array<string>
  tabUrls: Record<string, any>
  updateUrl: (...args: Array<any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps)