import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getArtistName, getArtistUserId, getArtistProfileUrl } from '../selectors/artist'
import { getArtworkCategory, getArtworkTitle } from '../selectors/artwork'
import { getCurrentTab } from '../selectors/product'
const mapStateToProps = createStructuredSelector({
  artistName: getArtistName,
  artistUserId: getArtistUserId,
  category: getArtworkCategory,
  currentTab: getCurrentTab,
  profileUrl: getArtistProfileUrl,
  title: getArtworkTitle,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  artistName: string
  artistUserId: string
  category: string
  currentTab: string
  profileUrl: string
  title: string
}
export default connect(mapStateToProps, mapDispatchToProps)