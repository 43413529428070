import React, { useState, useRef, useEffect } from 'react'
import SALink from 'components/SALink'
import useOnScreen from 'hooks/onScreen'
import type { ConnectorProps as AboutArtistProps } from '../connectors/AboutArtist'
import AboutArtistConnector from '../connectors/AboutArtist'
import { Wrapper, ChevronIcon } from './styles/aboutArtist'
import ArtistBadges from './ArtistBadges'
import { displayStringWithoutLineBreak, accordionScroll, getTruncatedText } from '../helpers'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

export default AboutArtistConnector((props: AboutArtistProps) => {
  const {
    artistCountry,
    artistName,
    artistProfileURL,
    artistAvatar,
    screenWidth,
    artworkID,
    artistAbout,
  } = props
  const aboutRef: Record<string, any> = useRef(null)
  const artistSectionRef: Record<string, any> = useRef(null)
  const artistNameRef: Record<string, any> = useRef(null)
  const { isSetup, isViewable, isFeatureAvailable } = useOnScreen(artistNameRef)
  const [showContent, setShowContent] = useState(false)
  const artistAboutText = getTruncatedText(artistAbout)
  const cleanArtistAbout = artistAboutText.includes('\n')
    ? displayStringWithoutLineBreak(artistAboutText)
    : artistAboutText
  // find the width of description <p> tag
  const artistAboutWidth = aboutRef.current ? aboutRef.current.offsetWidth : 0
  const artistAboutTextLengthValue = (artistAboutWidth * 955) / 1020

  const getAboutText = () => {
    return !showContent ? cleanArtistAbout : artistAboutText
  }

  const toggleSection = (event) => {
    const scrollBelowRef: boolean =
      artistNameRef.current && artistNameRef.current.getBoundingClientRect
        ? artistNameRef.current.getBoundingClientRect().y < 237
        : false
    const isScrollMobile =
      isFeatureAvailable &&
      isSetup &&
      showContent &&
      (!isViewable || (isViewable && scrollBelowRef))
    const isScrollDesktop = isFeatureAvailable && isSetup && !isViewable && showContent
    setShowContent(!showContent)

    if (screenWidth < 1024) {
      if (isScrollMobile) {
        accordionScroll(artistSectionRef, screenWidth, 135, 30)
      }
    } else if (isScrollDesktop) {
      accordionScroll(artistSectionRef, screenWidth, 135, 30)
    }
  }

  useEffect(() => {
    setShowContent(false)
  }, [artworkID])
  return (
    <Wrapper data-type='about-artist'>
      <div data-type='row' ref={artistSectionRef}>
        <div data-type='info-row'>
          {artistAvatar && (
            <div data-type='artist-image'>
              <SALink href={artistProfileURL} title='View profile'>
                <img
                  src={artistAvatar}
                  data-type='artist-avatar'
                  alt={artistName}
                  width='44px'
                  height='44px'
                />
              </SALink>
            </div>
          )}

          <div data-type='artist-info'>
            <div data-type='artist-info-col'>
              {artistName && (

                <SATypography variant={SATypographyVariant.H4}>
                  <SALink
                    data-type='artist-name'
                    href={artistProfileURL}
                    title='View profile'
                    ref={artistNameRef}
                  >
                    {artistName}
                  </SALink>
                </SATypography>
              )}

              {artistCountry &&
                <SATypography data-type='artist-location' variant={SATypographyVariant.SMALL}>
                  {artistCountry}
                </SATypography>
              }
            </div>
            <div data-type='profile-button'>
              <SALink data-type='artist-profile-link' href={artistProfileURL} title='View profile'>
                <SATypography variant={SATypographyVariant.CTA_MEDIUM}>View Profile</SATypography>
              </SALink>
            </div>
          </div>
        </div>
      </div>
      {artistAbout && (
        <>
          <div data-type='row' ref={aboutRef}>
            <SATypography
              variant={SATypographyVariant.MEDIUM}
              data-type={
                !showContent &&
                aboutRef.current &&
                cleanArtistAbout.length > artistAboutTextLengthValue
                  ? 'artist-about-clamped'
                  : 'artist-about'
              }
              dangerouslySetInnerHTML={{
                __html: `${(getAboutText() || '').replace(/\n/g, '<br />')}`,
              }}
            />
          </div>
          <div data-type='read-more'>
            {aboutRef.current && cleanArtistAbout.length > artistAboutTextLengthValue && (
              <button type='button' data-type='toggle-button' onClick={toggleSection}>
                <SATypography variant={SATypographyVariant.TINY} bold>
                  {showContent ? 'READ LESS' : 'READ MORE'}
                </SATypography>
                <ChevronIcon data-type={showContent ? 'rotate-chevron' : ''} />
              </button>
            )}
          </div>
        </>
      )}

      <ArtistBadges />
    </Wrapper>
  )
})