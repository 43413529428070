import React, { useState, useEffect } from 'react'
import { convertPrintInchesToCentimeters } from 'components/MeasurementUnit/helper'
import type { ConnectorProps as ArtworkDescriptionProps } from '../../../connectors/ArtworkDescription'
import ArtworkDescriptionConnector from '../../../connectors/ArtworkDescription'
import {
  ACRYLIC,
  METAL,
  FRAMING_TYPE_ID_LIGHT_PEWTER,
  FRAMING_TYPE_ID_DARK_PEWTER,
  OPEN_EDITION,
  CANVAS,
  BOX,
  ROLLED_IN_TUBE,
  NOT_FRAMED,
  PRINT_DEPTH,
  CANVAS_PRINT_DEPTH,
  CANVAS_WRAP_FRAME_SIZE_IN_INCHES,
  CANVAS_WRAP_GAP_SIZE_IN_INCHES,
} from '../../../helpers/constants'
import AccordionComponent from '../AccordionComponent'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

/** @see './Print.spec.js' exported for testing only */
export function getFramedCanvasWrapPrintDimensions(
  printWidthInInches: number,
  printHeightInInches: number,
  userSizePreference: 'in' | 'cm'
): string {
  const widthInInches =
    Number(printWidthInInches) +
    (CANVAS_WRAP_FRAME_SIZE_IN_INCHES + CANVAS_WRAP_GAP_SIZE_IN_INCHES) * 2
  // left and right
  const heightInInches =
    Number(printHeightInInches) +
    (CANVAS_WRAP_FRAME_SIZE_IN_INCHES + CANVAS_WRAP_GAP_SIZE_IN_INCHES) * 2
  // top and bottom
  const widthInUserUnitOfLength =
    userSizePreference === 'in'
      ? widthInInches
      : parseInt(convertPrintInchesToCentimeters(widthInInches, userSizePreference), 10)
  const heightInUserUnitOfLength =
    userSizePreference === 'in'
      ? heightInInches
      : parseInt(convertPrintInchesToCentimeters(heightInInches, userSizePreference), 10)
  const depthInUserUnitOfLength =
    userSizePreference === 'in'
      ? CANVAS_PRINT_DEPTH
      : convertPrintInchesToCentimeters(CANVAS_PRINT_DEPTH, userSizePreference)
  return [
    widthInUserUnitOfLength,
    'W',
    'x',
    heightInUserUnitOfLength,
    'H',
    'x',
    depthInUserUnitOfLength,
    'D',
    userSizePreference,
  ].join(' ')
}
export default ArtworkDescriptionConnector((props: ArtworkDescriptionProps): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const {
    artworkID,
    currentTab,
    selectedPrintProduct,
    selectedCanvasWrapColorOption,
    selectedFrameOption,
    screenWidth,
    userSizePreference,
  } = props
  const [showContent, setShowContent] = useState(false)
  useEffect(() => {
    setShowContent(false)
  }, [artworkID])
  const { material, height, width, depth } = selectedPrintProduct

  if (currentTab !== OPEN_EDITION) {
    return null
  }

  const isFrameAvailable = ![ACRYLIC, METAL].includes(material)
  const isFrameSelected = selectedFrameOption !== null
  const smallBorder = 4 // 2" mat border on both sides

  const largeBorder = 6 // 3" mat border on both sides

  const isPewterFrameSelected = [
    FRAMING_TYPE_ID_LIGHT_PEWTER,
    FRAMING_TYPE_ID_DARK_PEWTER,
  ].includes(selectedFrameOption?.framingTypeId)
  const frameBorder = isPewterFrameSelected
    ? 1.75 // 0.875" frame including both sides
    : 1.25

  // 0.625" frame including both sides
  function getFramedPaperPrintDimensions(): string {
    if (width < 18) {
      return `${
        parseInt(convertPrintInchesToCentimeters(width, userSizePreference), 10) +
        smallBorder +
        frameBorder
      } W x ${
        parseInt(convertPrintInchesToCentimeters(height, userSizePreference), 10) +
        smallBorder +
        frameBorder
      } H x ${convertPrintInchesToCentimeters(
        PRINT_DEPTH,
        userSizePreference
      )} D ${userSizePreference}`
    }

    return `${
      parseInt(convertPrintInchesToCentimeters(width, userSizePreference), 10) +
      largeBorder +
      frameBorder
    } W x ${
      parseInt(convertPrintInchesToCentimeters(height, userSizePreference), 10) +
      largeBorder +
      frameBorder
    } H x ${convertPrintInchesToCentimeters(
      PRINT_DEPTH,
      userSizePreference
    )} D ${userSizePreference}`
  }

  const getPrintType = (): string => {
    switch (material) {
      case ACRYLIC:
        return 'Giclee print face-mounted on Acrylic'

      case METAL:
        return 'Artwork is infused to aluminum with specialized inks via heat-transfer'

      default:
        return `Giclee on ${material}`
    }
  }

  return (
    <AccordionComponent
      dataType='product-details'
      title='DETAILS AND DIMENSIONS'
      showContent={showContent}
      setShowContent={setShowContent}
      screenWidth={screenWidth}
      headerChildren={
        <>
          {material && (
            <div data-type='accordion-sub-section'>
              <div data-type='accordion-sub-header-title'>
                <SATypography variant={SATypographyVariant.H6}>Print:</SATypography>
              </div>
              <div data-type='accordion-sub-header-content'>
                <SATypography variant={SATypographyVariant.MEDIUM}>{getPrintType()}</SATypography>
              </div>
            </div>
          )}
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Size:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {`${convertPrintInchesToCentimeters(
                  width,
                  userSizePreference
                )} W x ${convertPrintInchesToCentimeters(height, userSizePreference)} H x ${
                  material === CANVAS
                    ? convertPrintInchesToCentimeters(CANVAS_PRINT_DEPTH, userSizePreference)
                    : convertPrintInchesToCentimeters(depth, userSizePreference)
                } D ${userSizePreference}`}
              </SATypography>
            </div>
          </div>
          {isFrameAvailable && isFrameSelected ? (
            <div data-type='accordion-sub-section'>
              <div data-type='accordion-sub-header-title'>
                <SATypography variant={SATypographyVariant.H6}>Size with Frame:</SATypography>
              </div>
              <div data-type='accordion-sub-header-content'>
                <SATypography variant={SATypographyVariant.MEDIUM}>
                  {material === CANVAS
                    ? getFramedCanvasWrapPrintDimensions(width, height, userSizePreference)
                    : getFramedPaperPrintDimensions()}
                </SATypography>
              </div>
            </div>
          ) : (
            <div data-type='accordion-sub-section'>
              <div data-type='accordion-sub-header-title'>
                <SATypography variant={SATypographyVariant.H6}>Frame:</SATypography>
              </div>
              <div data-type='accordion-sub-header-content'>
                <SATypography variant={SATypographyVariant.MEDIUM}>Not framed</SATypography>
              </div>
            </div>
          )}
        </>
      }
    >
      <>
        {isFrameAvailable && isFrameSelected && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Frame:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {selectedFrameOption?.title ?? NOT_FRAMED}
              </SATypography>
            </div>
          </div>
        )}
        {material === CANVAS && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Canvas Wrap:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>
                {selectedCanvasWrapColorOption?.title}
              </SATypography>
            </div>
          </div>
        )}
        {(material === CANVAS || (isFrameAvailable && isFrameSelected)) && (
          <div data-type='accordion-sub-section'>
            <div data-type='accordion-sub-header-title'>
              <SATypography variant={SATypographyVariant.H6}>Ready to Hang:</SATypography>
            </div>
            <div data-type='accordion-sub-header-content'>
              <SATypography variant={SATypographyVariant.MEDIUM}>Yes</SATypography>
            </div>
          </div>
        )}
        <div data-type='accordion-sub-section'>
          <div data-type='accordion-sub-header-title'>
            <SATypography variant={SATypographyVariant.H6}>Packaging:</SATypography>
          </div>
          <div data-type='accordion-sub-header-content'>
            <SATypography variant={SATypographyVariant.MEDIUM}>
              {material === CANVAS || isFrameSelected ? BOX : ROLLED_IN_TUBE}
            </SATypography>
          </div>
        </div>
      </>
    </AccordionComponent>
  )
})